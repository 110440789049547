<template>
    <div class="product-meitukey-scan">
        <div class="product-meitukey-scan-container">
            <div class="banner-info product-meitukey-scan-info">
                <div class="banner-info-title poppins product-meitukey-scan-ani product-meitukey-scan-title" v-html="i18nPage.title"></div>
                <div class="banner-info-desc product-meitukey-scan-ani product-meitukey-scan-desc">{{ i18nPage.desc }}</div>
            </div>
            <div class="product-meitukey-scan-ani product-meitukey-scan-img" :class="{ 'is-ios-fix': isIosFix, 'is-android': isAndroid }">
                <img :src="videoData.poster" alt="" />
            </div>
        </div>
    </div>
</template>

<script>
const iosVer = (function() {
    let res = '';
    let str = navigator.userAgent.toLowerCase();
    let ver = str.match(/cpu iphone os (.*?) like mac os/);
    if (ver && ver[1]) {
        res = ver[1].replace(/_/g, '.');
        res = (res && res.split('.')) || [];
    }
    return res && res[0] ? Number(res && res[0]) : '';
})();
const isIosFix = iosVer >= 13;
export default {
    computed: {
        i18nPage() {
            return this.$t('meitukeyPage.scan');
        },
    },
    data() {
        const buildForOverseas = process.env.VUE_APP_BUILD_FOR === 'evelabinsight';
        const ua = window.navigator.userAgent.toLowerCase();
        return {
            isIosFix,
            isAndroid: /android|adr|miui|samsung|huawei/gi.test(ua),
            videoData: {
                url: require('@assets/medias/product-fitting.mp4'),
                poster: buildForOverseas ? require(`@assets/medias/evelabinsight/product-fitting.jpg`) : require(`@assets/medias/product-fitting.jpg`),
            },
        };
    },
    mounted() {
        this.mxAnimation({
            handle: '.product-meitukey-scan-ani',
            triggerElement: '.product-meitukey-scan',
            onEnter: () => {
                var el = document.querySelector('.product-meitukey-scan video');
                el && el.play();
            },
        });
    },
};
</script>

<style lang="scss">
.product-meitukey-scan {
    video::-webkit-media-controls-start-playback-button {
        display: none !important;
        -webkit-appearance: none;
    }
    @media only screen and (min-width: 769px) {
        &-container {
            max-width: 1130px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            padding: 80px 0;
        }
        &-info {
            width: 45%;
        }
        &-img {
            width: 55%;

            img {
                width: 100%;
            }
        }
    }
    @media only screen and (max-width: 768px) {
        padding: 0.8rem 0 0.4rem 0;
        background: #fff;
        &-title {
            font-size: 0.28rem;
            font-weight: 600;
            padding: 0 0.35rem;
            line-height: 1.1;
        }
        &-desc {
            font-size: 0.13rem;
            padding: 0 0.35rem;
            margin: 0.2rem 0;
        }
        &-img {
            overflow: hidden;
            img {
                width: 128%;
            }
        }
    }
}
</style>
