<template>
    <div class="product-meitukey-platform">
        <div class="product-meitukey-platform-container">
            <div class="block-title poppins product-meitukey-platform-ani product-meitukey-platform-title">{{ i18nPage.title }}</div>
            <div class="product-meitukey-platform-list">
                <div class="product-meitukey-platform-ani product-meitukey-platform-item" v-for="(item, index) in i18nPage.list" :key="index">
                    <div class="poppins product-meitukey-platform-ani product-meitukey-platform-item-title">{{ item.title }}</div>
                    <div class="product-meitukey-platform-ani product-meitukey-platform-item-desc">{{ item.desc }}</div>

                    <router-link class="product-meitukey-platform-ani learn-more" :to="{ path: '/' + $route.params.lang + '/product/' + item.key, query: $route.query }" @click.native="onLearnMore(item.key)">
                        {{ $t('learnMore') }}
                        <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M.95 10.9l4.775-4.775a.248.248 0 0 0-.002-.352L.95 1" stroke="#00C8C8" stroke-linecap="round"/></svg>
                    </router-link>

                    <div class="product-meitukey-platform-ani product-meitukey-platform-item-img">
                        <img class="img" :class="[item.key]" :src="require(`@assets/images/product/meitukey/platform/${buildForOverseas ? 'evelabinsight/' : ''}${item.key}.png`)" alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            buildForOverseas: process.env.VUE_APP_BUILD_FOR === 'evelabinsight',
        };
    },
    computed: {
        i18nPage() {
            return this.$t('meitukeyPage.platform');
        },
    },
    methods: {
        onLearnMore(key) {
            this.$track('product_meitukey_learnmore_clk', {
                type: key === 'skin-mirror' ? '智能分析系统' : 'AI皮肤分析仪',
            });
        },
    },
    mounted() {
        this.mxAnimation({
            handle: '.product-meitukey-platform-ani',
            triggerElement: '.product-meitukey-platform-ani',
        });
    },
};
</script>

<style lang="scss">
.product-meitukey-platform {
    background-color: #f9f9f9;

    @media only screen and (min-width: 769px) {
        &-container {
            width: 972px;
            margin: 0 auto;
            padding: 80px 0;
        }
        &-list {
            margin-top: 40px;
            display: flex;
            justify-content: space-around;
        }
        &-item {
            width: 446px;
            // height: 540px;
            padding: 30px 36px;
            box-sizing: border-box;
            background: #fff;
            box-shadow: 0px 4px 38px rgba(0, 0, 0, 0.07);
            border-radius: 20px;
            display: flex;
            flex-direction: column;

            &-title {
                font-size: 24px;
                line-height: 28px;
                font-weight: 500;
            }
            &-desc {
                font-size: 12px;
                line-height: 16px;
                margin-top: 8px;
                margin-bottom: 10px;
            }
            .learn-more {
                font-size: 16px;
                line-height: 20px;
                color: #00C8C8;
                display: flex;
                align-items: center;

                svg {
                    margin-left: 8px;
                    margin-top: 3px;
                }
            }
            &-img {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;

                .img {
                    display: block;
                    margin-top: 40px;
                    max-height: 250px;
                    max-width: 100%;
                }
            }
        }
    }
    @media only screen and (max-width: 1108px) and (min-width: 769px) {
        &-container {
            width: 100%;
            box-sizing: border-box;
        }
        &-list {
            width: 1130px;
            transform: scale(0.9451327433628318);
            transform-origin: 0;
        }
        &-item {
            flex-shrink: 0;
        }
    }

    @media only screen and (max-width: 768px) {
        padding-bottom: 0.8rem;
        &-title {
            font-size: 0.28rem;
            font-weight: 600;
            padding: 0.8rem 0.35rem 0.4rem 0.35rem;
        }
        &-list {
            padding: 0 0.3rem;
        }
        &-item {
            width: 100%;
            background: #fff;
            box-shadow: 0px 2px 0.2rem rgba(0, 0, 0, 0.07);
            border-radius: 0.12rem;
            margin-bottom: 0.3rem;
            padding: 0.25rem 0.16rem;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;

            &:last-of-type {
                margin-bottom: 0;
            }

            &-title {
                font-size: 0.2rem;
                font-weight: 600;
                line-height: 1.2;
            }
            &-desc {
                font-size: 0.13rem;
                margin-top: 0.1rem;
                margin-bottom: 0.06rem;
            }
            .learn-more {
                font-size: 0.13rem;
                color: #00C8C8;
                display: flex;
                align-items: center;

                svg {
                    width: 0.04rem;
                    margin-left: 0.05rem;
                }
            }
            &-img {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;

                .img {
                    display: block;
                    margin-top: 30px;

                    &.skin-mirror {
                        width: 2.4rem;
                    }
                    &.eve {
                        width: 2.18rem;
                    }
                }
            }
        }
    }
}
</style>
