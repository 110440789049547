<template>
    <div class="product-eve">
        <banner></banner>
        <features></features>
        <quantitativeAnalysis></quantitativeAnalysis>
        <light5></light5>
        <problems></problems>
    </div>
</template>

<script>
import banner from './components/banner.vue';
import features from './components/features.vue';
import quantitativeAnalysis from './components/quantitativeAnalysis.vue';
import light5 from './components/light5.vue';
import problems from './components/problems.vue';

export default {
    name: 'productEve',
    components: {
        banner,
        features,
        quantitativeAnalysis,
        light5,
        problems,
    },
    data() {
        return {
            pageDurationID: 'MeituEveM',
        };
    },
    mounted() {
        this.$track('product_skinanalyzer_imp');
    },
};
</script>
