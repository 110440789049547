<template>
    <div class="virtual-makeup-look">
        <div class="virtual-makeup-look-container">
            <div class="virtual-info virtual-makeup-look-info">
                <div class="virtual-info-title poppins virtual-makeup-look-ani virtual-makeup-look-info-title">{{ i18nPage.title }}</div>
                <div class="virtual-info-desc virtual-makeup-look-ani virtual-makeup-look-info-desc" v-html="i18nPage.desc"></div>
                <div class="virtual-info-buttons">
                    <a href="javascript:;" class="virtual-makeup-look-ani ui-button virtual-makeup-look-info-button" @click="onContact">{{ $t('contact') }}</a>
                </div>
            </div>

            <div class="virtual-makeup-look-ani virtual-makeup-look-imgs">
                <img :src="require(`@assets/images/product/virtualMakeup/look/${index}.png`)" alt="" class="virtual-makeup-look-img" :class="{ active: current === index }" v-for="index in 5" :key="index" />
                <div class="virtual-makeup-look-tabs">
                    <div class="virtual-makeup-look-ani virtual-makeup-look-tab" :class="{ active: current === index + 1 }" @click="onTabClick(index)" v-for="(item, index) in list" :key="index">
                        <img class="img" :src="require(`@assets/images/product/virtualMakeup/look/${index + 1}.jpg`)" alt="" />
                        <div class="text">{{ item }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        i18nPage() {
            return this.$t('virtualMakeupPage.look');
        },
    },
    data() {
        return {
            timer: null,
            current: 1,
            list: ['New Year', 'Blood', 'Peach', 'Ruby', 'Spring'],
        };
    },
    methods: {
        onContact() {
            this.mxShowContactModal({
                source: 'Makeup',
            });
        },
        onTabClick(index) {
            clearInterval(this.timer);
            this.current = index + 1;
        },
        play() {
            if (this.timer) {
                clearInterval(this.timer);
            }
            this.timer = setInterval(() => {
                if (this.current === 5) {
                    this.current = 1;
                } else {
                    this.current++;
                }
            }, 2000);
        },
    },
    mounted() {
        this.mxAnimation({
            handle: '.virtual-makeup-look-ani',
            triggerElement: '.virtual-makeup-look',
            interval: 0.15,
            triggerHook: 0.5,
            onEnter: () => {
                this.play();
            },
        });
    },
    beforeDestroy() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    },
};
</script>

<style lang="scss">
.virtual-makeup-look {
    @media only screen and (min-width: 769px) {
        &-container {
            display: flex;
            align-items: center;
            width: 1070px;
            margin: 0 auto;
            padding: 80px 0;
        }
        &-info {
            flex-shrink: 0;
            width: 500px;
        }
        &-imgs {
            flex: 1;
            height: 590px;
            position: relative;
            background: url(~@assets/images/product/mobile.png) 138px 0 no-repeat;
            background-size: 294px auto;

            &:after {
                content: '';
                position: absolute;
                width: 348px;
                height: 99px;
                background: rgba(0, 0, 0, 0.2);
                background: url(~@assets/images/product/shadow.png) 0 0 no-repeat;
                background-size: contain;
                left: 50%;
                bottom: -100px;
                transform: translateX(-50%);
            }
        }
        &-img {
            position: absolute;
            height: 100%;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            opacity: 0;
            transition: all 0.5s;

            &.active {
                opacity: 1;
            }
        }
        &-tabs {
            display: flex;
            align-items: center;
            position: absolute;
            left: 50%;
            top: 420px;
            transform: translateX(-50%);
        }
        &-tab {
            width: 55px;
            height: 75px;
            margin-right: 12px;
            background: #fff;
            display: flex;
            flex-direction: column;
            cursor: pointer;

            .img {
                display: block;
                width: 100%;
                text-align: center;
            }
            .text {
                font-size: 12px;
                transform: scale(0.8);
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &.active {
                width: 69px;
                height: 94px;
                box-sizing: border-box;
                background: #e61535;
                border: solid 2px #e61535;

                .text {
                    color: #fff;
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        padding-bottom: 0.4rem;
        &-info {
            width: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            padding: 0.8rem 0.35rem 0.5rem 0.35rem;
            &-title {
                font-size: 0.28rem;
                font-weight: 600;
                line-height: 1.1;
            }
            &-desc {
                font-size: 13px;
                width: 2.8rem;
                margin-top: 0.2rem;
                margin-bottom: 0.5rem;
            }
            &-button {
                margin-right: auto;
            }
        }
        &-imgs {
            height: 4.4rem;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                width: 2.62rem;
                height: 0.75rem;
                background: url(~@assets/images/product/mshadow.png) 0 0 no-repeat;
                background-size: contain;
                left: 50%;
                transform: translateX(-50%);
                bottom: -0.72rem;
            }
        }
        &-img {
            position: absolute;
            height: 4.4rem;
            left: 0.65rem;
            top: 0;
            opacity: 0;
            transition: all 0.5s;

            &.active {
                opacity: 1;
            }
        }
        &-tabs {
            display: flex;
            align-items: center;
            position: absolute;
            z-index: 2;
            left: 0.58rem;
            top: 3.1rem;
        }
        &-tab {
            width: 0.42rem;
            height: 0.57rem;
            margin-right: 0.1rem;
            background: #fff;
            display: flex;
            flex-direction: column;
            cursor: pointer;

            .img {
                display: block;
                width: 100%;
                text-align: center;
            }
            .text {
                font-size: 10px;
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                transform: scale(0.8);
                white-space: nowrap;
            }
            &.active {
                width: 0.52rem;
                height: 0.71rem;
                box-sizing: border-box;
                background: #e61535;
                border: solid 2px #e61535;

                .text {
                    color: #fff;
                }
            }
        }
    }
}
</style>
