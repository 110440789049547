<template>
    <div class="virtual-hair-customize">
        <div class="virtual-hair-customize-container">
            <div class="virtual-info virtual-hair-customize-info">
                <div class="virtual-info-title poppins virtual-hair-customize-ani virtual-hair-customize-info-title">{{ i18nPage.title }}</div>
                <div class="virtual-info-desc virtual-hair-customize-ani virtual-hair-customize-info-desc">{{ i18nPage.desc }}</div>
            </div>

            <div class="virtual-hair-customize-imgs">
                <img src="@assets/images/product/virtualHair/customize/bg.png" alt="" class="virtual-hair-customize-ani virtual-hair-customize-img" />
                <div class="virtual-hair-customize-tabs">
                    <img v-for="index in 5" :key="index" :src="require(`@assets/images/product/virtualHair/customize/${index}.jpg`)" alt="" class="virtual-hair-customize-ani virtual-hair-customize-tab" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        i18nPage() {
            return this.$t('virtualHairPage.customize');
        },
    },
    mounted() {
        this.mxAnimation({
            handle: '.virtual-hair-customize-ani',
            triggerElement: '.virtual-hair-customize',
            interval: 0.15,
        });
    },
};
</script>

<style lang="scss">
.virtual-hair-customize {
    background-color: #f9f9f9;

    @media only screen and (min-width: 769px) {
        &-container {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            width: 1070px;
            margin: 0 auto;
            padding: 80px 0;
        }
        &-imgs {
            flex: 1;
            height: 580px;
            position: relative;
            flex-shrink: 0;
            margin-right: 120px;

            &:after {
                content: '';
                position: absolute;
                width: 348px;
                height: 99px;
                background: rgba(0, 0, 0, 0.2);
                background: url(~@assets/images/product/shadow.png) 0 0 no-repeat;
                background-size: contain;
                left: 50%;
                bottom: -100px;
                transform: translateX(-50%);
            }
        }
        &-img {
            display: block;
            height: 100%;
            margin: 0 auto;
        }
        &-tabs {
            position: absolute;
            left: 50%;
            top: 425px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            width: 425px;
            transform: translateX(-50%);
        }
        &-tab {
            width: 72px;
            height: 72px;
        }
        &-info {
            width: 450px;
            margin-left: auto;
        }
    }

    @media only screen and (max-width: 768px) {
        &-container {
            display: flex;
            flex-direction: column;
            padding-bottom: 1.1rem;
        }
        &-info {
            width: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            padding: 0.5rem 0.35rem;
            &-title {
                font-size: 0.28rem;
                font-weight: 600;
                line-height: 1.1;
            }
            &-desc {
                font-size: 0.13rem;
                width: 2.8rem;
                margin-top: 0.2rem;
            }
            &-button {
                margin-right: auto;
                padding: 0 0.2rem;
                height: 0.44rem;
                line-height: 0.44rem;
                background: #000;
                border-radius: 0.22rem;
                color: #fff;
                font-size: 0.14rem;
            }
        }
        &-imgs {
            height: 4.42rem;
            margin: 0 0.35rem;
            box-sizing: border-box;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                width: 2.62rem;
                height: 0.75rem;
                background: url(~@assets/images/product/mshadow.png) 0 0 no-repeat;
                background-size: contain;
                left: 50%;
                transform: translateX(-50%);
                bottom: -0.72rem;
            }
        }
        &-img {
            display: block;
            height: 100%;
            margin: 0 auto;
        }
        &-tabs {
            width: 100%;
            display: flex;
            justify-content: space-between;
            position: absolute;
            top: 3.24rem;
            left: 0;
        }
        &-tab {
            width: 0.54rem;
            height: 0.54rem;
            box-shadow: 0 0.04rem 0.1rem rgba(0, 0, 0, 0.1);
        }
    }
}
</style>
