<template>
    <div class="evev-compare">
        <h2 class="block-title evev-compare-ani poppins evev-compare-title">{{ i18nPage.title }}</h2>
        <h3 class="block-desc evev-compare-ani evev-compare-desc">{{ i18nPage.desc }}</h3>

        <div class="evev-compare-ani evev-compare-content">
            <div class="evev-compare-content-label before">
                <div class="text">{{ i18nPage.before }}</div>
                <div class="poppins date">2022-1-10</div>
            </div>
            <div class="evev-compare-content-label after">
                <div class="text">{{ i18nPage.after }}</div>
                <div class="poppins date">2022-6-15</div>
            </div>
            <TwentyTwenty :before="before" :after="after" v-if="before && after" />
        </div>
    </div>
</template>

<script>
import TwentyTwenty from 'vue-twentytwenty';
export default {
    components: {
        TwentyTwenty,
    },
    data() {
        return {
            before: '',
            after: '',
        };
    },
    computed: {
        i18nPage() {
            return this.$t('evevPage.compare');
        },
    },
    mounted() {
        var winWidth = window.innerWidth;
        if (winWidth <= 768) {
            this.before = require('@assets/images/product/evev/compare/mbefore.jpg');
            this.after = require('@assets/images/product/evev/compare/mafter.jpg');
        } else {
            this.before = require('@assets/images/product/evev/compare/before.jpg');
            this.after = require('@assets/images/product/evev/compare/after.jpg');
        }
        this.mxAnimation({
            handle: '.evev-compare-ani',
            triggerElement: '.evev-compare',
        });
    },
};
</script>

<style lang="scss">
.evev-compare {
    background-color: #1E2021;
    transform: translateZ(0);

    .twentytwenty-container .twentytwenty-handle:before,
    .twentytwenty-container .twentytwenty-handle:after {
        width: 1px;
        border: none;
        background: #fff;
        left: calc(50% - 1px);
    }
    .twentytwenty-container .twentytwenty-handle {
        // border: none;
        background: rgba(255, 255, 255, 0.15);
        border: 4px solid transparent;
    }
    .twentytwenty-container .twentytwenty-handle:before {
        top: 0;
    }
    .twentytwenty-container .twentytwenty-arrow-left {
        left: 8px;
        top: 16px;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-right: 4px solid white;
        position: absolute;
    }
    .twentytwenty-container .twentytwenty-arrow-right {
        left: 27px;
        top: 16px;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-left: 4px solid white;
        position: absolute;
    }
    .twentytwenty-overlay {
        background: transparent;
    }

    @media only screen and (min-width: 769px) {
        padding: 80px 0;
        color: #fff;
        &-content {
            width: 873px;
            height: 560px;
            margin: 40px auto 0 auto;
            border-radius: 30px;
            overflow: hidden;
            position: relative;

            &-label {
                position: absolute;
                bottom: 0;
                background: rgba(134, 235, 217, 0.27);
                z-index: 100;
                pointer-events: none;
                font-size: 17px;
                padding: 16px 40px;

                .date {
                    font-style: italic;
                    font-weight: 500;
                }

                &.before {
                    border-top-right-radius: 30px;
                    left: 0;
                }
                &.after {
                    border-top-left-radius: 30px;
                    right: 0;
                }
            }
        }
    }
    @media only screen and (max-width: 768px) {
        padding: 0.6rem 0;
        color: #fff;
        &-title {
            font-size: 0.28rem;
            line-height: 0.32rem;
            font-weight: 600;
            text-align: center;
        }
        &-desc {
            font-size: 0.13rem;
            line-height: 0.16rem;
            margin-top: 0.12rem;
            margin-bottom: 0.3rem;
            text-align: center;
        }
        &-content {
            position: relative;
            width: 3.45rem;
            height: 1.89rem;
            margin: 0 auto;

            &-label {
                position: absolute;
                bottom: 0;
                background: rgba(134, 235, 217, 0.27);
                z-index: 100;
                pointer-events: none;
                font-size: 0.16rem;
                line-height: 0.22rem;
                transform: scale(0.5);
                padding: 0.12rem 0.35rem;
                .text,
                .date {
                    white-space: nowrap;
                }

                .date {
                    line-height: 0.24rem;
                    font-style: italic;
                    font-weight: 500;
                }

                &.before {
                    border-top-right-radius: 16px;
                    left: 0;
                    transform-origin: 0 100%;
                }
                &.after {
                    border-top-left-radius: 16px;
                    right: 0;
                    transform-origin: 100% 100%;
                }
            }
        }
    }
}
</style>
