<template>
    <div class="virtual-makeup-tech">
        <div class="virtual-makeup-tech-container">
            <div class="block-title poppins virtual-makeup-tech-ani virtual-makeup-tech-title">{{ i18nPage.title }}</div>
            <div class="block-desc virtual-makeup-tech-ani virtual-makeup-tech-desc">{{ i18nPage.desc }}</div>

            <div class="virtual-makeup-tech-list">
                <div class="virtual-makeup-tech-ani virtual-makeup-tech-item" v-for="(item, index) in i18nPage.list" :key="index">
                    <img class="img" :src="require(`@assets/images/product/virtualMakeup/tech/${index + 1}.jpg`)" alt="" />
                    <div class="poppins virtual-makeup-tech-item-title">{{ item.title }}</div>
                    <div class="virtual-makeup-tech-item-desc">{{ item.desc }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        i18nPage() {
            return this.$t('virtualMakeupPage.tech');
        },
    },
    mounted() {
        this.mxAnimation({
            handle: '.virtual-makeup-tech-ani',
            triggerElement: '.virtual-makeup-tech-ani',
            interval: 0.1,
        });
    },
};
</script>

<style lang="scss">
.virtual-makeup-tech {
    background-color: #f9f9f9;

    @media only screen and (min-width: 769px) {
        padding: 80px 0;

        &-list {
            width: 1070px;
            margin: 40px auto 0 auto;
            display: flex;
            justify-content: space-between;
        }
        &-item {
            width: 218px;
            text-align: center;

            .img {
                display: block;
                width: 100%;
            }

            &-title {
                font-size: 20px;
                font-weight: 600;
                line-height: 1.5;
                margin-top: 25px;
                margin-bottom: 2px;
            }
            &-desc {
                font-size: 12px;
                width: 160px;
                margin: 0 auto;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        padding-top: 0.8rem;
        padding-bottom: 1rem;
        &-title {
            font-size: 0.28rem;
            line-height: 1.1;
            font-weight: 600;
            padding: 0 0.35rem;
        }
        &-desc {
            font-size: 0.13rem;
            padding: 0.2rem 0.35rem 0.65rem 0.35rem;
        }
        &-list {
            padding: 0 0.6rem;
        }
        &-item {
            text-align: center;
            margin-bottom: 0.5rem;
            &:last-of-type {
                margin-bottom: 0;
            }
            .img {
                display: block;
                width: 100%;
                margin-bottom: 0.2rem;
            }
            &-title {
                font-size: 0.2rem;
                font-weight: 600;
            }
            &-desc {
                font-size: 0.13rem;
                width: 2rem;
                margin: 0 auto;
            }
        }
    }
}
</style>
