<template>
    <div class="virtual-makeup-cloud" :style="`background: ${background};`">
        <div class="block-title poppins virtual-makeup-cloud-ani virtual-makeup-cloud-title">{{ i18nPage.title }}</div>
        <div class="virtual-makeup-cloud-container">
            <div class="virtual-makeup-cloud-info">
                <div class="poppins virtual-makeup-cloud-ani virtual-makeup-cloud-info-title">{{ i18nPage.text1 }}</div>
                <div class="virtual-makeup-cloud-ani virtual-makeup-cloud-info-desc">{{ i18nPage.text2 }}</div>
            </div>

            <div class="virtual-makeup-cloud-imgs">
                <img src="@assets/images/product/virtualMakeup/cloud/pc.png" alt="" class="img1 virtual-makeup-cloud-ani" />
                <img :src="img" alt="" class="img2 virtual-makeup-cloud-ani" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        background: {
            type: String,
            default: '#fff',
        },
        img: {
            type: String,
            default: require('@assets/images/product/virtualMakeup/cloud/1.jpg'),
        },
    },
    computed: {
        i18nPage() {
            return this.$t('virtualMakeupPage.cloud');
        },
    },
    mounted() {
        this.mxAnimation({
            handle: '.virtual-makeup-cloud-ani',
            triggerElement: '.virtual-makeup-cloud',
            triggerHook: 0.5,
        });
    },
};
</script>

<style lang="scss">
.virtual-makeup-cloud {
    @media only screen and (min-width: 769px) {
        padding-top: 80px;

        &-container {
            display: flex;
            align-items: center;
            width: 1070px;
            margin: 0 auto;
            padding-top: 40px;
        }
        &-info {
            width: 373px;
            margin-right: auto;
            &-title {
                font-size: 24px;
                font-weight: 500;
                width: 425px;
                line-height: 28px;
            }
            &-desc {
                font-size: 16px;
                line-height: 20px;
                margin-top: 16px;
                margin-bottom: 60px;
            }
        }
        &-imgs {
            position: relative;
            .img1 {
                width: 672px;
            }
            .img2 {
                width: 382px;
                position: absolute;
                box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05);
                top: 8px;
                right: 0px;
            }
        }
    }
    @media only screen and (max-width: 768px) {
        padding: 0.8rem 0;

        &-title {
            font-size: 0.28rem;
            font-weight: 600;
            padding: 0 0.35rem;
            line-height: 1.1;
        }
        &-info {
            width: 2.4rem;
            padding: 0 0.35rem;
            &-title {
                font-size: 0.2rem;
                font-weight: 600;
                margin-top: 0.2rem;
                margin-bottom: 0.13rem;
            }
            &-desc {
                font-size: 0.13rem;
                line-height: 0.18rem;
            }
        }
        &-imgs {
            margin-top: 0.73rem;
            position: relative;
            .img1 {
                width: 3.14rem;
                margin-left: 0.23rem;
            }
            .img2 {
                width: 1.85rem;
                position: absolute;
                box-shadow: 0 0 0.16rem rgba(0, 0, 0, 0.05);
                top: 0;
                right: 0.3rem;
            }
        }
    }
}
</style>
