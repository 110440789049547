<template>
    <div class="evev-saas">
        <div class="evev-saas-container">
            <h2 class="poppins block-title evev-saas-title" v-html="i18nPage.title"></h2>
            <div class="evev-saas-circle">
                <div class="block saas">{{ i18nPage.saas }}</div>
                <div class="block skin">{{ i18nPage.skin }}</div>
                <div class="block store">{{ i18nPage.store }}</div>
                <div class="block data">{{ i18nPage.dataManager }}</div>
                <div class="block customer">{{ i18nPage.customer }}</div>
            </div>
            <div class="evev-saas-list">
                <div class="evev-saas-list-frontend">
                    <h3 class="evev-saas-list-title">{{ i18nPage.frontManager }}</h3>
                    <div class="evev-saas-item" v-for="(item, index) in i18nPage.frontend" :key="index">{{ item }}</div>
                    <div class="evev-saas-item-more">......</div>
                    <div class="hidden-xs-only evev-saas-list-notice">{{ i18nPage.notice }}</div>
                </div>
                <div class="evev-saas-list-backend">
                    <h3 class="evev-saas-list-title">{{ i18nPage.backManager }}</h3>
                    <div class="evev-saas-item" v-for="(item, index) in i18nPage.backend" :key="index">{{ item }}</div>
                    <div class="evev-saas-item-more">......</div>
                    <div class="hidden-xs-only evev-saas-list-notice">{{ i18nPage.notice }}</div>
                </div>
            </div>
            <div class="hidden-sm-and-up evev-saas-notice">{{ i18nPage.notice }}</div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        i18nPage() {
            return this.$t('evevPage.saas');
        },
    },
};
</script>

<style lang="scss">
.evev-saas {
    background-color: #151515;

    @media only screen and (min-width: 769px) {
        height: 1127px;
        background: #151515 url(~@assets/images/product/evev/saas/bg.jpg) center no-repeat;
        background-size: auto 100%;

        &-title {
            font-size: 40px;
            line-height: 46px;
            font-weight: 600;
            text-align: center;
            color: #fff;
        }
        &-container {
            position: relative;
            width: 1218px;
            margin: 0 auto;
            padding-top: 200px;
            box-sizing: border-box;
        }
        &-circle {
            width: 540px;
            height: 540px;
            position: absolute;
            left: 50%;
            top: 335px;
            transform: translateX(-50%);
            .block {
                color: #fff;
                position: absolute;
                // border: solid 1px #ddd;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                box-sizing: border-box;
                font-size: 22px;
                line-height: 1.1;
            }
            .saas {
                width: 216px;
                height: 216px;
                font-size: 72px;
                font-weight: 500;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
            .skin,
            .data {
                width: 380px;
                height: 130px;
                left: 80px;
            }
            .skin {
                top: 30px;
            }
            .data {
                bottom: 30px;
            }
            .store,
            .customer {
                width: 160px;
                height: 300px;
                top: 120px;
            }
            .store {
                left: 0;
            }
            .customer {
                right: 0;
            }
        }
        &-list {
            font-size: 14px;
            line-height: 20px;
            color: #fff;
            &-frontend,
            &-backend {
                position: absolute;
                width: 200px;
                top: 450px;
                // border: solid 1px #ddd;
            }
            &-frontend {
                left: 100px;
            }
            &-backend {
                right: 70px;
            }
            &-title {
                line-height: 24px;
                color: #56F0ED;
                margin-bottom: 15px;
                font-weight: 500;
            }
            &-notice {
                color: rgba(255, 255, 255, 0.3);
                margin-top: 18px;
            }
        }
        &-item {
            margin-bottom: 15px;
            color: #fff;
            position: relative;

            &:before {
                content: '';
                position: absolute;
                width: 16px;
                height: 11px;
                background: url("data:image/svg+xml,%3Csvg width='16' height='11' viewBox='0 0 16 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 6.204l3.774 3.774a1.525 1.525 0 0 0 2.156 0l8.627-8.626' stroke='%2356F0ED' stroke-width='.762' stroke-linecap='round'/%3E%3C/svg%3E") 0 0 no-repeat;
                background-size: contain;
                top: 4px;
                left: -30px;
            }

            &:last-of-type {
                &:before {
                    display: none;
                }
            }
        }
    }
    @media only screen and (max-width: 768px) {
        width: 100%;
        background: #151515 url(~@assets/images/product/evev/saas/mbg.jpg) center 0.52rem no-repeat;
        background-size: 100%;
        position: relative;
        padding-top: 4.58rem;

        &-title {
            font-size: 0.28rem;
            line-height: 0.44rem;
            text-align: center;
            color: #fff;
            padding: 0 0.35rem;
            font-weight: 600;
            position: absolute;
            width: 100%;
            box-sizing: border-box;
            left: 0;
            top: 0.6rem;
        }
        &-circle {
            width: 2.94rem;
            height: 2.94rem;
            position: absolute;
            left: 0.4rem;
            top: 1.45rem;
            .block {
                position: absolute;
                color: #fff;
                // background: rgba(255, 255, 255, 0.1);
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                font-size: 0.13rem;
                font-weight: 500;
            }

            .saas {
                width: 0.9rem;
                height: 0.9rem;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                font-size: 0.3rem;
            }
            .skin,
            .data {
                width: 1.5rem;
                height: 0.7rem;
                left: 0.72rem;
            }
            .skin {
                top: 0.26rem;
            }
            .data {
                bottom: 0.26rem;
            }
            .store,
            .customer {
                width: 0.9rem;
                height: 1.2rem;
                top: 0.87rem;
            }
            .store {
                left: 0.06rem;
            }
            .customer {
                right: 0.06rem;
            }
        }
        &-list {
            display: flex;
            font-size: 0.13rem;
            line-height: 0.2rem;
            font-weight: 300;
            color: #fff;

            &-frontend,
            &-backend {
                flex: 1;
                flex-shrink: 0;
            }
            &-frontend {
                padding-left: 0.4rem;
                padding-right: 0.2rem;
            }
            &-backend {
                padding-left: 0.4rem;
                padding-right: 0.2rem;
            }
            &-title {
                color: #56F0ED;
                margin-bottom: 0.15rem;
                font-weight: 500;
            }
        }
        &-item {
            margin-bottom: 0.15rem;
            position: relative;

            &:before {
                content: '';
                width: 0.13rem;
                height: 0.09rem;
                background: url("data:image/svg+xml,%3Csvg width='13' height='9' viewBox='0 0 13 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4.778l2.828 2.829a1 1 0 0 0 1.415 0l7.07-7.071' stroke='%2356F0ED' stroke-width='.5' stroke-linecap='round'/%3E%3C/svg%3E") 0 0 no-repeat;
                background-size: contain;
                position: absolute;
                top: 0.05rem;
                left: -0.2rem;
            }
        }
        &-notice {
            padding: 0 0.3rem;
            color: rgba(255, 255, 255, 0.3);
            margin-top: 0.25rem;
            text-align: center;
            font-size: 0.13rem;
        }
    }
}
</style>
