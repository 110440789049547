<template>
    <div class="eve-light5">
        <div class="eve-light5-container">
            <div class="block-title poppins eve-light5-ani eve-light5-title" v-html="i18nPage.title"></div>
            <div class="poppins eve-light5-ani eve-light5-subtitle">{{ i18nPage.subtitle }}</div>
            <div class="block-desc eve-light5-ani eve-light5-desc">{{ i18nPage.desc }}</div>

            <div class="eve-light5-main">
                <div class="eve-light5-ani eve-light5-item eve-light5-item1">
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><ellipse cx="14" cy="13" rx="4" ry="4" stroke="#000" stroke-linecap="round"/><ellipse cx="14" cy="13" rx="4" ry="4" stroke="#fff" stroke-linecap="round"/><ellipse cx="14.6" cy="6" rx="1.037" ry="1" fill="#fff"/><path d="M8 26.5h12" stroke="#000" stroke-linecap="round"/><path d="M8 26.5h12M11 24l-1 2.5m7-2.5l1 2.5" stroke="#fff" stroke-linecap="round"/><circle cx="14" cy="13" r="11" stroke="#000" stroke-linecap="round"/><circle cx="14" cy="13" r="11" stroke="#fff" stroke-linecap="round"/></svg>
                    <div class="text">{{ i18nPage.item1 }}</div>
                </div>
                <div class="eve-light5-ani eve-light5-item eve-light5-item2">
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.5 1.466h21v25.069h-21V1.465z" stroke="#fff"/><path d="M6.5 4.53h14.843v11.962H6.5V4.53z" stroke="#fff"/><path d="M7.543 21.713l-.58-1.89h-.015a395.987 395.987 0 0 1 .025.492c.004.102.006.195.006.277v1.12h-.456v-2.409h.695l.57 1.843h.01l.605-1.843H9.1v2.41h-.477v-1.14a11.93 11.93 0 0 1 .025-.747h-.015l-.621 1.887h-.468zm3.551 0H9.707v-2.41h1.387v.419h-.876v.53h.815v.418h-.815v.621h.876v.422zm.505 0v-2.41h.51v2.41h-.51zm2.047 0h-.511v-1.984h-.655v-.426h1.82v.426h-.654v1.984zm2.986-2.41v1.56a.919.919 0 0 1-.11.45.776.776 0 0 1-.332.316 1.183 1.183 0 0 1-.557.117c-.318 0-.561-.081-.728-.244-.167-.163-.25-.378-.25-.646v-1.553h.508v1.475c0 .2.041.339.122.419.082.08.202.12.361.12.112 0 .203-.019.272-.057a.344.344 0 0 0 .155-.178.815.815 0 0 0 .05-.307v-1.472h.509zm1.977 2.41h-1.387v-2.41h1.387v.419h-.876v.53h.816v.418h-.816v.621h.877v.422zm2.395-2.41l-.819 2.41h-.557l-.817-2.41h.515l.454 1.434.038.137c.017.066.035.136.052.211.019.074.032.135.038.183.007-.048.018-.11.035-.183l.05-.21a4.48 4.48 0 0 1 .039-.138l.456-1.434h.516zm1.693 2.41h-1.388v-2.41h1.388v.419h-.877v.53h.816v.418h-.816v.621h.877v.422z" fill="#fff"/><path d="M6.5 23.5h3" stroke="#fff" stroke-width=".75" stroke-linecap="square"/></svg>
                    <div class="text">{{ i18nPage.item2 }}</div>
                </div>
                <div class="eve-light5-ani eve-light5-item eve-light5-item3">
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.75 21.637c0-.197.118-.375.3-.452a7.614 7.614 0 0 0 4.648-7.004c0-4.205-3.428-7.614-7.657-7.614s-7.656 3.41-7.656 7.614a7.613 7.613 0 0 0 4.57 6.97.491.491 0 0 1 .293.45v2.594h5.502v-2.558z" stroke="#fff" stroke-width="1.167"/><path d="M10 13l2.654 2.654a.5.5 0 0 0 .7.007l1.8-1.729a.5.5 0 0 1 .692 0L18 16" stroke="#fff" stroke-linecap="round"/><path d="M.491 14.428h2.162a.491.491 0 1 0 0-.983H.49a.491.491 0 1 0 0 .983zM25.347 14.426h2.162a.491.491 0 1 0 0-.982h-2.162a.491.491 0 1 0 0 .982zM22.372 6.305L23.9 4.777a.491.491 0 1 0-.695-.695L21.677 5.61a.491.491 0 0 0 .695.695zM4.1 4.776l1.53 1.528a.491.491 0 0 0 .694-.695L4.796 4.081a.491.491 0 0 0-.695.695zM13.51.491v2.162a.491.491 0 0 0 .982 0V.49a.491.491 0 1 0-.983 0z" fill="#fff"/><path d="M11 27.5h6" stroke="#fff" stroke-linecap="round"/></svg>
                    <div class="text">{{ i18nPage.item3 }}</div>
                </div>
                <div class="eve-light5-ani eve-light5-item eve-light5-item4">
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="M3.403 4.091C6.831 5.647 10.363 6.424 14 6.424c3.637 0 7.17-.777 10.597-2.333.488-.221 1.073-.026 1.307.436a.886.886 0 0 1 .096.4v3.704C26 13.895 20 15 14 15S2 13.895 2 8.631V4.927C2 4.415 2.439 4 2.98 4c.146 0 .29.031.423.091z" stroke="#fff" stroke-linecap="round"/><path d="M4 25l1.663-5.454c.682-2.237.6-4.278-.663-6.046M23 25l-1.663-5.454c-.682-2.237-.6-3.778.663-5.546M2 7.031C4.887 8.848 9.18 10 13.975 10 18.797 10 23.113 8.835 26 7" stroke="#fff" stroke-linecap="round"/></svg>
                    <div class="text">{{ i18nPage.item4 }}</div>
                </div>
                <div class="eve-light5-ani eve-light5-item eve-light5-item5">
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="13.676" cy="13.676" r="10.699" stroke="#fff"/><path d="M26.275 13.676h-4.2m-16.798 0h-4.2M13.676 1.077v4.308m.108 16.69v4.327" stroke="#fff" stroke-linecap="round"/><path d="M7.957 13.338h11.008M13.462 7.889v10.898" stroke="#fff" stroke-width=".5" stroke-dasharray="0.62 0.62"/></svg>
                    <div class="text" v-html="i18nPage.item5"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        i18nPage() {
            return this.$t('evePage.light5');
        },
    },
    mounted() {
        this.mxAnimation({
            handle: '.eve-light5-ani',
            triggerElement: '.eve-light5-ani',
        });
    },
};
</script>

<style lang="scss">
.eve-light5 {
    @media only screen and (min-width: 769px) {
        &-container {
            max-width: 1108px;
            margin: 0 auto;
            padding: 80px 20px;
        }
        &-title {
            width: 516px;
            text-align: left;
            margin-left: 0;
        }
        &-subtitle {
            font-size: 24px;
            line-height: 28.8px;
            font-weight: 500;
            color: #00C8C8;
            margin-top: 30px;
        }
        &-desc {
            width: 737px;
            text-align: left;
            margin-left: 0 !important;
        }
        &-main {
            width: 1102px;
            height: 607px;
            // transform: scale(0.969147005444646);
            // transform-origin: 0 0;
            margin-top: 40px;
            position: relative;
        }
        &-item {
            position: absolute;

            svg {
                display: block;
                margin: 30px 0 8px 34px;
            }

            .text {
                color: #fff;
                font-size: 18px;
                font-weight: 400;
                margin-left: 34px;
                line-height: 25px;
            }
        }
        &-item1 {
            width: 414px;
            height: 390px;
            left: 0;
            top: 0;
            background: url(~@assets/images/product/eve/light5/1.png) 0 0 no-repeat;
            background-size: contain;

            .text {
                width: 222px;
            }
        }
        &-item2 {
            width: 354px;
            height: 390px;
            left: 433px;
            top: 0;
            background: url(~@assets/images/product/eve/light5/2.png) 0 0 no-repeat;
            background-size: contain;
            .text {
                width: 195px;
            }
        }
        &-item3 {
            width: 302px;
            height: 390px;
            top: 0;
            right: 0;
            background: url(~@assets/images/product/eve/light5/3.png) 0 0 no-repeat;
            background-size: contain;
            .text {
                width: 182px;
            }
        }
        &-item4 {
            width: 619px;
            height: 198px;
            left: 0;
            bottom: 0;
            background: url(~@assets/images/product/eve/light5/4.png) 0 0 no-repeat;
            background-size: contain;
            .text {
                width: 294px;
            }
        }
        &-item5 {
            width: 467px;
            height: 198px;
            right: 0;
            bottom: 0;
            background: url(~@assets/images/product/eve/light5/5.png) 0 0 no-repeat;
            background-size: contain;
            .text {
                width: 182px;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        &-title {
            font-size: 0.28rem;
            font-weight: 600;
            line-height: 1.15;
            padding: 0.8rem 0.35rem 0.25rem 0.35rem;
        }
        &-subtitle {
            font-size: 0.2rem;
            line-height: 1.1;
            font-weight: 600;
            padding: 0 0.36rem;
            color: #00C8C8;
        }
        &-desc {
            font-size: 0.13rem;
            font-weight: 400;
            padding: 0.14rem 0.35rem 0.23rem 0.35rem;
        }
        &-main {
            margin: 0 0.35rem 0.8rem 0.35rem;
            height: 5.72rem;
            position: relative;
        }
        &-item {
            position: absolute;

            svg {
                width: 0.16rem;
                height: 0.16rem;
                margin: 0.2rem 0 0.08rem 0.13rem;
            }
            .text {
                color: #fff;
                font-size: 0.1rem;
                margin-left: 0.13rem;
                width: 0.95rem;
                line-height: 1.3;
            }
        }
        &-item1 {
            width: 1.32rem;
            height: 2.7rem;
            background: url(~@assets/images/product/eve/light5/mobile/1.png) 0 0 no-repeat;
            background-size: contain;
            left: 0;
            top: 0;
        }
        &-item2 {
            width: 1.66rem;
            height: 1.88rem;
            background: url(~@assets/images/product/eve/light5/mobile/2.png) 0 0 no-repeat;
            background-size: contain;
            right: 0;
            top: 0;
        }
        &-item3 {
            width: 1.32rem;
            height: 1.75rem;
            background: url(~@assets/images/product/eve/light5/mobile/3.png) 0 0 no-repeat;
            background-size: contain;
            left: 0;
            top: 2.75rem;
        }
        &-item4 {
            width: 1.66rem;
            height: 2.57rem;
            background: url(~@assets/images/product/eve/light5/mobile/4.png) 0 0 no-repeat;
            background-size: contain;
            right: 0;
            top: 1.93rem;
        }
        &-item5 {
            width: 3.03rem;
            height: 1.17rem;
            background: url(~@assets/images/product/eve/light5/mobile/5.png) 0 0 no-repeat;
            background-size: contain;
            left: 0;
            bottom: 0;
        }
    }
}
</style>
