<template>
    <div class="virtual-hair-example">
        <div class="virtual-hair-example-container">
            <div class="virtual-info virtual-hair-example-info">
                <div class="virtual-info-title poppins virtual-hair-example-ani virtual-hair-example-info-title">{{ i18nPage.title }}</div>
                <div class="virtual-info-desc virtual-hair-example-ani virtual-hair-example-info-desc">{{ i18nPage.desc }}</div>
            </div>

            <div class="virtual-hair-example-ani virtual-hair-example-imgs">
                <img class="virtual-hair-example-img" :class="{ active: index === current }" :src="require(`@assets/images/product/virtualHair/example/${index}.png`)" alt="" v-for="index in 4" :key="index" />
                <div class="virtual-hair-example-tabs">
                    <div class="virtual-hair-example-ani virtual-hair-example-tabhead"></div>
                    <div class="virtual-hair-example-ani virtual-hair-example-tab" :class="{ active: current === index + 1 }" @click="onTabClick(index)" v-for="(item, index) in list" :key="index" :style="`--bg: ${item.color};`"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        i18nPage() {
            return this.$t('virtualHairPage.example');
        },
    },
    data() {
        return {
            current: 1,
            list: [
                {
                    color: '#472337',
                },
                {
                    color: '#72261d',
                },
                {
                    color: '#20233d',
                },
                {
                    color: '#102521',
                },
            ],
        };
    },
    methods: {
        onTabClick(index) {
            clearInterval(this.timer);
            this.current = index + 1;
        },
        play() {
            if (this.timer) {
                clearInterval(this.timer);
            }
            this.timer = setInterval(() => {
                if (this.current === 4) {
                    this.current = 1;
                } else {
                    this.current++;
                }
            }, 2000);
        },
    },
    mounted() {
        this.mxAnimation({
            handle: '.virtual-hair-example-ani',
            triggerElement: '.virtual-hair-example-info-title',
            interval: 0.15,
            onEnter: () => {
                this.play();
            },
        });
    },
    beforeDestroy() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    },
};
</script>

<style lang="scss">
.virtual-hair-example {
    background-color: #f9f9f9;
    @media only screen and (min-width: 769px) {
        &-container {
            display: flex;
            align-items: center;
            width: 1070px;
            margin: 0 auto;
            padding-top: 80px;
            padding-bottom: 80px;
        }
        &-info {
            flex-shrink: 0;
            width: 500px;
            margin-right: auto;
        }
        &-imgs {
            height: 588px;
            position: relative;
            flex: 1;
            background: url(~@assets/images/product/mobile.png) 150px 0 no-repeat;
            background-size: 294px auto;

            &:after {
                content: '';
                position: absolute;
                width: 348px;
                height: 99px;
                background: rgba(0, 0, 0, 0.2);
                background: url(~@assets/images/product/shadow.png) 0 0 no-repeat;
                background-size: contain;
                left: 50%;
                bottom: -100px;
                transform: translateX(-50%);
            }
        }
        &-img {
            position: absolute;
            height: 100%;
            left: 150px;
            top: 0;
            opacity: 0;
            transition: all 0.6s;

            &.active {
                opacity: 1;
                transition: all 1s;
            }
        }
        &-tabs {
            display: flex;
            align-items: center;
            position: absolute;
            left: 100px;
            top: 410px;
        }
        &-tabhead {
            width: 88px;
            height: 88px;
            background: url(~@assets/images/product/virtualHair/example/tabhead.jpg) 0 0 no-repeat;
            background-size: contain;
        }
        &-tab {
            width: 66px;
            height: 66px;
            margin: 0 10px;
            cursor: pointer;
            border-radius: 33px;
            position: relative;
            background: var(--bg);

            &.active {
                background: transparent !important;
                &:before {
                    content: '';
                    width: 66px;
                    height: 66px;
                    border-radius: 44px;
                    border: solid 3px var(--bg);
                    position: absolute;
                    left: 0;
                    top: 0;
                    box-sizing: border-box;
                }
                &:after {
                    content: '';
                    width: 56px;
                    height: 56px;
                    border-radius: 44px;
                    position: absolute;
                    left: 5px;
                    top: 5px;
                    background: var(--bg);
                }
            }
        }
    }
    @media only screen and (max-width: 768px) {
        &-container {
            padding-bottom: 0.8rem;
        }
        &-info {
            width: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            padding: 0.8rem 0.35rem 0.5rem 0.35rem;

            &-title {
                font-size: 0.28rem;
                font-weight: 600;
                line-height: 1.1;
            }
            &-desc {
                font-size: 0.13rem;
                width: 2.8rem;
                margin-top: 0.2rem;
            }
        }
        &-imgs {
            height: 4.4rem;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                width: 2.62rem;
                height: 0.75rem;
                background: url(~@assets/images/product/mshadow.png) 0 0 no-repeat;
                background-size: contain;
                left: 50%;
                transform: translateX(-50%);
                bottom: -0.72rem;
            }
        }
        &-img {
            position: absolute;
            width: 2.2rem;
            left: 0.775rem;
            top: 0;
            opacity: 0;
            transition: all 0.5s;

            &.active {
                opacity: 1;
            }
        }
        &-tabs {
            display: flex;
            align-items: center;
            position: absolute;
            z-index: 2;
            left: 0.2rem;
            top: 3.12rem;
        }
        &-tabhead {
            width: 0.66rem;
            height: 0.66rem;
            background: url(~@assets/images/product/virtualHair/example/tabhead.jpg) 0 0 no-repeat;
            background-size: contain;
            margin-right: 0.1rem;
        }
        &-tab {
            width: 0.5rem;
            height: 0.5rem;
            margin: 0 0.08rem;
            cursor: pointer;
            border-radius: 0.3rem;
            position: relative;
            background: var(--bg);

            &.active {
                background: transparent !important;
                &:before {
                    content: '';
                    width: 0.5rem;
                    height: 0.5rem;
                    border-radius: 0.3rem;
                    border: solid 0.02rem var(--bg);
                    position: absolute;
                    left: 0;
                    top: 0;
                    box-sizing: border-box;
                }
                &:after {
                    content: '';
                    width: 0.44rem;
                    height: 0.44rem;
                    border-radius: 0.3rem;
                    position: absolute;
                    left: 0.03rem;
                    top: 0.03rem;
                    background: var(--bg);
                }
            }
        }
    }
}
</style>
