<template>
    <div class="meitukey-add">
        <div class="poppins meitukey-add-ani meitukey-add-title">{{ i18nPage.title }}</div>

        <div class="meitukey-add-list">
            <div class="meitukey-add-ani meitukey-add-item" v-for="(item, index) in list" :key="index">
                <div class="meitukey-add-item-top">
                    <div class="meitukey-add-item-top-title">{{ item.title }}</div>
                    <div class="meitukey-add-item-top-desc">{{ item.desc }}</div>
                </div>
                <div class="meitukey-add-item-bottom">
                    <div class="meitukey-add-item-bottom-left">
                        <img :src="item.img1" alt="" class="img1" :class="[`img1-${index}`]" />
                        <div class="tip">{{ item.tip }}</div>
                    </div>
                    <div class="meitukey-add-img">
                        <img :src="item.img2" alt="" class="img2" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        i18nPage() {
            return this.$t('meitukeyPage.add');
        },
        list() {
            let i = 1;
            let lists = this.i18nPage.list.map(item => {
                const data = {
                    ...item,
                    img1: require(`@assets/images/product/fitting/add-${i}.jpg`),
                    img2: require(`@assets/images/product/fitting/add-${i + 1}.png`),
                };
                i += 2;
                return data;
            });
            lists.splice(1, 1); // v3.4去掉【防晒检测】
            return lists;
        },
    },
};
</script>

<style lang="scss">
.meitukey-add {
    @media only screen and (max-width: 768px) {
        padding-bottom: 0.8rem;
        &-title {
            font-size: 0.28rem;
            padding: 0.8rem 0.35rem 0.4rem 0.35rem;
            font-weight: 600;
            line-height: 1.1;
        }
        &-list {
            padding: 0 0.22rem;
        }
        &-item {
            background: #fff;
            box-shadow: 0 0 0.15rem rgba(0, 0, 0, 0.1);
            border-radius: 0.15rem;
            padding: 0.3rem 0.28rem;
            margin-bottom: 0.3rem;

            &:last-of-type {
                margin-bottom: 0;
            }

            &-top {
                &-title {
                    font-size: 0.13rem;
                    font-weight: 600;
                }
                &-desc {
                    font-size: 0.1rem;
                    font-weight: 300;
                    margin-top: 0.05rem;
                    margin-bottom: 0.14rem;
                }
            }
            &-bottom {
                display: flex;

                &-left {
                    width: 1rem;
                    margin-right: 0.14rem;
                    .img1 {
                        display: block;
                        width: 100%;
                        margin-top: 0.18rem;
                        &-1, &-2 {
                            margin-top: 0.3rem;
                        }
                    }
                    .tip {
                        font-size: 0.07rem;
                        color: rgba(0, 0, 0, 0.4);
                        margin-top: 0.12rem;
                    }
                }
            }
        }
        &-info {
            width: 100%;
            &-title {
                font-size: 0.13rem;
                font-weight: 600;
            }
            &-desc {
                font-size: 10px;
                margin-top: 0.05rem;
                margin-bottom: 0.14rem;
            }
        }
        &-img {
            width: 1.63rem;

            .img2 {
                display: block;
                width: 100%;
            }
        }

        &-tabs {
            width: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            border-bottom: 1px solid rgba(0, 0, 0, 0.3);
            padding: 0.06rem 0;
            font-size: 0.1rem;
            margin-top: 0.3rem;
            text-align: center;
            position: relative;

            &-line {
                position: absolute;
                height: 1px;
                left: 0;
                bottom: -1px;
                background: #000;
                transition: all 0.3s;
            }
        }
        &-tab {
            padding: 0 0.1rem;
            opacity: 0.3;
            transition: all 0.3s;

            &.active {
                opacity: 1;
            }
        }
    }
}
</style>
