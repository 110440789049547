<template>
    <div class="evev-machine">
        <h2 class="block-title poppins evev-machine-ani evev-machine-title">{{ i18nPage.title }}</h2>
        <div class="block-desc evev-machine-ani evev-machine-desc">{{ i18nPage.desc }}</div>
        <div class="evev-machine-ani evev-machine-imgs">
            <div class="evev-machine-img" :class="[`evev-machine-img${index}`, { 'evev-machine-img-overseas': buildForOverseas, active: index === current }]" v-for="(item, index) in i18nPage.tabs" :key="index"></div>
        </div>
        <div class="evev-machine-ani evev-machine-tabs">
            <div class="evev-machine-tabs-container">
                <a href="javascript:;" class="evev-machine-tab" :class="{ active: current === index }" v-for="(item, index) in i18nPage.tabs" :key="index" @click="onTabClick(index)">{{ item }}</a>
                <div class="evev-machine-tabs-line" ref="line" :style="lineStyle"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            current: 0,
            lineStyle: {},
            buildForOverseas: process.env.VUE_APP_BUILD_FOR === 'evelabinsight',
        };
    },
    computed: {
        i18nPage() {
            return this.$t('evevPage.machine');
        },
    },
    methods: {
        onTabClick(index) {
            this.current = index;
            this.currentChange();
        },
        async currentChange() {
            await this.$nextTick();

            var el = this.$el.querySelector('.evev-machine-tab.active');
            if (!el) return;
            this.lineStyle = {
                width: el.offsetWidth + 'px',
                left: el.offsetLeft + 'px',
            };
        },
    },
    mounted() {
        this.currentChange();

        this.mxAnimation({
            handle: '.evev-machine-ani',
            triggerElement: '.evev-machine',
        });
    },
};
</script>

<style lang="scss">
.evev-machine {
    color: #fff;

    @media only screen and (min-width: 769px) {
        height: 731px;
        background: #151515 url(~@assets/images/product/evev/machine/bg.jpg) center no-repeat;
        background-size: auto 100%;
        padding: 80px 0;
        box-sizing: border-box;
        &-imgs {
            height: 462px;
            position: relative;
        }
        &-img {
            position: absolute;
            left: 50%;
            top: 40px;
            transform: translateX(-50%);
            opacity: 0;
            transition: all 0.5s;
            width: 426px;
            height: 382px;

            &.active {
                opacity: 1;
            }
            &0 {
                background: url(~@assets/images/product/evev/machine/0.png) 0 0 no-repeat;
                background-size: contain;
            }
            &1 {
                background: url(~@assets/images/product/evev/machine/1.png) 0 0 no-repeat;
                background-size: contain;
            }
            &2 {
                background: url(~@assets/images/product/evev/machine/2.png) 0 0 no-repeat;
                background-size: contain;
            }
            &3 {
                background: url(~@assets/images/product/evev/machine/3.png) 0 0 no-repeat;
                background-size: contain;
            }
            &4 {
                background: url(~@assets/images/product/evev/machine/4.png) 0 0 no-repeat;
                background-size: contain;
            }
            &5 {
                background: url(~@assets/images/product/evev/machine/5.png) 0 0 no-repeat;
                background-size: contain;
            }
        }
        &-img-overseas {
            &.evev-machine-img0 {
                background: url(~@assets/images/product/evev/machine/evelabinsight/0.png) 0 0 no-repeat;
                background-size: contain;
            }
            &.evev-machine-img1 {
                background: url(~@assets/images/product/evev/machine/evelabinsight/1.png) 0 0 no-repeat;
                background-size: contain;
            }
            &.evev-machine-img3 {
                background: url(~@assets/images/product/evev/machine/evelabinsight/3.png) 0 0 no-repeat;
                background-size: contain;
            }
        }
        &-tabs {
            display: flex;

            &-container {
                padding-bottom: 6px;
                border-bottom: 1px solid rgba(255, 255, 255, 0.3);
                margin: 0 auto;
                position: relative;
            }
            &-line {
                position: absolute;
                height: 2px;
                background: #00C8C8;
                bottom: -1px;
                transition: all .4s;
            }
        }
        &-tab {
            padding: 0 5px;
            font-size: 20px;
            line-height: 25px;
            margin: 0 30px;
            color: rgba(255, 255, 255, 0.3);

            &.active {
                color: #00C8C8;
                font-weight: 700;
            }

            &:first-of-type {
                margin-left: 0;
            }
            &:last-of-type {
                margin-right: 0;
            }
        }
    }
    @media only screen and (max-width: 768px) {
        width: 3.75rem;
        height: 5.03rem;
        background: #151515 url(~@assets/images/product/evev/machine/mbg.png) 0 0 no-repeat;
        background-size: auto 100%;
        padding: 0.6rem 0;
        box-sizing: border-box;

        &-title {
            font-size: 0.28rem;
            line-height: 0.32rem;
            font-weight: 600;
            text-align: center;
        }
        &-desc {
            font-size: 0.13rem;
            line-height: 0.16rem;
            margin-top: 0.12rem;
            text-align: center;
        }
        &-imgs {
            position: relative;
            height: 2.8rem;
        }
        &-img {
            position: absolute;
            left: 50%;
            top: 0.4rem;
            transform: translateX(-50%);
            opacity: 0;
            transition: all 0.5s;
            width: 2.13rem;
            height: 1.91rem;

            &.active {
                opacity: 1;
            }
            &0 {
                background: url(~@assets/images/product/evev/machine/0.png) 0 0 no-repeat;
                background-size: contain;
            }
            &1 {
                background: url(~@assets/images/product/evev/machine/1.png) 0 0 no-repeat;
                background-size: contain;
            }
            &2 {
                background: url(~@assets/images/product/evev/machine/2.png) 0 0 no-repeat;
                background-size: contain;
            }
            &3 {
                background: url(~@assets/images/product/evev/machine/3.png) 0 0 no-repeat;
                background-size: contain;
            }
            &4 {
                background: url(~@assets/images/product/evev/machine/4.png) 0 0 no-repeat;
                background-size: contain;
            }
            &5 {
                background: url(~@assets/images/product/evev/machine/5.png) 0 0 no-repeat;
                background-size: contain;
            }
        }
        &-img-overseas {
            &.evev-machine-img0 {
                background: url(~@assets/images/product/evev/machine/evelabinsight/0.png) 0 0 no-repeat;
                background-size: contain;
            }
            &.evev-machine-img1 {
                background: url(~@assets/images/product/evev/machine/evelabinsight/1.png) 0 0 no-repeat;
                background-size: contain;
            }
            &.evev-machine-img3 {
                background: url(~@assets/images/product/evev/machine/evelabinsight/3.png) 0 0 no-repeat;
                background-size: contain;
            }
        }
        &-tabs {
            display: flex;
            padding: 0 0.1rem;

            &-container {
                padding-bottom: 0.06rem;
                border-bottom: 1px solid rgba(255, 255, 255, 0.3);
                margin: 0 auto;
                position: relative;
                display: flex;
                align-items: center;
            }
            &-line {
                position: absolute;
                height: 2px;
                background: #00C8C8;
                bottom: -1px;
                transition: all 0.4s;
            }
        }
        &-tab {
            padding: 0 0.05rem;
            font-size: 0.13rem;
            line-height: 1.2;
            color: rgba(255, 255, 255, 0.3);
            margin: 0 0.06rem;
            text-align: center;
            &:first-of-type {
                margin-left: 0;
            }
            &:last-of-type {
                margin-right: 0;
            }

            &.active {
                color: #00C8C8;
            }
        }
    }
}
</style>
