<template>
    <div class="meitukey-add">
        <div class="block-title poppins meitukey-add-ani meitukey-add-title">{{ i18nPage.title }}</div>

        <div class="meitukey-add-container">
            <swiper ref="addSwiper" class="meitukey-add-ani meitukey-add-swiper" :options="swiperOptions" @slideChange="onSlide">
                <swiper-slide :class="[`key-${item.key}`, `swiper-index-${index}`]" v-for="(item, index) in list" :key="index">
                    <div class="meitukey-add-info">
                        <div class="meitukey-add-info-title">{{ item.title }}</div>
                        <div class="meitukey-add-info-desc">{{ item.desc }}</div>
                        <img :src="item.img1" alt="" class="img1" />
                        <div class="meitukey-add-ani meitukey-add-info-tip">{{ item.tip }}</div>
                    </div>
                    <div class="meitukey-add-img">
                        <img :src="item.img2" alt="" class="img2" />
                    </div>
                </swiper-slide>
            </swiper>
            <div class="meitukey-add-ani meitukey-add-tabs" ref="scrollWrap">
                <div class="meitukey-add-tab" @click="onTabClick(index)" :class="[{ active: activeIndex === index }, `meitukey-add-tab-${index}`, currentLang]" v-for="(item, index) in list" :key="index">
                    {{ item.title }}
                </div>
                <div class="meitukey-add-tabs-line"></div>
            </div>
        </div>
    </div>
</template>

<script>
import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';

export default {
    components: {
        swiper,
        swiperSlide,
    },
    computed: {
        i18nPage() {
            return this.$t('meitukeyPage.add');
        },
        list() {
            let i = 1;
            let lists = this.i18nPage.list.map(item => {
                const data = {
                    ...item,
                    img1: require(`@assets/images/product/fitting/add-${i}.jpg`),
                    img2: require(`@assets/images/product/fitting/add-${i + 1}.png`),
                };
                i += 2;
                return data;
            });
            lists.splice(1, 1); // v3.4去掉【防晒检测】
            return lists;
        },
    },
    data() {
        return {
            activeIndex: 0,
            swiperOptions: {
                effect: 'fade',
                speed: 400,
            },
        };
    },
    watch: {
        activeIndex() {
            this.setTabLine();
        },
    },
    methods: {
        onTabClick(index) {
            this.activeIndex = index;
            this.$refs.addSwiper.swiper.slideToLoop(index);
        },
        onSlide() {
            this.$nextTick(() => {
                const swiper = this.$refs.addSwiper.swiper;
                this.activeIndex = swiper.realIndex;
            });
        },
        setTabLine() {
            let index = this.activeIndex;
            const el = document.querySelector(`.meitukey-add-tab-${index}`);
            const w = el.offsetWidth;
            const l = index === 0 ? 0 : el.offsetLeft;
            const style = `width:${w}px;-webkit-transform:translateX(${l}px);transform:translateX(${l}px);`;
            document.querySelector('.meitukey-add-tabs-line').setAttribute('style', style);
        },
    },
    mounted() {
        this.setTabLine();

        this.mxAnimation({
            handle: '.meitukey-add-ani',
            triggerElement: '.meitukey-add-title',
            triggerHook: 0.5,
        });
    },
};
</script>

<style lang="scss">
.meitukey-add {
    .swiper-slide {
        height: unset;
    }
    @media only screen and (min-width: 769px) {
        padding: 80px 0;
        &-container {
            width: 844px;
            margin: 40px auto 0 auto;
        }
        &-swiper {
            box-shadow: 0 3px 40px rgba(0, 0, 0, 0.1);
            border-radius: 10px;

            .swiper-slide {
                display: flex;
                padding: 0 50px 50px 50px;
                box-sizing: border-box;
                background-color: #fff;
            }
        }
        &-tabs {
            width: 100%;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid rgba(0, 0, 0, 0.3);
            font-size: 14px;
            padding: 8px 0;
            margin-top: 45px;
            position: relative;

            &-line {
                position: absolute;
                height: 2px;
                background: #000;
                transition: all 0.3s;
                left: 0;
                bottom: -1px;
            }
        }

        &-info {
            width: 50%;
            padding-right: 70px;
            box-sizing: border-box;

            &-title {
                font-size: 18px;
                font-weight: 600;
                padding-top: 56px;
            }
            &-desc {
                font-size: 10px;
                margin-top: 5px;
                margin-bottom: 40px;
            }
            .img1 {
                width: 250px;
                display: block;
                margin-bottom: 15px;
            }
            &-tip {
                font-size: 11px;
                color: rgba(0, 0, 0, 0.4);
            }
        }
        &-img {
            width: 50%;
            padding-top: 20px;

            .img2 {
                display: block;
                width: 390px;
                max-width: 100%;
            }
        }

        &-tab {
            font-size: 18px;
            line-height: 25px;
            font-weight: 300;
            padding: 0 20px;
            cursor: pointer;
            color: rgba(0, 0, 0, 0.3);

            &.active {
                color: #000;
                font-weight: 600;
            }
            &.zh,
            &.tw {
                font-size: 20px;
            }
        }
    }
}
</style>
