<template>
    <div class="product-home-top" :class="[currentLang]">
        <div class="product-home-top-container">
            <div class="info">
                <h1 class="poppins info-title" v-html="i18nPage.title"></h1>
                <h2 class="info-desc">{{ i18nPage.desc }}</h2>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        i18nPage() {
            return this.$t('productHomePage.top');
        },
    },
};
</script>

<style lang="scss">
.product-home-top {
    @media only screen and (min-width: 769px) {
        height: 609px;
        margin: 0 auto;
        background: url(~@assets/images/product/home/top/2.jpg) center no-repeat;
        background-size: cover;

        &-container {
            max-width: 1108px;
            margin: 0 auto;
            height: 100%;
            display: flex;
            align-items: center;
            padding: 0 20px;
        }
        .info {
            width: 494px;
            &-title {
                font-size: 40px;
                font-weight: 500;
                line-height: 45.6px;
                margin-bottom: 15px;
            }
            &-desc {
                font-size: 22px;
                line-height: 28px;
                font-weight: 400;
                margin-top: 20px;
            }
        }
        &.zh,
        &.tw {
            .info {
                width: 444px;

                &-title {
                    font-size: 36px;
                    line-height: 50px;
                    margin-bottom: 10px;
                }
                &-desc {
                    font-size: 16px;
                    line-height: 25px;
                }
            }
        }
    }
    @media only screen and (max-width: 768px) {
        width: 100%;
        height: 5.64rem;
        background: url(~@assets/images/product/home/top/mbg.jpg) 0 100% no-repeat;
        background-size: cover;

        .info {
            &-title {
                width: 2.68rem;
                margin: 0 auto;
                font-size: 30px;
                line-height: 1.1;
                font-weight: 700;
                text-align: center;
                padding-top: 0.6rem;
            }
            &-desc {
                font-size: 14px;
                line-height: 20px;
                width: 2.33rem;
                margin: 0 auto;
                text-align: center;
                font-weight: 400;
                margin-top: 10px;
            }
        }
    }
}
</style>
