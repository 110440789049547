<template>
    <div class="product-accessories">
        <top></top>
        <customize></customize>
        <favorite></favorite>
        <cloud :img="require('@assets/images/product/virtualMakeup/cloud/3.jpg')"></cloud>
    </div>
</template>

<script>
import top from './components/top.vue';
import customize from './components/customize.vue';
import favorite from './components/favorite.vue';
import cloud from '../makeup/components/cloud.vue';

export default {
    name: 'productAccessories',
    components: {
        top,
        customize,
        favorite,
        cloud,
    },
    data() {
        return {
            pageDurationID: 'Accessories',
        };
    },
    mounted() {
        this.$track('product_accessories_imp');
    },
};
</script>
