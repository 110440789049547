<template>
    <div class="product-hair">
        <top></top>
        <example></example>
        <customize></customize>
        <cloud :img="require('@assets/images/product/virtualMakeup/cloud/2.jpg')"></cloud>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import top from './components/top.vue';
import example from './components/example.vue';
import customize from './components/customize.vue';
import cloud from '../makeup/components/cloud.vue';

export default {
    name: 'productHair',
    components: {
        top,
        example,
        customize,
        cloud,
    },
    computed: {
        ...mapState({
            currentLang: state => state.route.params.lang || 'zh',
        }),
    },
    data() {
        return {
            pageDurationID: 'HairDye',
        };
    },
    mounted() {
        this.$track('product_hairdye_imp');
    },
};
</script>
