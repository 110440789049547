<template>
    <div class="product-meitukey-top-ani product-meitukey-top">
        <div class="product-meitukey-top-container">
            <div class="banner-info product-meitukey-top-info">
                <h1 class="banner-info-title poppins product-meitukey-top-ani product-meitukey-top-title">{{ $t('meitukey') }}</h1>
                <div class="banner-info-desc product-meitukey-top-ani product-meitukey-top-desc">{{ i18nPage.desc }}</div>
                <div class="banner-info-buttons">
                    <a href="javascript:;" class="ui-button poppins product-meitukey-top-ani product-meitukey-top-button" @click="onContact">{{ $t('contact') }}</a>
                </div>
            </div>
            <div class="product-meitukey-top-imgs">
                <div class="product-meitukey-top-ani product-meitukey-top-key" ref="key" @mouseenter="onMouseenter" @mouseleave="onMouseleave" @mousemove="onMousemove">
                    <img ref="img" :style="imgStyle" class="img" :src="require(`@assets/images/product/meitukey/top/${buildForOverseas ? 'evelabinsight/' : ''}key.png`)" alt="" @load="onLoad" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
let counter = 0;
let updateRate = 10;
let isTimeToUpdate = function() {
    return counter++ % updateRate === 0;
};
export default {
    computed: {
        i18nPage() {
            return this.$t('meitukeyPage.top');
        },
    },
    data() {
        return {
            x: 0,
            y: 0,
            innerW: 0,
            innerH: 0,
            imgStyle: {},
            buildForOverseas: process.env.VUE_APP_BUILD_FOR === 'evelabinsight',
        };
    },
    methods: {
        onContact() {
            this.mxShowContactModal({
                source: 'MeituKey',
            });
        },
        onMouseenter(e) {
            console.log('-------onMouseenter');
            this.update(e);
        },
        onMouseleave() {
            console.log('-------onMouseleave');
            this.imgStyle = {};
        },
        onMousemove(e) {
            if (isTimeToUpdate()) {
                this.update(e);
            }
        },
        update(event) {
            const e = event || window.event;
            this.x = e.clientX - this._x;
            this.y = (e.clientY - this._y) * -1;

            const x = (this.y / this.innerH / 2.5).toFixed(2),
                y = (this.x / this.innerW / 0.5).toFixed(2);
            const style = `rotateX(${-x}deg) rotateY(${-y}deg)`;
            this.imgStyle = {
                webkitTransform: style,
                transform: style,
            };
        },
        onLoad(e) {
            const container = this.$refs.key,
                inner = e ? e.target : this.$refs.img;
            // x、y：相对于inner元素中心点的坐标；_x、_y：inner元素中心点的坐标；
            counter = 0;
            this.x = this.y = 0;
            this._x = container.getBoundingClientRect().left + Math.floor(container.offsetWidth / 2);
            this._y = container.offsetTop + Math.floor(container.offsetHeight / 2);
            this.innerH = inner.offsetHeight;
            this.innerW = inner.offsetWidth;
        },
        resize() {
            console.log('resize');
            this.onLoad();
        },
    },
    mounted() {
        window.addEventListener('resize', this.resize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resize);
    },
};
</script>

<style lang="scss">
.product-meitukey-top {
    @media only screen and (min-width: 769px) {
        height: 690px;
        &-container {
            width: 1108px;
            margin: 0 auto;
            display: flex;
            padding: 0 20px;
            box-sizing: border-box;
        }
        &-imgs {
            padding: 109px 140px 0 0;
            margin-left: auto;
            .img {
                display: block;
                width: 225px;
                transition: transform 0.2s;
            }
        }
        &-key {
            perspective: 25px;
        }
    }
    @media only screen and (max-width: 768px) {
        background: url(~@assets/images/product/meitukey/top/bg-mobile.png) right bottom no-repeat;
        background-size: 3rem;
        &-info {
            display: flex;
            flex-direction: column;
        }
        &-title {
            font-size: 0.3rem;
            font-weight: 700;
            text-align: center;
            padding: 0.63rem 0.3rem 0 0.3rem;
            line-height: 1.1;
        }
        &-desc {
            font-size: 14px;
            text-align: center;
            padding: 0.25rem 0.3rem 0.2rem 0.3rem;
        }
        &-button {
            margin: 0 auto;
            padding: 0 0.2rem;
            height: 0.44rem;
            line-height: 0.44rem;
            background: #000;
            border-radius: 0.22rem;
            color: #fff;
            font-size: 0.14rem;
        }
        &-key {
            padding-top: 0.86rem;
            padding-bottom: 0.76rem;
            .img {
                width: 1.5rem;
                display: block;
                margin: 0 auto;
            }
        }
    }
}
</style>
