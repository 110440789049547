<template>
    <div class="eve-banner" :class="{ 'eve-banner-overseas': buildForOverseas }">
        <div class="eve-banner-container">
            <div class="banner-info eve-banner-wrap">
                <h1 class="poppins banner-info-title eve-banner-title">{{ $t('eve') }}</h1>
                <h2 class="banner-info-desc eve-banner-desc">{{ i18nPage.desc }}</h2>
                <div class="poppins eve-banner-buttons">
                    <a href="javascript:;" class="ui-button eve-banner-button" @click="onContact">{{ $t('contact') }}</a>
                    <a href="javascript:;" class="ui-button2 eve-banner-button" @click="onTechSpecs">{{ $t('techSpecs') }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    data() {
        return {
            buildForOverseas: process.env.VUE_APP_BUILD_FOR === 'evelabinsight',
        };
    },
    computed: {
        ...mapState({
            currentLang: state => state.route.params.lang || 'zh',
        }),
        i18nPage() {
            return this.$t('evePage.banner');
        },
    },
    methods: {
        onContact() {
            this.mxShowContactModal({
                source: 'MeituEveM',
            });
            this.$track('product_skinanalyzer_button_clk', {
                button: 'contactus',
            });
        },
        onTechSpecs() {
            this.$track('product_skinanalyzer_button_clk', {
                button: 'techspecs',
            });
            this.$router.push({
                path: `/${this.currentLang}/qa/tech-specs/eve`,
            });
        },
    },
};
</script>

<style lang="scss">
.eve-banner {
    width: 100%;
    position: relative;

    @media only screen and (min-width: 769px) {
        height: 690px;
        background: url(~@assets/images/product/eve/banner.jpg) center no-repeat;
        background-size: cover;
        &-overseas {
            background: url(~@assets/images/product/eve/evelabinsight/banner.jpg) center no-repeat;
            background-size: cover;
        }
        &-container {
            max-width: 1108px;
            height: 100%;
            margin: 0 auto;
            padding: 0 20px;
        }
        &-wrap {
            margin-right: auto;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        &-buttons {
            display: flex;
            margin-top: 35px;
        }
        &-button {
            &:last-of-type {
                margin-left: 25px;
            }
        }
    }

    @media only screen and (min-width: 1900px) {
        height: 860px;
    }

    @media only screen and (max-width: 768px) {
        width: 100%;
        height: auto;
        padding-bottom: 4.45rem;
        background: #e3f1fe url(~@assets/images/product/eve/banner-mobile.jpg) 0 bottom no-repeat;
        background-size: 100%;
        overflow: hidden;
        &-overseas {
            background: url(~@assets/images/product/eve/evelabinsight/banner-mobile.jpg) center no-repeat;
            background-size: cover;
        }
        &-title {
            font-size: 0.3rem;
            line-height: 1.1;
            font-weight: 700;
            text-align: center;
            padding: 0.65rem 0.4rem 0.25rem 0.4rem;
        }
        &-desc {
            font-size: 0.14rem;
            line-height: 0.2rem;
            padding: 0 0.35rem;
            text-align: center;
        }
        &-buttons {
            display: flex;
            justify-content: center;
            padding-top: 0.2rem;
        }
        &-button {
            height: 40px;
            line-height: 40px;
            padding: 0 20px;
            font-size: 14px;
            font-weight: 500;
            border-radius: 40px;
            color: #fff;
            // background: #000;

            &:last-of-type {
                margin-left: 30px;
                background: transparent;
                border: solid 1px #000;
                color: #000;
            }
        }
    }
}
</style>
