<template>
    <div class="meitukey-problems">
        <div class="block-title poppins meitukey-problems-ani meitukey-problems-title">{{ i18nPage.title }}</div>

        <div class="meitukey-problems-main">
            <div class="meitukey-problems-ani meitukey-problems-tabs" ref="tabs">
                <div class="meitukey-problems-tabs-wrap" ref="tabsWrap">
                    <div class="poppins meitukey-problems-tab" :class="[item.key, { active: current === item.key }]" v-for="item in i18nPage.tabs" :key="item.key" @click="onTabClick(item.key)">
                        {{ item.name }}
                    </div>
                </div>
            </div>
            <div class="meitukey-problems-ani meitukey-problems-imgs">
                <img class="meitukey-problems-img" :class="[item.key, { active: item.key === current }]" :src="require(`@assets/images/product/meitukey/problems/${item.key}_${currentLang}.png`)" alt="" v-for="item in i18nPage.tabs" :key="item.key" />
                <!-- <div class="meitukey-problems-img" :class="[item.key, { active: item.key === current }]" v-for="item in i18nPage.tabs" :key="item.key"></div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        i18nPage() {
            return this.$t('meitukeyPage.problems');
        },
    },
    data() {
        return {
            current: 'daylight',
        };
    },
    methods: {
        onTabClick(key) {
            this.current = key;
            this.$nextTick(() => {
                this.setOffset();
            });
        },
        setOffset() {
            let winW = window.innerWidth;
            let tabsEl = this.$refs.tabs;
            let tabsWrapEl = this.$refs.tabsWrap;
            let current = tabsEl.querySelector(`.${this.current}`);
            let currentRect = current.getBoundingClientRect();
            if (winW > 768) {
                // let offsetTop = current.offsetTop;
                // let tabsRect = tabsEl.getBoundingClientRect();
                // let top = tabsRect.height / 2 - currentRect.height / 2 - offsetTop;
                // tabsWrapEl.style.transform = `translateY(${top}px)`;
            } else {
                tabsWrapEl.scrollTo({
                    left: current.offsetLeft - winW / 2 + currentRect.width / 2, 
                    top: 0,
                    behavior: 'smooth',
                });
            }
        },
    },
    mounted() {
        setTimeout(() => {
            this.setOffset();
        }, 200);

        this.mxAnimation({
            handle: '.meitukey-problems-ani',
            triggerElement: '.meitukey-problems-title',
            triggerHook: 0.5,
        });

        // ios 一台8p 13.0 上面的settimeout不会居中。测试需要点击或者滚动触发👿
        window.addEventListener('scroll', this.setOffset);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.setOffset);
    },
};
</script>

<style lang="scss">
.meitukey-problems {
    background-color: #f9f9f9;

    @media only screen and (min-width: 769px) {
        padding: 80px 0;
        background-image: radial-gradient(circle 400px at right 1050px, rgba(46, 228, 242, 0.3), transparent);

        &-main {
            display: flex;
            align-items: center;
            width: 1080px;
            margin: 40px auto 0 auto;
        }
        &-tabs {
            // height: 141px;
            // overflow: hidden;
            position: relative;

            &-wrap {
                width: 100%;
                transition: all 0.3s;
            }
            // &:before,
            // &:after {
            //     content: '';
            //     position: absolute;
            //     width: 100%;
            //     height: 45px;
            //     left: 0;
            //     z-index: 1;
            //     background: linear-gradient(to bottom, rgba(249, 249, 249, 0.98), rgba(249, 249, 249, 0.5));
            //     pointer-events: none;
            // }
            // &:before {
            //     top: 0;
            // }
            // &:after {
            //     bottom: 0;
            //     transform: scaleY(-1);
            // }
        }
        &-tab {
            color: rgba(0, 0, 0, 0.2);
            padding: 15px 0 15px 18px;
            font-size: 20px;
            font-weight: 300;
            line-height: 1.5;
            position: relative;
            cursor: pointer;
            user-select: none;
            white-space: nowrap;
            font-weight: 500;

            &.active {
                color: #000;

                &:before {
                    width: 2px;
                    height: 24px;
                    background: #000;
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
        &-imgs {
            width: 618px;
            height: 472px;
            margin-left: auto;
            position: relative;
        }
        &-img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: all 0.5s;
            border-radius: 20px;

            &.active {
                opacity: 1;
            }
        }
    }
    @media only screen and (max-width: 768px) {
        background-image: radial-gradient(circle 250px at 4.5rem 6.5rem, rgba(46, 228, 242, 0.3), transparent);
        &-title {
            font-size: 0.28rem;
            font-weight: 600;
            width: 2.88rem;
            margin: 0 auto;
            line-height: 1.1;
            padding-top: 1rem;
            padding-bottom: 0.47rem;
        }
        &-main {
            display: flex;
            flex-direction: column-reverse;
        }
        &-imgs {
            width: 3.06rem;
            height: 2.34rem;
            position: relative;
            margin: 0 auto;
        }
        &-img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-position: 0 0;
            background-repeat: no-repeat;
            background-size: contain;
            opacity: 0;
            transition: all 0.5s;
            border-radius: 0.2rem;

            &.active {
                opacity: 1;
            }

            &.daylight {
                background-image: url(~@assets/images/product/meitukey/problems/daylight.jpg);
            }
            &.uv {
                background-image: url(~@assets/images/product/meitukey/problems/uv.jpg);
            }
            &.pz {
                background-image: url(~@assets/images/product/meitukey/problems/pz.jpg);
            }
        }

        &-tabs {
            height: 0.35rem;
            padding-top: 0.26rem;
            margin-bottom: 1rem;
            overflow: hidden;
            position: relative;

            &-wrap {
                width: 100%;
                height: 0.23rem;
                overflow-x: auto;
                display: flex;
                flex-wrap: nowrap;
                padding-bottom: 0.3rem;
            }

            &:before {
                width: 0.2rem;
                height: 100%;
                content: '';
                background-image: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) 100%);
                position: absolute;
                left: 0;
                top: 0;
                z-index: 2;
            }
            &:after {
                width: 0.2rem;
                height: 100%;
                content: '';
                background-image: linear-gradient(to left, rgba(220, 244, 247, 1), rgba(249, 249, 249, 0) 100%);
                position: absolute;
                right: 0;
                top: 0;
                z-index: 2;
            }
        }
        &-tab {
            font-size: 0.13rem;
            flex-shrink: 0;
            font-weight: 300;
            padding: 0 0.13rem;
            color: rgba(0, 0, 0, 0.5);
            position: relative;
            white-space: nowrap;
            font-size: 0.13rem;

            &.active {
                color: #000;
                font-weight: 600;

                &:after {
                    content: '';
                    width: 60%;
                    height: 2px;
                    background: #000;
                    position: absolute;
                    left: 20%;
                    top: 0.25rem;
                }
            }
        }
    }
}
</style>
