<template>
    <div class="eve-features">
        <div class="eve-features-container">
            <div class="eve-features-info">
                <div class="poppins block-title eve-features-ani eve-features-title">{{ i18nPage.title }}</div>
                <div class="eve-features-ani eve-features-list">
                    <div class="eve-features-scroll" ref="scroll">
                        <div class="eve-features-item" :class="[`eve-features-item-${index}`]" v-for="(item, index) in i18nPage.list" :key="index">
                            <div class="poppins eve-features-item-title" :class="[{ active: index === current }, currentLang]" @click="onTabClick(index)">{{ item.title }}</div>
                            <div class="eve-features-item-desc" :class="{ active: index === current }">{{ item.desc }}</div>
                        </div>
                        <div class="hidden-xs-only line" :style="lineStyle"></div>
                    </div>
                </div>
                <div class="hidden-sm-and-up eve-features-ani eve-features-descs">
                    <template v-for="(item, index) in i18nPage.list">
                        <transition name="fade" :key="index">
                            <div class="eve-features-descs-item" v-if="index === current">{{ item.desc }}</div>
                        </transition>
                    </template>
                </div>
            </div>
            <div class="eve-features-ani eve-features-imgs">
                <div class="eve-features-model"></div>
                <div class="eve-features-ani0" v-if="current === 0">
                    <div class="eve-features-ani0-item"><div class="circle"></div></div>
                    <div class="eve-features-ani0-item"><div class="circle"></div></div>
                </div>
                <canvas v-else :class="[`eve-features-ani${current}`]" ref="canvas"></canvas>
            </div>
        </div>
    </div>
</template>

<script>
import proload from '@utils/preload';
export default {
    computed: {
        i18nPage() {
            return this.$t('evePage.features');
        },
    },
    data() {
        return {
            current: 0,
            imgsMap: {
                1: null,
                2: null,
                3: null,
            },
            timer: null,
            countMap: {
                1: 63,
                2: 100,
                3: 75,
            },
            lineStyle: {},
        };
    },
    methods: {
        onTabClick(index) {
            this.current = index;
            if (this.timer) {
                clearInterval(this.timer);
            }
            var canvas = this.$refs[`canvas`];
            if (canvas) {
                var ctx = canvas.getContext('2d');
                ctx.clearRect(0, 0, canvas.width, canvas.height);
            }
            if (index !== 0) {
                if (this.imgsMap[index]) {
                    this.playAnimation();
                } else {
                    var urls = [];

                    for (let i = 0; i < this.countMap[index]; i++) {
                        urls.push(require(`@assets/images/product/eve/features/${index + 1}/合成 1_000${i < 10 ? '0' + i : i}.png`));
                    }
                    proload(urls).done(res => {
                        this.imgsMap[index] = res;
                        this.playAnimation();
                    });
                }
            }

            this.$nextTick(() => {
                var scrollEl = this.$refs.scroll;
                if (!scrollEl) return;
                this.mxScrollToCenter({
                    scrollEl,
                    el: scrollEl.querySelector(`.eve-features-item-${this.current}`),
                });
                this.setTabLine();
            });
        },
        async playAnimation() {
            await this.$nextTick();
            var { current, imgsMap } = this;
            var i = 0;
            var canvas = this.$refs[`canvas`];
            var ctx = canvas.getContext('2d');
            canvas.width = imgsMap[current][0].width;
            canvas.height = imgsMap[current][0].height;
            this.timer = setInterval(() => {
                if (i === imgsMap[current].length - 1) {
                    i = 0;
                }
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(imgsMap[current][i], 0, 0, canvas.width, canvas.height);
                i++;
            }, 30);
        },
        setOffset() {
            let winW = window.innerWidth;
            let tabsWrapEl = this.$refs.scroll;
            let current = tabsWrapEl.querySelector(`.eve-features-item-${this.current}`);

            if (winW <= 768) {
                tabsWrapEl.scrollTo({
                    left: current.offsetLeft,
                    top: 0,
                    behavior: 'smooth',
                });
            } else {
                this.setTabLine();
            }
        },
        async setTabLine() {
            await this.$nextTick();
            // var width = window.innerWidth;
            var el = this.$el.querySelector(`.eve-features-item-${this.current}`);

            if (!el) return;
            this.lineStyle = {
                height: el.offsetHeight + 'px',
                top: el.offsetTop + 'px',
            };
        },
    },
    mounted() {
        this.setTabLine();
        this.mxAnimation({
            handle: '.eve-features-ani',
            triggerElement: '.eve-features-ani',
        });
    },
};
</script>

<style lang="scss">
.eve-features {
    &-ani0 {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        &-item {
            width: 10px;
            height: 10px;
            border-radius: 10px;
            background: #fff;
            position: absolute;
            left: 250px;
            top: 246px;
            animation: point0 infinite 1.2s;

            @keyframes point0 {
                to {
                    opacity: 0;
                    transform: scale(1.3);
                }
            }

            .circle {
                width: 28px;
                height: 28px;
                border-radius: 28px;
                border: 1px solid rgba(255, 255, 255, .5);
                box-sizing: border-box;
                position: absolute;
                left: -9px;
                top: -9px;
                animation: circle0 infinite 1.2s;

                @keyframes circle0 {
                    to {
                        transform: scale(1.2);
                    }
                }
            }

            &:last-of-type {
                left: 378px;
                top: 300px;
            }
        }
    }
    &-ani1 {
        width: 152px;
        height: 234px;
        position: absolute;
        left: 138px;
        top: 208px;
        z-index: 3;
    }
    &-ani2 {
        width: 470px;
        height: 639px;
        position: absolute;
        left: -2px;
        top: -8px;
        transform: scale(0.85);
        z-index: 3;
    }
    &-ani3 {
        width: 200px;
        height: 230px;
        position: absolute;
        z-index: 1;
        left: -25px;
        bottom: 82px;
    }
    @media only screen and (min-width: 769px) {
        background-image: url(~@assets/images/product/eve/features/bg.svg), radial-gradient(circle 300px at right top, rgba(46, 228, 242, 0.3), transparent);
        background-repeat: no-repeat, no-repeat;
        background-size: 729px auto, auto;
        background-position: 0 bottom, 0 0;
        &-container {
            max-width: 1108px;
            margin: 0 auto;
            display: flex;
            flex-direction: row-reverse;
            padding: 80px 20px 0 20px;
        }
        &-title {
            width: auto;
            text-align: left;
        }
        &-info {
            margin-left: auto;
            margin-right: 60px;
            padding-top: 138px;
        }
        &-imgs {
            position: relative;
        }
        &-model {
            width: 434px;
            height: 589px;
            background: url(~@assets/images/product/eve/features/model.png) 0 0 no-repeat;
            background-size: contain;
            position: relative;
            z-index: 2;
        }
        &-scroll {
            border-left: solid 2px #efefef;
            padding-left: 16px;

            .line {
                width: 4px;
                position: absolute;
                left: 0px;
                background: #00c8c8;
                transition: all 0.4s;
            }
        }
        &-title {
            margin-bottom: 40px;
        }
        &-item {
            margin-bottom: 10px;
            &-title {
                font-size: 22px;
                line-height: 32px;
                font-weight: 500;
                cursor: pointer;
                position: relative;
                color: #d9d9d9;

                // &.active {
                //     background-image: -webkit-linear-gradient(left, #3EDCE6, #4DBFFF);
                //     -webkit-background-clip: text;
                //     -webkit-text-fill-color: transparent;
                // }

                // &:before {
                //     content: '';
                //     width: 0;
                //     height: 0;
                //     border-style: solid;
                //     border-width: 6px 0 6px 9px;
                //     border-color: transparent transparent transparent #000;
                //     position: absolute;
                //     left: -24px;
                //     top: 15px;
                //     transition: transform 0.4s;
                // }
                &.active {
                    font-size: 24px;
                    line-height: 40px;
                    color: #000;
                    // &:before {
                    //     border-color: transparent transparent transparent #28c7ee;
                    //     transform: rotate(90deg);
                    // }
                }
            }
            &-desc {
                color: #333;
                width: 474px;
                font-size: 14px;
                line-height: 20px;
                opacity: 0;
                max-height: 0;
                overflow: hidden;
                

                &.active {
                    // transition: all 0.3s;
                    max-height: 100px;
                    opacity: 1;
                    margin-top: 5px;
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        background-image: url(~@assets/images/product/eve/features/bg.svg), radial-gradient(circle 2rem at 4.5rem top, rgba(46, 228, 242, 0.3), transparent);
        background-repeat: no-repeat, no-repeat;
        background-size: 3.2rem auto, auto;
        background-position: 0 bottom, 0 0;

        &-ani0 {
            &-item {
                width: 0.05rem;
                height: 0.05rem;
                border-radius: 0.05rem;
                left: 2.08rem;
                top: 1.3rem;

                .circle {
                    width: 0.15rem;
                    height: 0.15rem;
                    border-radius: 0.15rem;
                    left: -0.05rem;
                    top: -0.05rem;
                }

                &:last-of-type {
                    left: 2.75rem;
                    top: 1.58rem;
                }
            }
        }
        &-ani1 {
            width: 0.76rem;
            height: 1.27rem;
            left: 1.51rem;
            top: 1.1rem;
        }
        &-ani2 {
            width: 2.35rem;
            height: 3.2rem;
            left: 0.7rem;
            top: -0.05rem;
        }
        &-ani3 {
            width: 1rem;
            height: 1.15rem;
            position: absolute;
            z-index: 1;
            left: 0.6rem;
            bottom: 0.4rem;
        }

        &-title {
            width: 2.6rem;
            box-sizing: border-box;
            line-height: 1.1;
            font-size: 0.28rem;
            font-weight: 600;
            padding: 0.8rem 0.35rem 0.25rem 0.35rem;
        }

        &-list {
            height: 0.34rem;
            overflow: hidden;
            margin-bottom: 0.13rem;
            position: relative;
            &:before {
                width: 0.15rem;
                height: 100%;
                content: '';
                background-image: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.6) 70%, rgba(255, 255, 255, 0) 100%);
                position: absolute;
                left: 0;
                top: 0;
                z-index: 2;
            }
            &:after {
                width: 0.15rem;
                height: 100%;
                content: '';
                background-image: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.6) 70%, rgba(255, 255, 255, 0) 100%);
                position: absolute;
                right: 0;
                top: 0;
                z-index: 2;
            }
        }
        &-scroll {
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
        }
        &-item {
            padding: 0 0.1rem;
            &:first-of-type {
                padding-left: 0.35rem;
            }
            &:last-of-type {
                padding-right: 0.35rem;
            }
            &-title {
                font-size: 0.17rem;
                font-weight: 300;
                white-space: nowrap;
                line-height: 0.23rem;
                padding-bottom: 0.2rem;
                position: relative;

                &.active {
                    font-weight: 600;
                    background-image: -webkit-linear-gradient(left, #3EDCE6, #4DBFFF);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;

                    &:after {
                        width: 100%;
                        height: 0.02rem;
                        background: linear-gradient(#3EDCE6, #4DBFFF);
                        position: absolute;
                        content: '';
                        position: absolute;
                        top: 0.32rem;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
            }
            &-desc {
                display: none;
            }
        }
        &-descs {
            height: 1.6rem;
            position: relative;
            margin: 0 0.35rem;
            &-item {
                width: 2rem;
                position: absolute;
                left: 0;
                top: 0;
                font-size: 0.13rem;
                line-height: 0.18rem;
            }
        }

        &-model {
            width: 2.32rem;
            height: 3.15rem;
            background: url(~@assets/images/product/eve/features/model-mobile.png) 0 0 no-repeat;
            background-size: contain;
            margin: 0.1rem auto 0 auto;
            position: relative;
            z-index: 2;
        }
    }
}
</style>
