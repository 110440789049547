<template>
    <div class="virtual-makeup-textures">
        <div class="virtual-makeup-textures-container">
            <div class="virtual-info virtual-makeup-textures-info">
                <div class="virtual-info-title poppins virtual-makeup-textures-ani virtual-makeup-textures-info-title">{{ i18nPage.title }}</div>
                <div class="virtual-info-desc virtual-makeup-textures-ani virtual-makeup-textures-info-desc">{{ i18nPage.desc }}</div>
                <div class="virtual-info-buttons">
                    <a href="javascript:;" class="ui-button poppins virtual-makeup-textures-ani virtual-makeup-textures-info-button" @click="onContact">{{ $t('contact') }}</a>
                </div>
            </div>
            <div class="virtual-makeup-textures-imgs">
                <img class="virtual-makeup-textures-ani virtual-makeup-textures-img" src="@assets/images/product/virtualMakeup/textures/bg.png" alt="" />
                <div class="virtual-makeup-textures-tabs">
                    <img v-for="index in 5" :key="index" :src="require(`@assets/images/product/virtualMakeup/textures/${index}.jpg`)" alt="" class="virtual-makeup-textures-ani virtual-makeup-textures-tab" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        i18nPage() {
            return this.$t('virtualMakeupPage.textures');
        },
    },
    methods: {
        onContact() {
            this.mxShowContactModal({
                source: 'Makeup',
            });
        },
    },
    mounted() {
        this.mxAnimation({
            handle: '.virtual-makeup-textures-ani',
            triggerElement: '.virtual-makeup-textures',
            interval: 0.15,
        });
    },
};
</script>

<style lang="scss">
.virtual-makeup-textures {
    @media only screen and (min-width: 769px) {
        &-container {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            width: 1070px;
            margin: 0 auto;
            padding: 80px 0;
        }
        &-imgs {
            width: 446px;
            height: 590px;
            position: relative;
            flex-shrink: 0;
            &:after {
                content: '';
                position: absolute;
                width: 348px;
                height: 99px;
                background: rgba(0, 0, 0, 0.2);
                background: url(~@assets/images/product/shadow.png) 0 0 no-repeat;
                background-size: contain;
                left: 50%;
                bottom: -100px;
                transform: translateX(-50%);
            }
        }
        &-img {
            display: block;
            height: 100%;
            margin: 0 auto;
        }
        &-tabs {
            position: absolute;
            left: 0;
            top: 410px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            width: 446px;
        }
        &-tab {
            width: 81px;
            height: 81px;
        }
        &-info {
            margin-left: 130px;
            
        }
    }
    @media only screen and (max-width: 768px) {
        &-container {
            display: flex;
            flex-direction: column;
            padding-bottom: 0.4rem;
        }
        &-info {
            width: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            padding: 0.8rem 0.35rem 0.5rem 0.35rem;
            &-title {
                font-size: 0.28rem;
                font-weight: 600;
                line-height: 1.1;
            }
            &-desc {
                font-size: 0.13rem;
                width: 2.8rem;
                margin-top: 0.2rem;
                margin-bottom: 0.5rem;
            }
            &-button {
                margin-right: auto;
            }
        }
        &-imgs {
            height: 4.42rem;
            margin: 0 0.45rem;
            box-sizing: border-box;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                width: 2.62rem;
                height: 0.75rem;
                background: url(~@assets/images/product/mshadow.png) 0 0 no-repeat;
                background-size: contain;
                left: 50%;
                transform: translateX(-50%);
                bottom: -0.72rem;
            }
        }
        &-img {
            display: block;
            height: 100%;
            margin: 0 auto;
        }
        &-tabs {
            width: 100%;
            display: flex;
            justify-content: space-between;
            position: absolute;
            top: 3.26rem;
            left: 0;
        }
        &-tab {
            width: 0.52rem;
            height: 0.52rem;
        }
    }
}
</style>
