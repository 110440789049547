
/**
 * 预加载图片
 * @param {string | Array<String>} imageUrls 要加载的图片地址
 * @return 
 */
 export default function preload(imageUrls) {
    let images = [],
        count = 0,
        doneAction = function() {},
        progressAction = function() {};
    let loadedImages = [];
    function imageLoad(status) {
        return function() {
            images[count].status = status;
            loadedImages.push(images[count]);
            count++;
            progressAction(
                (count * 100) / images.length,
                loadedImages
            );
            if (count === imageUrls.length) {
                doneAction(loadedImages);
            }
        };
    }
    imageUrls = Array.isArray(imageUrls) ? imageUrls : [imageUrls];

    imageUrls.forEach((image, i) => {
        images[i] = new Image();
        images[i].src = image;
        images[i].onload = imageLoad("success");
        images[i].onerror = imageLoad("fail");
        images[count].status = "waiting";
        return image;
    });

    if (imageUrls.length === 0) {
        setTimeout(() => {
            progressAction(100, []);
            doneAction([]);
        }, 100);
    }

    return {
        /** 
         * @method done
         * @param { doneCb } doneCb 完成回调
        */
        done(doneCbFn) {
            doneAction =
                typeof doneCbFn === "function"
                    ? doneCbFn
                    : doneAction;
            return this
        },
        /** 
         * @method progress
         * @param { progressCbFn } progressCbFn 进度回调
        */
        progress(progressCbFn) {
            progressAction =
                typeof progressCbFn === "function"
                    ? progressCbFn
                    : progressAction;
            return this;
        }
    };
};


/** 
 * @callback doneCb
 * @param { Array<string>} loadedImages 加载的图片组
 * 
*/

/** 
 * @callback progressCbFn
 * @param { number } loadedPercent 已经加载的进度
 * @param { Array<string>} loadedImages 已经加载的图片
 * 
*/

