<template>
    <div class="virtual-hair-top">
        <div class="virtual-hair-top-container">
            <div class="banner-info virtual-hair-top-info">
                <div class="banner-info-title poppins virtual-hair-top-ani virtual-hair-top-info-title">{{ i18nPage.title }}</div>
                <div class="banner-info-desc virtual-hair-top-ani virtual-hair-top-info-desc">{{ i18nPage.desc }}</div>
                <div class="banner-info-list virtual-hair-top-info-list">
                    <div class="virtual-hair-top-ani virtual-hair-top-info-item" v-for="(item, index) in list" :key="index">
                        <img class="icon" :src="item.icon" alt="" />
                        <div class="text">{{ item.text }}</div>
                    </div>
                </div>
                <div class="banner-info-buttons">
                    <a href="javascript:;" class="ui-button virtual-hair-top-ani virtual-hair-top-info-button" @click="onContact">{{ $t('contact') }}</a>
                </div>
            </div>

            <div class="virtual-hair-top-img">
                <div class="virtual-hair-top-ani bg"></div>
                <img class="virtual-hair-top-ani img" src="@assets/images/product/virtualHair/top/a.png" alt="" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        i18nPage() {
            return this.$t('virtualHairPage.top');
        },
        list() {
            return [
                {
                    key: 'inStore',
                    text: this.$t('inStore'),
                    icon: require('@assets/images/product/virtualMakeup/top/1.svg'),
                },
                {
                    key: 'appService',
                    text: this.$t('appService'),
                    icon: require('@assets/images/product/virtualMakeup/top/2.svg'),
                },
                {
                    key: 'webService',
                    text: this.$t('webService'),
                    icon: require('@assets/images/product/virtualMakeup/top/3.svg'),
                },
                {
                    key: 'mini',
                    text: this.$t('mini'),
                    icon: require('@assets/images/product/virtualMakeup/top/4.svg'),
                },
            ];
        },
    },
    methods: {
        onContact() {
            this.mxShowContactModal({
                source: 'HairDye',
            });
        },
    },
};
</script>

<style lang="scss">
.virtual-hair-top {
    @media only screen and (min-width: 769px) {
        width: 100%;
        height: 690px;
        overflow: hidden;
        &-container {
            height: 100%;
            display: flex;
            width: 1108px;
            margin: 0 auto;
            padding: 0 20px;
            box-sizing: border-box;
        }
        &-info {
            &-list {
                margin-top: 30px;
                width: 360px;
                display: flex;
                justify-content: space-between;
                transform: translateX(-20px);
                margin-bottom: 10px;
            }
            &-item {
                width: 80px;
                text-align: center;
                font-size: 11px;
                display: flex;
                flex-direction: column;

                .icon {
                    height: 28px;
                    margin-bottom: 10px;
                }
            }
        }
        &-img {
            position: relative;
            .img {
                width: 580px;
                margin-top: 170px;
                margin-left: 40px;
                position: relative;
                z-index: 2;
            }
            .bg {
                width: 503px;
                height: 664px;
                background: url(~@assets/images/product/virtualHair/top/bg.png) 0 0 no-repeat;
                background-size: contain;
                position: absolute;
                top: 28px;
                left: 290px;
            }
        }
    }
    @media only screen and (max-width: 768px) {
        &-container {
            width: 100%;
        }
        &-info {
            width: 100%;
            display: flex;
            flex-direction: column;
            &-title {
                font-size: 0.3rem;
                font-weight: 700;
                line-height: 1.1;
                padding: 0.65rem 0.68rem 0 0.68rem;
                text-align: center;
            }
            &-desc {
                font-size: 0.14rem;
                text-align: center;
                padding: 0.25rem 0.3rem 0.32rem 0.3rem;
            }
            &-list {
                display: flex;
                flex-flow: wrap;
                padding: 0 0.87rem;
            }
            &-item {
                box-sizing: border-box;
                width: 1rem;
                padding: 0 0.1rem;
                display: flex;
                flex-direction: column;
                text-align: center;
                font-size: 0.11rem;
                margin-bottom: 0.2rem;

                .icon {
                    height: 0.28rem;
                    margin-bottom: 0.1rem;
                }
                .text {
                    min-height: 0.3rem;
                }
            }
            &-button {
                margin: 0.2rem auto;
            }
        }
        &-img {
            padding-top: 0.6rem;
            padding-bottom: 0.8rem;
            .img {
                display: block;
                width: 3.32rem;
                margin-left: auto;
            }
        }
    }
}
</style>
