<template>
    <div class="skin-mirror-detail">
        <h2 class="block-title poppins skin-mirror-detail-ani skin-mirror-detail-title">{{ i18nPage.title }}</h2>
        <div class="block-desc skin-mirror-detail-ani skin-mirror-detail-desc">{{ i18nPage.desc }}</div>

        <div class="skin-mirror-detail-container">
            <div class="skin-mirror-detail-ani skin-mirror-detail-tabs" ref="tabs">
                <div class="skin-mirror-detail-tabs-wrap" ref="tabsWrap">
                    <div class="skin-mirror-detail-tab" :class="[item, { active: current === item, 'next-1': index === currentIndex - 1 || index === currentIndex + 1, 'next-2': index === currentIndex - 2 || index === currentIndex + 2, 'next-3': index === currentIndex - 3 || index === currentIndex + 3 }]" v-for="(item, index) in tabs" :key="item" @click="onTabClick(item)">
                        {{ i18nPage[item] }}
                    </div>
                </div>
            </div>
            <div class="skin-mirror-detail-ani skin-mirror-detail-model">
                <img class="mask" :class="[item, { active: current === item }]" :src="require(`@assets/images/product/skinMirror/skinDetail/${item}.png`)" alt="" v-for="item in tabs" :key="item" />
            </div>
            <div class="skin-mirror-detail-ani skin-mirror-detail-area">
                <div class="skin-mirror-detail-area-item">{{ i18nPage.dry }}</div>
                <div class="skin-mirror-detail-area-item">{{ i18nPage.oil }}</div>
            </div>
            <div class="skin-mirror-detail-ani skin-mirror-detail-overview">
                <div class="skin-mirror-detail-overview-title">{{ i18nPage.overviewTitle }}</div>
                <div class="poppins skin-mirror-detail-overview-score">83</div>
                <div class="skin-mirror-detail-overview-type">{{ i18nPage.overviewType }}</div>
                <div class="skin-mirror-detail-overview-notice">{{ i18nPage.overviewNotice }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        i18nPage() {
            return this.$t('skinMirrorPage.skinDetail');
        },
        currentIndex() {
            let index = 0;
            this.tabs.forEach((item, i) => {
                if (item === this.current) {
                    index = i;
                }
            });
            return index;
        },
    },
    data() {
        return {
            current: 'acne',
            tabs: ['pores', 'blackheads', 'darkCircles', 'acneScars', 'acne', 'chloasma', 'freckles', 'eyeWrinkles', 'tear', 'foreheadWrinkles'],
        };
    },
    methods: {
        onTabClick(key) {
            this.current = key;
            this.$nextTick(() => {
                this.setOffset();
            });
            this.$track('product_skinmirror_switch_clk', {
                part: key,
            });
        },
        setOffset() {
            let winW = window.innerWidth;
            let tabsEl = this.$refs.tabs;
            let tabsWrapEl = this.$refs.tabsWrap;
            let current = tabsEl.querySelector(`.${this.current}`);
            let currentRect = current.getBoundingClientRect();
            if (winW > 768) {
                let offsetTop = current.offsetTop;
                let tabsRect = tabsEl.getBoundingClientRect();
                let top = tabsRect.height / 2 - currentRect.height / 2 - offsetTop;
                tabsWrapEl.style.transform = `translateY(${top}px)`;
            } else {
                tabsWrapEl.scrollTo({
                    left: current.offsetLeft - winW / 2 + currentRect.width / 2, 
                    top: 0,
                    behavior: 'smooth',
                });
            }
        },
    },
    mounted() {
        setTimeout(() => {
            this.setOffset();
        }, 200);

        this.mxAnimation({
            handle: '.skin-mirror-detail-ani',
            triggerElement: '.skin-mirror-detail',
            triggerHook: 0.5,
        });
        // ios 一台8p 13.0 上面的settimeout不会居中。测试需要点击或者滚动触发👿
        window.addEventListener('scroll', this.setOffset);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.setOffset);
    },
};
</script>

<style lang="scss">
.skin-mirror-detail {
    background-color: #f9f9f9;
    @media only screen and (min-width: 769px) {
        background: #f9f9f9 url(~@assets/images/product/skinMirror/skinDetail/bg.jpg) center no-repeat;
        background-size: auto 100%;
        padding-top: 80px;
        &-container {
            width: 1108px;
            height: 756px;
            margin: 0 auto;
            position: relative;
        }
        &-model {
            background: url(~@assets/images/product/skinMirror/skinDetail/model.png) 0 0 no-repeat;
            background-size: contain;
            width: 802px;
            height: 755px;
            position: relative;
            z-index: 2;

            .mask {
                position: absolute;
                opacity: 0;
                transition: all 0.4s;

                &.active {
                    opacity: 1;
                }
                &.pores {
                    width: 286px;
                    left: 300px;
                    top: 71px;
                }
                &.blackheads {
                    width: 95px;
                    left: 393px;
                    top: 230px;
                }
                &.darkCircles {
                    width: 280px;
                    left: 300px;
                    top: 230px;
                }
                &.acneScars {
                    top: 265px;
                    left: 512px;
                    width: 58px;
                }
                &.acne {
                    top: 380px;
                    left: 349px;
                    width: 58px;
                }
                &.chloasma {
                    width: 284px;
                    left: 297px;
                    top: 282px;
                }
                &.freckles {
                    width: 166px;
                    left: 357px;
                    top: 231px;
                }
                &.eyeWrinkles {
                    width: 293px;
                    left: 303px;
                    top: 208px;
                }
                &.tear {
                    width: 240px;
                    left: 317px;
                    top: 214px;
                }
                &.foreheadWrinkles {
                    width: 231px;
                    left: 321px;
                    top: 76px;
                }
            }
        }
        &-area {
            width: 154px;
            height: 92px;
            border-radius: 20px;
            background: #fff;
            box-shadow: 0 4px 26px rgba(0, 0, 0, 0.1);
            position: absolute;
            left: 140px;
            top: 340px;
            z-index: 1;
            padding-left: 30px;
            box-sizing: border-box;

            &-item {
                &:first-of-type {
                    font-size: 22px;
                    font-weight: 600;
                    margin-top: 12px;
                    position: relative;
                    &:before {
                        content: '';
                        width: 6px;
                        height: 6px;
                        border-radius: 3px;
                        background: #3DD3D6;
                        position: absolute;
                        left: -15px;
                        top: 12px;
                    }
                }
                &:last-of-type {
                    font-size: 17px;
                    color: rgba(0, 0, 0, 0.15);
                    margin-top: 8px;
                }
            }
        }
        &-overview {
            width: 170px;
            height: 189px;
            background: #fff;
            border-radius: 20px;
            box-shadow: 0 3px 18px rgba(0, 0, 0, 0.1);
            position: absolute;
            left: 578px;
            top: 430px;
            z-index: 1;
            text-align: center;
            padding: 16px;
            box-sizing: border-box;

            &-title {
                font-size: 10px;
                text-align: center;
                font-weight: 600;
            }
            &-score {
                font-size: 60px;
                line-height: 1;
                font-weight: 500;
                text-align: center;
                margin-top: 8px;
            }
            &-type {
                font-size: 16px;
                font-weight: 700;
                margin-top: 8px;
                white-space: nowrap;
            }
            &-notice {
                width: 270px;
                font-size: 12px;
                transform: scale(0.5);
                transform-origin: 0 0;
                margin-top: 8px;
            }
        }
        &-tabs {
            height: 370px;
            position: absolute;
            top: 190px;
            right: 80px;
            overflow: hidden;

            &-wrap {
                width: 100%;
                transition: all 0.3s;
            }
        }
        &-tab {
            padding: 15px 0 15px 18px;
            font-size: 20px;
            font-weight: 300;
            line-height: 28px;
            position: relative;
            cursor: pointer;
            user-select: none;
            white-space: nowrap;

            &.next-1 {
                opacity: 1;
            }
            &.next-2 {
                opacity: 0.45;
            }
            &.next-3 {
                opacity: 0.15;
            }

            &.active {
                font-weight: 600;

                &:before {
                    width: 2px;
                    height: 24px;
                    background: #000;
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 17px;
                }
            }
        }
    }
    @media only screen and (max-width: 768px) {
        overflow: hidden;
        &-title {
            font-size: 28px;
            font-weight: 600;
            line-height: 1.1;
            width: 2.8rem;
            margin-left: 0.35rem;
            padding-top: 0.8rem;
        }
        &-desc {
            font-size: 14px;
            width: 2.43rem;
            margin-left: 0.35rem;
            margin-top: 0.2rem;
            margin-bottom: 0.5rem;
        }
        &-container {
            position: relative;
            background: url(~@assets/images/product/skinMirror/skinDetail/bg-mobile.jpg) 0 0 no-repeat;
            background-size: contain;
        }
        &-model {
            background: url(~@assets/images/product/skinMirror/skinDetail/model-mobile.png) 0 0 no-repeat;
            background-size: contain;
            width: 3.75rem;
            height: 3.4rem;
            position: relative;
            z-index: 2;

            .mask {
                position: absolute;
                opacity: 0;
                transition: all 0.4s;

                &.active {
                    opacity: 1;
                }

                &.pores {
                    width: 1.34rem;
                    left: 1.21rem;
                    top: 0.35rem;
                }
                &.blackheads {
                    width: 0.4rem;
                    left: 1.65rem;
                    top: 1.12rem;
                }
                &.darkCircles {
                    width: 1.3rem;
                    left: 1.25rem;
                    top: 1.08rem;
                }
                &.acneScars {
                    width: 0.3rem;
                    left: 2.2rem;
                    top: 1.25rem;
                }
                &.acne {
                    width: 0.3rem;
                    left: 1.43rem;
                    top: 1.78rem;
                }
                &.chloasma {
                    width: 1.35rem;
                    left: 1.2rem;
                    top: 1.33rem;
                }
                &.freckles {
                    width: 0.9rem;
                    left: 1.4rem;
                    top: 1.08rem;
                }
                &.eyeWrinkles {
                    width: 1.35rem;
                    left: 1.22rem;
                    top: 1.02rem;
                }
                &.tear {
                    width: 1.1rem;
                    left: 1.32rem;
                    top: 1.03rem;
                }
                &.foreheadWrinkles {
                    width: 1.1rem;
                    left: 1.33rem;
                    top: 0.36rem;
                }
            }
        }
        &-area {
            width: 0.74rem;
            height: 0.44rem;
            background: #fff;
            border-radius: 0.05rem;
            box-shadow: 0 0.02rem 0.14rem rgba(0, 0, 0, 0.1);
            position: absolute;
            z-index: 1;
            left: 0.44rem;
            top: 2.2rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 0.2rem;

            &-item {
                &:first-of-type {
                    font-size: 0.1rem;
                    font-weight: 600;
                    margin-bottom: 0.02rem;
                    position: relative;
                    &:before {
                        content: '';
                        width: 4px;
                        height: 4px;
                        border-radius: 2px;
                        background: #3DD3D6;
                        position: absolute;
                        left: -0.1rem;
                        top: 0.06rem;
                    }
                }
                &:last-of-type {
                    font-size: 0.08rem;
                    opacity: 0.15;
                }
            }
        }
        &-overview {
            width: 1.1rem;
            height: 1.2rem;
            background: #fff;
            border-radius: 0.05rem;
            box-shadow: 0 0.02rem 0.14rem rgba(0, 0, 0, 0.1);
            position: absolute;
            z-index: 1;
            left: 2.54rem;
            top: 2.2rem;
            text-align: center;
            padding: 0.06rem;
            box-sizing: border-box;
            &-title {
                display: none;
            }
            &-score {
                font-size: 0.35rem;
                font-weight: 500;
                line-height: 1.1;
            }
            &-type {
                font-size: 0.1rem;
                font-weight: 700;
                margin-top: 0.02rem;
            }
            &-notice {
                width: 1.85rem;
                font-size: 0.1rem;
                transform: scale(0.5);
                transform-origin: 0 0;
                margin-top: 0.1rem;
            }
        }
        &-tabs {
            height: 0.27rem;
            margin-bottom: 0.23rem;
            overflow: hidden;
            position: relative;

            &:before {
                width: 0.15rem;
                height: 100%;
                content: '';
                background-image: linear-gradient(to right, rgba(249, 249, 249, 1), rgba(249, 249, 249, 0) 100%);
                position: absolute;
                left: 0;
                top: 0;
                z-index: 2;
            }
            &:after {
                width: 0.15rem;
                height: 100%;
                content: '';
                background-image: linear-gradient(to left, rgba(249, 249, 249, 1), rgba(249, 249, 249, 0) 100%);
                position: absolute;
                right: 0;
                top: 0;
                z-index: 2;
            }
            &-wrap {
                display: flex;
                width: 100%;
                height: 0.18rem;
                overflow-x: auto;
                flex-wrap: nowrap;
                padding-bottom: 0.2rem;
            }
        }
        &-tab {
            font-size: 0.13rem;
            line-height: 0.18rem;
            flex-shrink: 0;
            padding: 0 0.1rem;
            color: rgba(0, 0, 0, 0.6);
            position: relative;
            cursor: pointer;
            font-weight: 300;
            white-space: nowrap;

            &.active {
                color: #000;
                font-weight: 600;

                &:after {
                    content: '';
                    width: 70%;
                    height: 2px;
                    background: #000;
                    position: absolute;
                    left: 15%;
                    top: 0.25rem;
                }
            }
        }
    }
}
</style>
