<template>
    <div class="skin-mirror-customize">
        <div class="skin-mirror-customize-container">
            <div class="banner-info skin-mirror-customize-info">
                <h2 class="banner-info-title poppins skin-mirror-customize-ani skin-mirror-customize-title" v-html="i18nPage.title"></h2>
                <div class="banner-info-desc skin-mirror-customize-ani skin-mirror-customize-desc">{{ i18nPage.desc }}</div>
            </div>
            <div class="skin-mirror-customize-ani skin-mirror-customize-card">
                <swiper class="skin-mirror-customize-swiper" :options="swiperOption" ref="swiper">
                    <swiper-slide v-for="index in 3" :key="index">
                        <img :src="imgSrc(index)" alt="" />
                    </swiper-slide>
                </swiper>
                <div class="swiper-pagination" slot="pagination"></div>
            </div>
        </div>
    </div>
</template>

<script>
import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
export default {
    components: {
        swiper,
        swiperSlide,
    },
    computed: {
        i18nPage() {
            return this.$t('skinMirrorPage.customize');
        },
    },
    data() {
        return {
            swiperOption: {
                loop: true,
                spaceBetween: 0,
                speed: 300,
                autoplay: {
                    delay: 2800,
                    disableOnInteraction: false,
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
            },
        };
    },
    methods: {
        resize() {
            let swiper = this.$refs.swiper;
            swiper.swiper.update();
        },
        imgSrc(index) {
            let { currentLang } = this;
            if (currentLang === 'ko') {
                currentLang = 'en';
            }
            return require(`@assets/images/product/skinMirror/customize/${currentLang}${index}.png`);
        },
    },
    mounted() {
        this.mxAnimation({
            handle: '.skin-mirror-customize-ani',
            triggerElement: '.skin-mirror-customize-ani',
        });
        window.addEventListener('resize', this.resize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resize);
    },
};
</script>

<style lang="scss">
.skin-mirror-customize {
    .swiper-pagination-bullets {
        left: 50%;
        transform: translate(-50%, -10px);
    }
    .swiper-pagination-bullet {
        margin: 0 5px;
        width: 8px;
        height: 8px;
        box-sizing: border-box;
        border: solid 1px #c4c4c4;
        background: transparent;
        opacity: 1;
    }
    .swiper-pagination-bullet-active {
        background: #c4c4c4;
    }
    @media only screen and (min-width: 769px) {
        height: 650px;
        &-container {
            width: 960px;
            height: 100%;
            margin: 0 auto;
            display: flex;
            align-items: center;
        }
        &-info {
            margin-right: auto;
        }
        &-title {
            width: 516px;
        }
        &-desc {
            width: 445px;
        }
        &-card {
            position: relative;
        }
        &-swiper {
            width: 356px;
            height: 463px;
            img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        &-container {
            padding-top: 0.8rem;
        }
        &-title {
            font-size: 0.28rem;
            font-weight: 600;
            width: 2.65rem;
            margin-left: 0.35rem;
            line-height: 1.1;
        }
        &-desc {
            font-size: 0.13rem;
            line-height: 0.18rem;
            width: 2.68rem;
            margin-left: 0.35rem;
            margin-top: 0.2rem;
            margin-bottom: 0.5rem;
        }
        &-card {
            position: relative;
            padding-bottom: 0.8rem;
        }
        &-swiper {
            width: 2.9rem;
            height: 3.77162rem;
            img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        .swiper-pagination-bullet {
            width: 0.06rem;
            height: 0.06rem;
            margin: 0 0.04rem;
        }
    }
}
</style>
