<template>
    <div class="virtual-makeup-lip">
        <div class="virtual-makeup-lip-container">
            <div class="virtual-info virtual-makeup-lip-info">
                <div class="virtual-info-title poppins virtual-makeup-lip-ani virtual-makeup-lip-info-title">{{ i18nPage.title }}</div>
                <div class="virtual-info-desc virtual-makeup-lip-ani virtual-makeup-lip-info-desc">{{ i18nPage.desc }}</div>
                <div class="virtual-info-buttons">
                    <a href="javascript:;" class="ui-button virtual-makeup-lip-ani virtual-makeup-lip-info-button" @click="onContact">{{ $t('contact') }}</a>
                </div>
            </div>
            <div class="virtual-makeup-lip-ani virtual-makeup-lip-imgs">
                <img class="virtual-makeup-lip-img" :class="{ active: index === current }" :src="require(`@assets/images/product/virtualMakeup/lip/${index}.png`)" alt="" v-for="index in 4" :key="index" />
                <div class="virtual-makeup-lip-tabs">
                    <div class="virtual-makeup-lip-ani virtual-makeup-lip-tabhead"></div>
                    <div class="virtual-makeup-lip-ani virtual-makeup-lip-tab" :class="{ active: current === index + 1 }" @click="onTabClick(index)" v-for="(item, index) in list" :key="index" :style="`--bg: ${item.color};`"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        i18nPage() {
            return this.$t('virtualMakeupPage.lip');
        },
    },
    data() {
        return {
            timer: null,
            current: 1,
            list: [
                {
                    color: '#6b111a',
                },
                {
                    color: '#930719',
                },
                {
                    color: '#af3326',
                },
                {
                    color: '#bc4c4e',
                },
            ],
        };
    },
    methods: {
        onContact() {
            this.mxShowContactModal({
                source: 'Makeup',
            });
        },
        onTabClick(index) {
            clearInterval(this.timer);
            this.current = index + 1;
        },
        play() {
            if (this.timer) {
                clearInterval(this.timer);
            }
            this.timer = setInterval(() => {
                if (this.current === 4) {
                    this.current = 1;
                } else {
                    this.current++;
                }
            }, 2000);
        },
    },
    mounted() {
        this.mxAnimation({
            handle: '.virtual-makeup-lip-ani',
            triggerElement: '.virtual-makeup-lip',
            interval: 0.15,
            triggerHook: 0.5,
            onEnter: () => {
                this.play();
            },
        });
    },
    beforeDestroy() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    },
};
</script>

<style lang="scss">
.virtual-makeup-lip {
    @media only screen and (min-width: 769px) {
        &-container {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            width: 1070px;
            margin: 0 auto;
        }
        &-imgs {
            flex: 1;
            height: 740px;
            position: relative;
            background: url(~@assets/images/product/mobile.png) 120px 80px no-repeat;
            background-size: 294px auto;
            &:after {
                content: '';
                position: absolute;
                width: 348px;
                height: 99px;
                background: rgba(0, 0, 0, 0.2);
                background: url(~@assets/images/product/shadow.png) 0 0 no-repeat;
                background-size: contain;
                left: 50%;
                bottom: -24px;
                transform: translateX(-60%);
            }
        }
        &-img {
            position: absolute;
            top: 97px;
            width: 255px;
            left: 141px;
            opacity: 0;
            transition: all 0.6s;

            &.active {
                opacity: 1;
                transition: all 1s;
            }
        }
        &-tabs {
            display: flex;
            align-items: center;
            position: absolute;
            left: 48px;
            top: 500px;
        }
        &-tabhead {
            width: 98px;
            height: 98px;
            background: url(~@assets/images/product/virtualMakeup/lip/lip.jpg) 0 0 no-repeat;
            background-size: contain;
            margin-right: 8px;
        }
        &-tab {
            width: 66px;
            height: 66px;
            margin: 0 10px;
            cursor: pointer;
            border-radius: 33px;
            position: relative;
            background: var(--bg);

            &.active {
                background: transparent !important;
                &:before {
                    content: '';
                    width: 66px;
                    height: 66px;
                    border-radius: 44px;
                    border: solid 3px var(--bg);
                    position: absolute;
                    left: 0;
                    top: 0;
                    box-sizing: border-box;
                }
                &:after {
                    content: '';
                    width: 56px;
                    height: 56px;
                    border-radius: 44px;
                    position: absolute;
                    left: 5px;
                    top: 5px;
                    background: var(--bg);
                }
            }
        }
        &-info {
            width: 450px;
            margin-left: auto;

        }
    }

    @media only screen and (max-width: 768px) {
        &-container {
            display: flex;
            flex-direction: column;
        }
        &-info {
            width: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            padding: 0.8rem 0.35rem 0.5rem 0.35rem;
            &-title {
                font-size: 0.28rem;
                font-weight: 600;
                line-height: 1.1;
            }
            &-desc {
                font-size: 0.13rem;
                width: 2.8rem;
                margin-top: 0.2rem;
                margin-bottom: 0.5rem;
            }
            &-button {
                margin-right: auto;
            }
        }
        &-imgs {
            width: 2.22rem;
            height: 4.4rem;
            margin: 0 auto;
            position: relative;
            background: url(~@assets/images/product/mobile.png) center 0 no-repeat;
            background-size: auto 4.4rem;
            &:after {
                content: '';
                position: absolute;
                width: 2.62rem;
                height: 0.75rem;
                background: url(~@assets/images/product/mshadow.png) 0 0 no-repeat;
                background-size: contain;
                left: 50%;
                transform: translateX(-50%);
                bottom: -0.72rem;
            }
        }
        &-img {
            position: absolute;
            width: 1.92rem;
            left: 0.16rem;
            top: 0.12rem;
            opacity: 0;
            transition: all 0.5s;

            &.active {
                opacity: 1;
            }
        }
        &-tabs {
            display: flex;
            align-items: center;
            position: absolute;
            z-index: 2;
            left: -0.56rem;
            top: 3.13rem;
        }
        &-tabhead {
            width: 0.74rem;
            height: 0.74rem;
            background: url(~@assets/images/product/virtualMakeup/lip/lip.jpg) 0 0 no-repeat;
            background-size: contain;
            margin-right: 0.05rem;
        }
        &-tab {
            width: 0.5rem;
            height: 0.5rem;
            margin: 0 0.08rem;
            cursor: pointer;
            border-radius: 0.3rem;
            position: relative;
            background: var(--bg);
            &.active {
                background: transparent !important;
                &:before {
                    content: '';
                    width: 0.5rem;
                    height: 0.5rem;
                    border-radius: 0.3rem;
                    border: solid 0.02rem var(--bg);
                    position: absolute;
                    left: 0;
                    top: 0;
                    box-sizing: border-box;
                }
                &:after {
                    content: '';
                    width: 0.44rem;
                    height: 0.44rem;
                    border-radius: 0.3rem;
                    position: absolute;
                    left: 0.03rem;
                    top: 0.03rem;
                    background: var(--bg);
                }
            }
        }
    }
}
</style>
