<template>
    <div class="product-home">
        <top></top>
        <saas></saas>
        <management></management>
        <mfigure></mfigure>
        <scene></scene>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import top from './components/top.vue';
import saas from '@views/home/components/saas.vue';
import management from './components/management.vue';
import mfigure from './components/figure.vue';
import scene from './components/scene.vue';

export default {
    name: 'productName',
    components: {
        top,
        saas,
        management,
        mfigure,
        scene,
    },
    computed: {
        ...mapState({
            currentLang: state => state.route.params.lang || 'en',
        }),
    },
    data() {
        return {
            pageDurationID: 'Product',
        };
    },
    methods: {},
    mounted() {
        this.$track('product_overview_imp');
    },
};
</script>
