<template>
    <div class="virtual-contact-tryout">
        <div class="virtual-contact-tryout-container">
            <div class="virtual-info virtual-contact-tryout-info">
                <div class="virtual-info-title poppins virtual-contact-tryout-ani virtual-contact-tryout-info-title">{{ i18nPage.title }}</div>
                <div class="virtual-info-desc virtual-contact-tryout-ani virtual-contact-tryout-info-desc">{{ i18nPage.desc }}</div>
            </div>

            <div class="virtual-contact-tryout-imgs">
                <img src="@assets/images/product/virtualContact/tryout/bg.png" alt="" class="virtual-contact-tryout-ani virtual-contact-tryout-img" />
                <div class="virtual-contact-tryout-tabs">
                    <img src="@assets/images/product/virtualContact/tryout/tabhead.jpg" class="virtual-contact-tryout-ani virtual-contact-tryout-tabhead" />
                    <img v-for="index in 4" :key="index" :src="require(`@assets/images/product/virtualContact/tryout/${index}.png`)" class="virtual-contact-tryout-ani virtual-contact-tryout-tab" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        i18nPage() {
            return this.$t('virtualContactLensesPage.tryout');
        },
    },
    mounted() {
        this.mxAnimation({
            handle: '.virtual-contact-tryout-ani',
            triggerElement: '.virtual-contact-tryout',
            interval: 0.15,
        });
    },
};
</script>

<style lang="scss">
.virtual-contact-tryout {
    background-color: #f9f9f9;

    @media only screen and (min-width: 769px) {
        &-container {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            width: 1070px;
            margin: 0 auto;
            padding: 80px 0 130px 0;
        }
        &-imgs {
            width: 464px;
            height: 590px;
            position: relative;
            flex-shrink: 0;

            &:after {
                content: '';
                position: absolute;
                width: 348px;
                height: 99px;
                background: rgba(0, 0, 0, 0.2);
                background: url(~@assets/images/product/shadow.png) 0 0 no-repeat;
                background-size: contain;
                left: 50%;
                bottom: -100px;
                transform: translateX(-50%);
            }
        }
        &-img {
            display: block;
            height: 100%;
            margin: 0 auto;
        }
        &-tabs {
            position: absolute;
            left: 0;
            top: 420px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &-tabhead {
            width: 98px;
            height: 98px;
            flex-shrink: 0;
        }
        &-tab {
            flex-shrink: 0;
            width: 68px;
            height: 68px;
            border-radius: 40px;
            box-shadow: 0 10px 60px rgba(0, 0, 0, 0.2);
        }
        &-info {
            width: 450px;
            margin-left: auto;
        }
    }

    @media only screen and (max-width: 768px) {
        &-container {
            padding-bottom: 1.4rem;
        }
        &-info {
            width: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            padding: 0.5rem 0.35rem;
            &-title {
                font-size: 0.28rem;
                font-weight: 600;
                line-height: 1.1;
            }
            &-desc {
                font-size: 0.13rem;
                width: 2.8rem;
                margin-top: 0.2rem;
                margin-bottom: 0.5rem;
            }
        }
        &-imgs {
            height: 4.42rem;
            margin: 0 0.13rem;
            box-sizing: border-box;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                width: 2.62rem;
                height: 0.75rem;
                background: url(~@assets/images/product/mshadow.png) 0 0 no-repeat;
                background-size: contain;
                left: 50%;
                transform: translateX(-50%);
                bottom: -0.72rem;
            }
        }
        &-img {
            display: block;
            height: 100%;
            margin: 0 auto;
        }
        &-tabs {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            top: 3.15rem;
            left: 0;
        }
        &-tabhead {
            width: 0.74rem;
            height: 0.74rem;
        }
        &-tab {
            width: 0.52rem;
            height: 0.52rem;
        }
    }
}
</style>
