<template>
    <div class="product-evev">
        <top></top>
        <machine></machine>
        <dim></dim>
        <patent></patent>
        <compare></compare>
        <saas></saas>
        <scene></scene>
        <params></params>
        <stories class="hidden-xs-only"></stories>
        <storiesMobile class="hidden-sm-and-up"></storiesMobile>
    </div>
</template>

<script>
import top from './components/top';
import machine from './components/machine';
import dim from './components/dim';
import patent from './components/patent';
import compare from './components/compare';
import saas from './components/saas';
import scene from './components/scene';
import params from './components/params';
import stories from '../../home/components/stories';
import storiesMobile from '../../home/components/stories-mobile';

export default {
    components: {
        top,
        machine,
        dim,
        patent,
        compare,
        saas,
        scene,
        params,
        stories,
        storiesMobile,
    },
};
</script>

<style lang="scss">
.product-evev {
    @media only screen and (min-width: 769px) {
        background: #151515;
        margin-top: -71px;
    }
    @media only screen and (max-width: 768px) {
        margin-top: -48px;
    }
}
</style>
