<template>
    <div class="product-skin-mirror">
        <system></system>
        <skinDetail></skinDetail>
        <customize></customize>
        <service background="#f9f9f9"></service>
        <stories style="background: #fff;" class="hidden-xs-only"></stories>
        <storiesMobile style="background: #fff;" class="hidden-sm-and-up"></storiesMobile>
    </div>
</template>

<script>
import system from './components/system.vue';
import skinDetail from './components/skinDetail.vue';
import customize from './components/customize.vue';
import service from './components/service.vue';
import stories from '../../home/components/stories';
import storiesMobile from '../../home/components/stories-mobile';

export default {
    name: 'productSkinMirror',
    components: {
        system,
        skinDetail,
        customize,
        service,
        stories,
        storiesMobile,
    },
    data() {
        return {
            pageDurationID: 'MeituEveSkin',
        };
    },
    mounted() {
        this.$track('product_skinmirror_imp');
    },
};
</script>
