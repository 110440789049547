<template>
    <div class="product-home-figure">
        <div class="block-title product-home-figure-ani poppins product-home-figure-title">{{ i18nPage.title }}</div>
        <div class="block-desc product-home-figure-ani product-home-figure-desc">{{ i18nPage.desc }}</div>

        <div class="product-home-figure-list">
            <div class="product-home-figure-ani product-home-figure-item" v-for="(item, index) in i18nPage.list" :key="index">
                <div class="left">
                    <img v-if="item.icon" :src="require(`@assets/images/product/home/figure/${item.icon}.png`)" alt="" class="logo" />
                    <div class="left-text">
                        <div class="text1">{{ item.text1 }}</div>
                        <div class="text2" v-if="item.text2">{{ item.text2 }}</div>
                    </div>
                </div>
                <div class="info" :class="[`info${index + 1}`, currentLang]">
                    <div class="info-top">
                        <div class="poppins name" :class="[currentLang]">{{ item.name }}</div>
                        <div class="text3">{{ item.text3 }}</div>
                    </div>
                    <div class="info-desc">
                        <div class="info-desc-item" :class="[currentLang]" v-for="(desc, descIndex) in item.desc" :key="descIndex">
                            {{ desc }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        i18nPage() {
            return this.$t('productHomePage.figure');
        },
    },
    mounted() {
        this.mxAnimation({
            handle: '.product-home-figure-ani',
            triggerElement: '.product-home-figure',
        });
    },
};
</script>

<style lang="scss">
.product-home-figure {
    background: #f9f9f9;

    @media only screen and (min-width: 769px) {
        padding: 80px 0;
        &-list {
            width: 763px;
            margin: 40px auto 0 auto;
            padding: 0 10px;
            box-sizing: border-box;
        }
        &-item {
            width: 100%;
            height: 319px;
            border-radius: 15px;
            margin-bottom: 20px;
            display: flex;
            background: #fff;
            overflow: hidden;

            &:last-of-type {
                margin-bottom: 0;
            }

            .left {
                width: 225px;
                padding: 0 32px;
                text-align: center;
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                box-sizing: border-box;
                .logo {
                    width: 102px;
                    height: 102px;
                    display: block;
                }
                .text1 {
                    font-size: 18px;
                    line-height: 25px;
                    font-weight: 600;
                    margin: 5px 0;
                }
                .text2 {
                    font-size: 12px;
                    line-height: 17px;
                    font-weight: 400;
                    color: #333;
                    opacity: 0.5;
                }
            }
            .info {
                flex: 1;
                padding-top: 50px;
                padding-left: 32px;

                &.zh,
                &.tw {
                    padding-top: 52px;
                    padding-left: 56px;
                }

                &.info1 {
                    background: url(~@assets/images/product/home/figure/3.jpg);
                    background-size: contain;
                }
                &.info2 {
                    background: url(~@assets/images/product/home/figure/1.jpg);
                    background-size: contain;
                }
                &.info3 {
                    background: url(~@assets/images/product/home/figure/2.jpg);
                    background-size: contain;
                    padding-top: 88px;
                }

                &-top {
                    padding-left: 15px;
                    border-left: solid 5px #00C8C8;
                    margin-bottom: 20px;
                    max-width: 370px;
                    box-sizing: border-box;

                    .name {
                        font-size: 24px;
                        line-height: 28.8px;
                        font-weight: 500;
                    }
                    .text3 {
                        color: #00C8C8;
                        font-size: 18px;
                        line-height: 25px;
                        font-weight: 500;
                    }
                }
                &-desc {
                    width: 313px;
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 400;
                    padding-left: 20px;

                    &-item {
                        margin-bottom: 5px;
                        &.zh,
                        &.tw {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        padding: 0.6rem 0.35rem;

        &-title {
            font-size: 28px;
            line-height: 1.1;
            font-weight: 600;
        }
        &-desc {
            font-size: 13px;
            line-height: 18px;
            font-weight: 400;
            margin: 20px 0;
        }

        &-item {
            display: flex;
            flex-direction: column-reverse;
            background: #fff;
            border-radius: 0.1rem;
            overflow: hidden;
            margin-bottom: 0.2rem;

            &:last-of-type {
                margin-bottom: 0;
            }

            .info {
                padding-top: 0.3rem;
                padding-left: 0.3rem;
                height: 2.51rem;
                box-sizing: border-box;

                &.info1 {
                    height: 2.09rem;
                    background: url(~@assets/images/product/home/figure/m3.jpg) 0 0 no-repeat;
                    background-size: contain;
                }
                &.info2 {
                    height: 2.26rem;
                    background: url(~@assets/images/product/home/figure/m1.jpg) 0 0 no-repeat;
                    background-size: contain;
                }
                &.info3 {
                    height: 2.51rem;
                    background: url(~@assets/images/product/home/figure/m2.jpg) 0 0 no-repeat;
                    background-size: contain;
                }

                &-top {
                    border-left: 2px solid #8ED5FF;
                    padding-left: 8px;
                    transform: translateX(-10px);
                    margin-bottom: 0.12rem;

                    .name {
                        font-size: 20px;
                        line-height: 1.1;
                        font-weight: 600;
                    }
                    .text3 {
                        color: #00C8C8;
                        font-size: 13px;
                        line-height: 18px;
                        font-weight: 600;
                        margin-top: 2px;
                        max-width: 1.8rem;
                    }
                }
                &-desc {
                    width: 1.56rem;
                    font-size: 11px;
                    line-height: 15px;
                    font-weight: 400;

                    &-item {
                        margin-bottom: 0.05rem;
                    }
                }
            }

            .left {
                display: flex;
                align-items: center;
                padding: 0.12rem 0.18rem;

                .logo {
                    width: 0.46rem;
                    height: 0.46rem;
                    display: block;
                    flex-shrink: 0;
                }

                &-text {
                    margin-left: 0.1rem;
                    flex: 1;
                }
                .text1 {
                    font-size: 13px;
                    line-height: 15px;
                    font-weight: 600;
                }
                .text2 {
                    font-size: 9px;
                    line-height: 1.1;
                    color: #999;
                    font-weight: 400;
                    margin-top: 5px;
                }
            }
        }
    }
}
</style>
