<template>
    <div class="product-service" :style="`background-color: ${background};`">
        <div class="product-service-container">
            <h2 class="block-title poppins product-service-ani product-service-title">{{ i18nPage.title }}</h2>
            <div class="block-desc product-service-ani product-service-desc">{{ i18nPage.desc }}</div>
            <div class="block-desc product-service-ani product-service-desc" v-if="i18nPage.desc2">{{ i18nPage.desc2 }}</div>

            <div class="product-service-main">
                <div class="product-service-ani product-service-item" v-for="(item, index) in i18nPage.list" :key="index">
                    <!-- <img class="img" :src="require(`@assets/images/product/skinMirror/service/${index + 1}.jpg`)" alt="" /> -->
                    <div class="info">
                        <h3 class="poppins product-service-ani info-title">{{ item.title }}</h3>
                        <h4 class="product-service-ani info-desc">{{ item.desc }}</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        background: {
            type: String,
            default: '#fff',
        },
    },
    computed: {
        i18nPage() {
            return this.$t('skinMirrorPage.service');
        },
    },
    mounted() {
        this.mxAnimation({
            handle: '.product-service-ani',
            triggerElement: '.product-service',
            interval: 0.1,
        });
    },
};
</script>

<style lang="scss">
.product-service {
    @media only screen and (min-width: 769px) {
        &-container {
            width: 1200px;
            padding: 80px 20px;
            margin: 0 auto;
        }
        &-main {
            display: flex;
            margin-top: 40px;
        }
        &-item {
            width: 300px;
            height: 400px;
            overflow: hidden;
            flex-shrink: 0;
            position: relative;
            background-position: center center !important;
            transition: background-size 0.3s;

            &:nth-child(1) {
                background: url(~@assets/images/product/skinMirror/service/1.jpg) 0 0 no-repeat;
                background-size: 100%;
            }
            &:nth-child(2) {
                background: url(~@assets/images/product/skinMirror/service/2.jpg) 0 0 no-repeat;
                background-size: 100%;
            }
            &:nth-child(3) {
                background: url(~@assets/images/product/skinMirror/service/3.jpg) 0 0 no-repeat;
                background-size: 100%;
            }
            &:nth-child(4) {
                background: url(~@assets/images/product/skinMirror/service/4.jpg) 0 0 no-repeat;
                background-size: 100%;
            }

            .img {
                display: block;
                width: 100%;
                transition: all 0.4s;
            }

            .info {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background: rgba(0, 0, 0, 0.7);
                color: #fff;
                transition: all 0.3s;
                transform: translateZ(0);

                &-title {
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 1.5;
                    width: 100%;
                    text-align: center;
                }
                &-desc {
                    font-weight: 300;
                    font-size: 12px;
                    margin-bottom: 20px;
                }
            }

            &:hover {
                background-size: 110%;
                .info {
                    opacity: 0;
                }
            }
        }
    }
    @media only screen and (max-width: 1108px) and (min-width: 769px) {
        &-container {
            width: 100%;
            overflow: hidden;
            box-sizing: border-box;
        }
        &-main {
            transform: scale(0.89);
            transform-origin: 0;
        }
    }

    @media only screen and (max-width: 768px) {
        background-image: url("data:image/svg+xml,%3Csvg width='375' height='867' viewBox='0 0 375 867' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='.1' fill-rule='evenodd' clip-rule='evenodd' d='M479.21 4.613C308.582-19.735 140.158 60.026 50.114 207.826c-16.184 26.571-7.762 61.207 18.814 77.375 26.552 16.177 61.206 7.767 77.389-18.793C212.891 157.106 337.31 98.105 463.289 116.083c173.855 24.809 294.954 187.33 269.964 362.298-24.99 174.968-186.749 297.126-360.603 272.317-126.084-17.992-229.035-109.545-262.3-233.212-8.075-30.045-38.975-47.833-69.013-39.767-22.432 6.036-38.059 24.818-41.148 46.45a56.131 56.131 0 0 0 1.38 22.554C46.556 713.98 185.95 837.797 356.73 862.167c235.341 33.583 454.266-131.43 488.036-367.873C878.534 257.863 714.551 38.196 479.21 4.614z' fill='%23C8C8C8'/%3E%3C/svg%3E");
        background-position: right 0.6rem;
        background-repeat: no-repeat;
        &-container {
            padding: 0.8rem 0;
        }
        &-title {
            font-size: 0.28rem;
            font-weight: 600;
            line-height: 1.1;
            width: 1.8rem;
            margin-left: 0.35rem;
        }
        &-desc {
            font-size: 0.13rem;
            line-height: 0.18rem;
            font-weight: 400;
            width: 2rem;
            margin: 0.2rem 0 0.3rem 0.35rem;
        }
        &-item {
            width: 2.7rem;
            height: 3.09rem;
            margin: 0 auto 0.4rem auto;
            border-radius: 0.1rem;
            overflow: hidden;

            &:nth-child(1) {
                background: url(~@assets/images/product/skinMirror/service/mobile/1.png) 0 0 no-repeat;
                background-size: contain;
            }
            &:nth-child(2) {
                background: url(~@assets/images/product/skinMirror/service/mobile/2.png) 0 0 no-repeat;
                background-size: contain;
            }
            &:nth-child(3) {
                background: url(~@assets/images/product/skinMirror/service/mobile/3.png) 0 0 no-repeat;
                background-size: contain;
            }
            &:nth-child(4) {
                margin-bottom: 0;
                background: url(~@assets/images/product/skinMirror/service/mobile/4.png) 0 0 no-repeat;
                background-size: contain;
            }

            .info {
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                padding: 0.22rem;
                background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6));
                color: #fff;
                display: flex;
                flex-direction: column;

                &-title {
                    font-size: 15px;
                    font-weight: 600;
                    margin-top: auto;
                }
                &-desc {
                    width: 1.6rem;
                    font-size: 13px;
                    font-weight: 300;
                    line-height: 18px;
                    height: 36px;
                    margin-top: 5px;
                }
            }
        }
    }
}
</style>
