<template>
    <div class="product-meitukey">
        <top></top>
        <platform></platform>
        <scan></scan>
        <problems></problems>
        <meitukeyAdd class="hidden-xs-only"></meitukeyAdd>
        <meitukeyAddMobile class="hidden-sm-and-up"></meitukeyAddMobile>
    </div>
</template>

<script>
import top from './components/top.vue';
import platform from './components/platform.vue';
import scan from './components/scan.vue';
import problems from './components/problems.vue';
import meitukeyAdd from './components/add.vue';
import meitukeyAddMobile from './components/add-mobile.vue';
export default {
    name: 'productMeitukey',
    components: {
        top,
        platform,
        scan,
        problems,
        meitukeyAdd,
        meitukeyAddMobile,
    },
    data() {
        return {
            pageDurationID: 'MeituKey',
        };
    },
    mounted() {
        this.$track('product_meitukey_imp');
    },
};
</script>
