<template>
    <div class="product-home-scene">
        <div class="block-title product-home-scene-ani poppins product-home-scene-title">{{ i18nPage.title }}</div>
        <div class="block-desc product-home-scene-ani product-home-scene-desc">{{ i18nPage.desc }}</div>

        <div class="product-home-scene-list">
            <div class="product-home-scene-ani product-home-scene-item" @click="onItemClick(item)" :class="[`item${index + 1}`, { 'product-home-scene-item-overseas': buildForOverseas }]" v-for="(item, index) in i18nPage.list" :key="index">
                <img class="icon" :class="[currentLang]" :src="require(`@assets/images/product/home/scene/${index + 1}.png`)" alt="" />
                <div class="poppins product-home-scene-item-title">{{ item.title }}</div>
                <div class="product-home-scene-item-desc">
                    {{ item.desc }}
                    <div class="hidden-xs-only icon-arrow"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    data() {
        return {
            buildForOverseas: process.env.VUE_APP_BUILD_FOR === 'evelabinsight',
        };
    },
    computed: {
        ...mapState({
            currentLang: state => state.route.params.lang || 'en',
        }),
        i18nPage() {
            return this.$t('productHomePage.scene');
        },
    },
    methods: {
        onItemClick(data) {
            this.$router.push({
                path: `/${this.currentLang}${data.path}`,
            });
        },
    },
    mounted() {
        this.mxAnimation({
            handle: '.product-home-scene-ani',
            triggerElement: '.product-home-scene',
        });
    },
};
</script>

<style lang="scss">
.product-home-scene {
    @media only screen and (min-width: 769px) {
        padding: 80px 0;

        .icon-arrow {
            width: 5px;
            height: 8px;
            background: url("data:image/svg+xml,%3Csvg width='5' height='8' viewBox='0 0 5 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.172 6.828L4 4 1.172 1.172' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") 0 0 no-repeat;
            background-size: contain;
            display: inline-block;
        }
        &-list {
            width: 743px;
            margin: 40px auto 0 auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        &-item {
            width: 234px;
            height: 271px;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            color: #fff;
            cursor: pointer;
            background-position: center center !important;
            transition: background-size 0.3s;

            .icon {
                margin-bottom: 10px;

                &.zh,
                &.tw {
                    margin-bottom: 12px;
                }
            }

            &-title {
                font-size: 24px;
                line-height: 1.1;
                font-weight: 500;
                margin-bottom: 8px;
                width: 160px;
                text-align: center;
            }
            &-desc {
                font-size: 18px;
                line-height: 25px;
                font-weight: 400;
                width: 160px;
                text-align: center;

                .icon-arrow {
                    margin-bottom: 1px;
                    margin-left: 2px;
                }
            }

            &.item1 {
                width: 743px;
                height: 271px;
                margin-bottom: 20px;
                background: url(~@assets/images/product/home/scene/1.jpg) 0 0 no-repeat;
                background-size: 100%;

                .icon {
                    width: 43px;
                    height: 43px;
                }
            }
            &.item2 {
                background: url(~@assets/images/product/home/scene/2.jpg) 0 0 no-repeat;
                background-size: 100%;
                .icon {
                    width: 43px;
                    height: 45px;
                }
            }
            &.item3 {
                background: url(~@assets/images/product/home/scene/3.jpg) 0 0 no-repeat;
                background-size: 100%;
                .icon {
                    width: 50px;
                    height: 32px;
                }
            }
            &.item4 {
                background: url(~@assets/images/product/home/scene/4.jpg) 0 0 no-repeat;
                background-size: 100%;
                .icon {
                    width: 27px;
                    height: 41px;
                }
            }
            &:hover {
                background-size: 110%;
            }
        }
        &-item-overseas {
            &.item1 {
                background: url(~@assets/images/product/home/scene/evelabinsight/1.jpg) 0 0 no-repeat;
                background-size: 100%;
            }
            &.item2 {
                background: url(~@assets/images/product/home/scene/evelabinsight/2.jpg) 0 0 no-repeat;
                background-size: 100%;
            }
            &.item3 {
                background: url(~@assets/images/product/home/scene/evelabinsight/3.jpg) 0 0 no-repeat;
                background-size: 100%;
            }
            &.item4 {
                background: url(~@assets/images/product/home/scene/evelabinsight/4.jpg) 0 0 no-repeat;
                background-size: 100%;
            }
            &:hover {
                background-size: 110%;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        padding: 0.6rem 0.35rem;

        &-title {
            font-size: 28px;
            line-height: 1.1;
            font-weight: 600;
        }
        &-desc {
            font-size: 13px;
            line-height: 18px;
            font-weight: 400;
            margin-top: 0.05rem;
            margin-bottom: 0.3rem;
        }

        &-list {
            position: relative;
            height: 4.82rem;
        }
        &-item {
            border-radius: 0.05rem;
            position: absolute;
            display: flex;
            flex-direction: column;
            padding: 0 0.15rem;
            box-sizing: border-box;
            color: #fff;

            &-title {
                font-size: 20px;
                line-height: 1.1;
                font-weight: 600;
                max-width: 1.25rem;
            }
            &-desc {
                margin-top: 2px;
                margin-bottom: 0.15rem;
                font-size: 13px;
                line-height: 15px;
                font-weight: 400;
            }

            .icon {
                height: 0.22rem;
                margin-top: auto;
                margin-right: auto;
                margin-bottom: 2px;
            }

            &.item1 {
                width: 1.64rem;
                height: 3.25rem;
                background: url(~@assets/images/product/home/scene/m1.jpg) 0 0 no-repeat;
                background-size: contain;
                left: 0;
                top: 0;
            }
            &.item2 {
                width: 1.34rem;
                height: 1.89rem;
                background: url(~@assets/images/product/home/scene/m2.jpg) 0 0 no-repeat;
                background-size: contain;
                right: 0;
                top: 0;
            }
            &.item3 {
                width: 3.05rem;
                height: 1.46rem;
                background: url(~@assets/images/product/home/scene/m3.jpg) 0 0 no-repeat;
                background-size: cover;
                left: 0;
                bottom: 0;
            }
            &.item4 {
                width: 1.34rem;
                height: 1.31rem;
                background: url(~@assets/images/product/home/scene/m4.jpg) 0 0 no-repeat;
                background-size: cover;
                right: 0;
                top: 1.94rem;
            }
        }
        &-item-overseas {
            &.item1 {
                background: url(~@assets/images/product/home/scene/evelabinsight/m1.jpg) 0 0 no-repeat;
                background-size: contain;
            }
            &.item2 {
                background: url(~@assets/images/product/home/scene/evelabinsight/m2.jpg) 0 0 no-repeat;
                background-size: contain;
            }
        }
    }
}
</style>
