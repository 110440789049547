<template>
    <div class="product-contactLenses">
        <top></top>
        <customize></customize>
        <tryout></tryout>
        <cloud :img="require('@assets/images/product/virtualMakeup/cloud/4.jpg')"></cloud>
    </div>
</template>

<script>
import top from './components/top.vue';
import customize from './components/customize.vue';
import tryout from './components/tryout.vue';
import cloud from '../makeup/components/cloud.vue';

export default {
    name: 'productContactLenses',
    components: {
        top,
        customize,
        tryout,
        cloud,
    },
    data() {
        return {
            pageDurationID: 'Lenses',
        };
    },
    mounted() {
        this.$track('product_lenses_imp');
    },
};
</script>
