<template>
    <div class="evev-top" :class="[currentLang, { 'evev-top-overseas': buildForOverseas }]">
        <div class="evev-top-container">
            <div class="evev-top-machine" :class="{ 'evev-top-machine-overseas': buildForOverseas }"></div>
            <div class="banner-info evev-top-info">
                <h1 class="banner-info-title poppins title">{{ i18nPage.title }}</h1>
                <h2 class="banner-info-desc desc">{{ i18nPage.desc }}</h2>
            </div>
            <div class="evev-top-imgs">
                <canvas ref="canvas"></canvas>
                <div class="circle circle1" v-if="current !== 'jowl'" @click="onAniChange('jowl')">
                    <div class="circle-inner"></div>
                    <div class="circle-hotarea"></div>
                </div>
                <div class="circle circle2" v-if="current !== 'crow'" @click="onAniChange('crow')">
                    <div class="circle-inner"></div>
                    <div class="circle-hotarea"></div>
                </div>
                <div class="circle circle3" v-if="current !== 'medialMalar'" @click="onAniChange('medialMalar')">
                    <div class="circle-inner"></div>
                    <div class="circle-hotarea"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import preload from '@utils/preload';
export default {
    data() {
        return {
            current: 'medialMalar',
            timer: null,
            // 帧数
            num: {
                jowl: 77,
                crow: 70,
                medialMalar: 60,
            },
            // 间隔多久播放
            interval: {
                jowl: 120,
                crow: 100,
                medialMalar: 140,
            },
            cache: {},
            buildForOverseas: process.env.VUE_APP_BUILD_FOR === 'evelabinsight',
        };
    },
    computed: {
        i18nPage() {
            return this.$t('evevPage.top');
        },
    },
    methods: {
        onAniChange(type) {
            this.play(type, () => {
                this.current = type;
            });
        },
        ani(type, data) {
            if (this.timer) {
                clearInterval(this.timer);
            }
            var canvas = this.$refs.canvas;
            var ctx = canvas.getContext('2d');
            let i = 0;
            this.timer = setInterval(() => {
                if (i === data.length - 1) {
                    i = 0;
                }
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(data[i], 0, 0, canvas.width, canvas.height);
                i++;
            }, this.interval[type]);
        },
        play(type, callback) {
            if (this.cache[type]) {
                this.ani(type, this.cache[type]);
                if (callback) callback();
            } else {
                let urls = [];

                for (let i = 1; i <= this.num[type]; i++) {
                    let index = i;
                    urls.push(require(`@assets/images/product/evev/top/${type}/序列${index}.png`));
                }

                preload(urls).done(res => {
                    this.cache[type] = res;
                    this.ani(type, res);
                    if (callback) callback();
                });
            }
        },
        loadImgs(type) {
            let urls = [];

            for (let i = 1; i <= this.num[type]; i++) {
                let index = i;
                urls.push(require(`@assets/images/product/evev/top/${type}/序列${index}.png`));
            }

            preload(urls).done(res => {
                this.cache[type] = res;
                if (this.timer) {
                    clearInterval(this.timer);
                }

                var canvas = this.$refs.canvas;
                var ctx = canvas.getContext('2d');
                let i = 0;
                this.timer = setInterval(() => {
                    if (i === res.length - 1) {
                        i = 0;
                    }
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    ctx.drawImage(res[i], 0, 0, canvas.width, canvas.height);
                    i++;
                }, this.interval[type]);
            });
        },
    },
    mounted() {
        var img = new Image();
        img.onload = () => {
            var canvas = this.$refs.canvas;
            var ctx = canvas.getContext('2d');
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
        };
        img.src = require(`@assets/images/product/evev/top/model.png`);
        this.play(this.current);
    },
};
</script>

<style lang="scss">
.evev-top {
    overflow: hidden;
    &-imgs {
        canvas {
            display: block;
        }
    }
    .circle {
        width: 10px;
        height: 10px;
        border-radius: 10px;
        background: #fff;
        position: absolute;
        animation: point0 infinite 1.2s;

        &1 {
            left: 265px;
            top: 432px;
        }
        &2 {
            left: 360px;
            top: 270px;
        }
        &3 {
            top: 322px;
            left: 279px;
        }

        @keyframes point0 {
            to {
                opacity: 0.2;
                transform: scale(1.3);
            }
        }
        &-inner {
            width: 28px;
            height: 28px;
            border-radius: 28px;
            border: 1px solid rgba(255, 255, 255, 0.5);
            box-sizing: border-box;
            position: absolute;
            left: -9px;
            top: -9px;
            animation: circle0 infinite 1.2s;
            @keyframes circle0 {
                to {
                    opacity: 0;
                    transform: scale(1.2);
                }
            }
        }
        &-hotarea {
            width: 50px;
            height: 50px;
            position: absolute;
            left: -20px;
            top: -20px;
        }
    }
    @media only screen and (min-width: 769px) {
        height: 668px;
        background: url(~@assets/images/product/evev/top/bg.jpg) center no-repeat;
        background-size: cover;

        &-container {
            width: 1108px;
            height: 100%;
            margin: 0 auto;
            color: #fff;
            display: flex;
            position: relative;
        }
        &-machine {
            width: 443px;
            height: 295px;
            background: url(~@assets/images/product/evev/top/machine.png) 0 0 no-repeat;
            background-size: contain;
            position: absolute;
            bottom: -34px;
            left: -224px;
        }
        &-machine-overseas {
            background: url(~@assets/images/product/evev/top/evelabinsight/machine.png) 0 0 no-repeat;
            background-size: contain;
        }
        &-info {
            padding-top: 209px;
            padding-left: 30px;
            display: block !important;
        }
        &-imgs {
            position: absolute;
            bottom: 0;
            right: 0;
            transform: translate(100px, 0) scale(0.7);
            transform-origin: center bottom;

            .circle {
                cursor: pointer;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        height: 5.64rem;
        background: url(~@assets/images/product/evev/top/mbg.jpg) center no-repeat;
        background-size: cover;
        position: relative;
        &.evev-top-overseas {
            background: url(~@assets/images/product/evev/top/evelabinsight/mbg.jpg) center no-repeat;
            background-size: cover;
        }

        .circle {
            width: 20px;
            height: 20px;

            &-inner {
                width: 40px;
                height: 40px;
                left: -10px;
                top: -10px;
            }
        }

        &-info {
            padding: 0 0.3rem;
            .title {
                font-size: 0.3rem;
                line-height: 1.1;
                padding-top: 0.92rem;
                text-align: center;
                color: #fff;
                font-weight: 600;
            }
            .desc {
                font-size: 0.14rem;
                line-height: 0.2rem;
                text-align: center;
                color: #fff;
                margin-top: 0.12rem;
            }
        }
        &-imgs {
            position: absolute;
            bottom: 0;
            transform: scale(.42);
            transform-origin: 0% 100%;
            left: 0.4rem;
        }
    }

    @media only screen and (max-width: 350px) {
        &-imgs {
            transform: scale(0.35);
        }
    }
}
</style>
