<template>
    <div class="evev-params">
        <h2 class="block-title evev-params-ani evev-params-title">{{ i18nPage.title }}</h2>
        <div class="evev-params-container">
            <div class="evev-params-ani evev-params-block">
                <h3 class="evev-params-block-title">{{ $t('sizeAndWeight') }}</h3>
                <paramsTable :data="i18nPage.sizeAndWeightData" />
            </div>
            <div class="evev-params-ani evev-params-block">
                <h3 class="evev-params-block-title">{{ $t('basicInfo') }}</h3>
                <paramsTable :data="i18nPage.basicInfoData" />
            </div>
            <div class="evev-params-ani evev-params-block">
                <h3 class="evev-params-block-title">{{ $t('power') }}</h3>
                <paramsTable :data="i18nPage.powerData" />
            </div>
            <div class="evev-params-ani evev-params-block">
                <h3 class="evev-params-block-title">{{ $t('camera') }}</h3>
                <paramsTable :data="i18nPage.cameraData" />
            </div>
        </div>
        <div class="evev-params-ani evev-params-buttons">
            <router-link class="evev-params-button" :to="{ path: '/' + $route.params.lang + '/qa/tech-specs/evev', query: $route.query }">{{ $t('more_params') }}</router-link>
        </div>
    </div>
</template>

<script>
import paramsTable from '@views/qa/techSpecs/comp/paramsTable.vue';
export default {
    components: {
        paramsTable,
    },
    computed: {
        i18nPage() {
            return this.$t('paramsEveVPage');
        },
    },
    mounted() {
        this.mxAnimation({
            handle: '.evev-params-ani',
            triggerElement: '.evev-params',
        });
    },
};
</script>

<style lang="scss">
.evev-params {
    background: #1E2021;
    @media only screen and (min-width: 769px) {
        padding: 80px 0;
        &-title {
            color: #fff;
        }
        &-container {
            width: 1060px;
            margin: 0 auto;
            padding: 40px 0;
        }
        &-block {
            display: flex;
            padding: 30px 0;
            border-bottom: solid 1px rgba(217, 217, 217, 0.2);
            &:last-of-type {
                border: none;
            }
            &-title {
                color: #999;
                font-size: 28px;
                width: 300px;
                margin-right: 50px;
                flex-shrink: 0;
            }
            .params-table {
                color: #fff;
                font-size: 16px;
                flex-shrink: 0;

                td {
                    padding: 8px 0;
                }
            }
        }
        &-buttons {
            text-align: center;
        }
        &-button {
            display: inline-flex;
            align-items: center;
            height: 64px;
            padding: 0 45px;
            border-radius: 32px;
            font-size: 24px;
            color: #fff;
            border: solid 2px #fff;
        }
    }

    @media only screen and (max-width: 768px) {
        padding: 0.6rem 0;

        &-title {
            color: #fff;
            text-align: center;
            font-size: 0.28rem;
            line-height: 0.32rem;
            font-weight: 600;
        }
        &-container {
            padding: 0.3rem 0.2rem;
        }
        &-block {
            padding: 0.2rem 0;
            border-bottom: solid 1px rgba(217, 217, 217, 0.2);

            &:last-of-type {
                border: none;
            }

            &-title {
                color: #999;
                font-size: 0.15rem;
                padding-bottom: 0.08rem;
            }

            .params-table {
                color: #fff;
                font-size: 0.12rem;
                flex-shrink: 0;

                td {
                    padding: 7px 0;
                }
            }
        }
        &-buttons {
            text-align: center;
        }
        &-button {
            display: inline-flex;
            align-items: center;
            height: 0.4rem;
            padding: 0 0.3rem;
            border-radius: 0.2rem;
            font-size: 0.15rem;
            color: #fff;
            border: solid 1px #fff;
        }
    }
}
</style>
