<template>
    <div class="product-home-management">
        <div class="product-home-management-ani block-title poppins product-home-management-title">{{ i18nPage.title }}</div>
        <div class="product-home-management-ani block-desc product-home-management-desc">{{ i18nPage.desc }}</div>
        <div class="product-home-management-ani product-home-management-container">
            <div class="pc" :style="`background-image: url(${bgs.pc});`">
                <div class="product-home-management-ani pore" :style="`background-image: url(${bgs.pore});`"></div>
                <div class="product-home-management-ani card" :style="`background-image: url(${bgs.card});`"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    computed: {
        ...mapState({
            currentLang: state => state.route.params.lang || 'zh',
        }),
        i18nPage() {
            return this.$t('productHomePage.management');
        },
        bgs() {
            let { currentLang } = this;
            return {
                pc: require(`@assets/images/product/home/management/${currentLang}_pc.png`),
                pore: require(`@assets/images/product/home/management/${currentLang}_pore.png`),
                card: require(`@assets/images/product/home/management/${currentLang}_card.png`),
            };
        },
    },
    mounted() {
        this.mxAnimation({
            handle: '.product-home-management-ani',
            triggerElement: '.product-home-management',
        });
    },
};
</script>

<style lang="scss">
.product-home-management {
    @media only screen and (min-width: 769px) {
        padding-top: 80px;
        padding-bottom: 50px;
        .pc {
            width: 566px;
            height: 357px;
            margin: 70px auto 0 auto;
            position: relative;
            background-position: 0 0;
            background-repeat: no-repeat;
            background-size: contain;
        }
        .pore {
            width: 188px;
            height: 210px;
            position: absolute;
            top: 94px;
            left: -82px;
            background-position: 0 0;
            background-repeat: no-repeat;
            background-size: contain;
        }
        .card {
            width: 208px;
            height: 154px;
            position: absolute;
            top: -36px;
            right: -64px;
            background-position: 0 0;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

    @media only screen and (max-width: 768px) {
        padding: 0.6rem 0;

        &-title {
            font-size: 28px;
            line-height: 1.1;
            font-weight: 600;
            padding: 0 0.35rem;
        }
        &-desc {
            font-size: 13px;
            line-height: 18px;
            font-weight: 400;
            padding: 0 0.35rem;
            margin-top: 0.05rem;
            margin-bottom: 0.36rem;
        }
        .pc {
            width: 2.78rem;
            height: 1.76rem;
            position: relative;
            margin: 0 auto;
            transform: translateX(0.1rem);
            background-position: 0 0;
            background-repeat: no-repeat;
            background-size: contain;
        }
        .pore {
            width: 0.93rem;
            height: 1.04rem;
            position: absolute;
            left: -0.41rem;
            top: 0.45rem;
            background-position: 0 0;
            background-repeat: no-repeat;
            background-size: contain;
        }
        .card {
            width: 1.04rem;
            height: 0.77rem;
            position: absolute;
            top: -0.18rem;
            right: -0.33rem;
            background-position: 0 0;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}
</style>
