<template>
    <div class="skin-mirror-system">
        <div class="skin-mirror-system-container">
            <div class="banner-info skin-mirror-system-info">
                <h1 class="banner-info-title poppins skin-mirror-system-ani skin-mirror-system-title">{{ i18nPage.title }}</h1>
                <h2 class="banner-info-desc skin-mirror-system-ani skin-mirror-system-desc">{{ i18nPage.desc }}</h2>
                <div class="banner-info-buttons">
                    <a href="javascript:;" class="poppins ui-button skin-mirror-system-ani skin-mirror-system-button" @click="onContact">{{ $t('contact') }}</a>
                </div>
            </div>
            <div class="skin-mirror-system-ani skin-mirror-system-img" :class="[currentLang]"></div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        i18nPage() {
            return this.$t('skinMirrorPage.system');
        },
    },
    methods: {
        onContact() {
            this.mxShowContactModal({
                source: 'MeituEveSkin',
            });
        },
    },
};
</script>

<style lang="scss">
.skin-mirror-system {
    @media only screen and (min-width: 769px) {
        height: 690px;
        &-container {
            height: 100%;
            max-width: 1108px;
            margin: 0 auto;
            display: flex;
            padding: 0 20px;
        }
        &-info {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        &-img {
            width: 403px;
            height: 511px;
            margin-left: auto;
            margin-right: 40px;
            margin-top: 130px;
            background: url(~@assets/images/product/skinMirror/system/ipad.png) 0 0 no-repeat;
            background-size: contain;

            &.zh,
            &.tw {
                background: url(~@assets/images/product/skinMirror/system/ipad_zh.png) 0 0 no-repeat;
                background-size: contain;
            }
        }
    }
    @media only screen and (max-width: 768px) {
        &-container {
            padding: 0.6rem 0;
        }
        &-info {
            height: 100%;
            display: flex;
            flex-direction: column;
        }
        &-title {
            font-size: 0.3rem;
            font-weight: 700;
            line-height: 1.1;
            width: 2.5rem;
            margin: 0 auto;
            text-align: center;
        }
        &-desc {
            text-align: center;
            font-size: 0.14rem;
            padding: 0.25rem 0.35rem 0.2rem 0.35rem;
        }
        .banner-info-buttons {
            display: flex;
            justify-content: center;
        }
        &-button {
            margin: 0 auto;
            padding: 0 0.2rem;
            height: 0.44rem;
            line-height: 0.44rem;
            background: #000;
            border-radius: 0.22rem;
            color: #fff;
            font-size: 0.14rem;
        }
        &-img {
            width: 3.32rem;
            height: 3.34rem;
            background: url(~@assets/images/product/skinMirror/system/ipad-mobile.png) 0 0 no-repeat;
            background-size: contain;
            margin: 0.7rem auto 0 auto;
        }
    }
}
</style>
