<template>
    <div class="evev-dim">
        <h2 class="block-title poppins evev-dim-ani evev-dim-title">{{ i18nPage.title }}</h2>
        <p class="block-desc evev-dim-ani evev-dim-desc">{{ i18nPage.desc }}</p>

        <div class="evev-dim-ani evev-dim-container">
            <div class="evev-dim-main">
                <div class="img" :class="[item.key, { active: item.key === tab2 }]" v-for="item in i18nPage.skinData" :key="item.key"></div>
                <div class="img" :class="[item.key, { active: item.key === tab2 }]" v-for="item in i18nPage.agingData" :key="item.key"></div>

                <div class="evev-dim-main-legend" v-if="legend[tab2]">
                    <div class="content">
                        <div class="arrow-left"></div>
                        <div class="arrow-right"></div>
                        <div class="min">{{ legend[tab2].min }}</div>
                        <div class="max">{{ legend[tab2].max }}</div>
                        <div class="value" :style="parseStyle(legend[tab2])">{{ legend[tab2].value }}</div>
                    </div>
                </div>
            </div>
            <div class="evev-dim-tabs1">
                <div class="evev-dim-tabs1-container">
                    <div class="evev-dim-tabs1-line" :style="lineStyle"></div>
                    <a href="javascript:;" @click="onTabs1Click(index)" class="evev-dim-tabs1-item" :class="{ active: tab1 === index }" v-for="(item, index) in i18nPage.tabs1" :key="index">{{ item }}</a>
                </div>
            </div>
            <div class="evev-dim-tabs2" ref="tabs">
                <div class="evev-dim-tabs2-wrap" ref="tabsWrap">
                    <template v-if="tab1 === 0">
                        <div class="evev-dim-tabs2-item" @click="onTabs2Click(item)" :class="[item.key, { active: item.key === tab2, 'next-1': index === currentIndex - 1 || index === currentIndex + 1, 'next-2': index === currentIndex - 2 || index === currentIndex + 2, 'next-3': index === currentIndex - 3 || index === currentIndex + 3, 'next-4': index === currentIndex - 4 || index === currentIndex + 4 }]" v-for="(item, index) in i18nPage.skinData" :key="index">{{ item.name }}</div>
                    </template>
                    <template v-if="tab1 === 1">
                        <div class="evev-dim-tabs2-item" @click="onTabs2Click(item)" :class="[item.key, { active: item.key === tab2, 'next-1': index === currentIndex - 1 || index === currentIndex + 1, 'next-2': index === currentIndex - 2 || index === currentIndex + 2, 'next-3': index === currentIndex - 3 || index === currentIndex + 3, 'next-4': index === currentIndex - 4 || index === currentIndex + 4 }]" v-for="(item, index) in i18nPage.agingData" :key="index">{{ item.name }}</div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tab1: 1,
            tab2: 'medialMalar', // 默认苹果肌
            lineStyle: {},
        };
    },
    computed: {
        i18nPage() {
            return this.$t('evevPage.dim');
        },
        currentIndex() {
            let index = 0;
            let data = this.i18nPage.skinData;
            if (this.tab1 === 1) {
                data = this.i18nPage.agingData;
            }
            data.forEach((item, i) => {
                if (item.key === this.tab2) {
                    index = i;
                }
            });
            return index;
        },
        legend() {
            let { i18nPage } = this;
            return {
                // 泪沟
                tear: {
                    min: this.$t('evevPage.dim.x_level', [1]),
                    max: this.$t('evevPage.dim.x_level', [5]),
                    value: this.$t('evevPage.dim.x_level', [2.7]),
                    percent: 0.1,
                },
                // 抬头纹
                foreheadWrinkles: {
                    min: this.$t('evevPage.dim.x_level', [1]),
                    max: this.$t('evevPage.dim.x_level', [8]),
                    value: this.$t('evevPage.dim.x_level', [2.8]),
                    percent: 0,
                },
                // 色斑
                spots: {
                    min: this.$t('evevPage.dim.x_level', [0]),
                    max: this.$t('evevPage.dim.x_level', [3]),
                    value: this.$t('evevPage.dim.x_level', [1.4]),
                    top: 0.25,
                },
                // 眼脸松弛
                eyelidLaxity: {
                    min: this.$t('evevPage.dim.x_level', [0]),
                    max: this.$t('evevPage.dim.x_level', [8]),
                    value: this.$t('evevPage.dim.x_level', [2.5]),
                    top: 0,
                },
                // 鱼尾纹
                crowsFeet: {
                    min: this.$t('evevPage.dim.x_level', [0]),
                    max: this.$t('evevPage.dim.x_level', [7]),
                    value: this.$t('evevPage.dim.x_level', [2.4]),
                    top: 0,
                },
                // 框周纹
                eyeSocket: {
                    min: this.$t('evevPage.dim.x_level', [0]),
                    max: this.$t('evevPage.dim.x_level', [8]),
                    value: this.$t('evevPage.dim.x_level', [2.0]),
                    top: 0,
                },
                // 口角纹
                marionetteLines: {
                    min: this.$t('evevPage.dim.x_level', [0]),
                    max: this.$t('evevPage.dim.x_level', [7]),
                    value: this.$t('evevPage.dim.x_level', [2.4]),
                    top: 0,
                },
                // 眼袋
                eyeBags: {
                    min: this.$t('evevPage.dim.x_level', [0]),
                    max: this.$t('evevPage.dim.x_level', [6]),
                    value: this.$t('evevPage.dim.x_level', [1.4]),
                    top: 0.1,
                },
                // 法令纹
                nlf: {
                    min: this.$t('evevPage.dim.x_level', [0]),
                    max: this.$t('evevPage.dim.x_level', [7]),
                    value: this.$t('evevPage.dim.x_level', [1.1]),
                    top: 0,
                },
                // 眉间纹
                glabellarWrinkles: {
                    min: this.$t('evevPage.dim.x_level', [0]),
                    max: this.$t('evevPage.dim.x_level', [8]),
                    value: this.$t('evevPage.dim.x_level', [1.0]),
                    top: 0,
                },
                // 下颌缘
                jawLine: {
                    min: this.$t('evevPage.dim.x_deg', [110]),
                    max: this.$t('evevPage.dim.x_deg', [145]),
                    value: this.$t('evevPage.dim.rightCheek', [125]),
                    bottom: 0,
                },
                pore: {
                    min: i18nPage.pore1,
                    max: i18nPage.pore2,
                    value: i18nPage.pore2,
                    bottom: 0,
                },
                blackheads: {
                    min: i18nPage.none,
                    max: i18nPage.severe,
                    value: i18nPage.medium,
                    top: 0.55,
                },
                blackCircle: {
                    min: i18nPage.none,
                    max: i18nPage.severe,
                    value: i18nPage.medium,
                    top: 0.55,
                },
                sensitivity: {
                    min: i18nPage.sensitive1,
                    max: i18nPage.sensitive3,
                    value: i18nPage.sensitive2,
                    top: 0.55,
                },
                acne: {
                    min: i18nPage.none,
                    max: 'IV',
                    value: this.$t('evevPage.dim.x_level', ['I']),
                    top: 0,
                },
                glow: {
                    min: i18nPage.good,
                    max: i18nPage.bad,
                    value: i18nPage.good,
                    top: 0,
                },
            };
        },
    },
    methods: {
        parseStyle(data) {
            if (typeof data.top !== 'undefined') {
                return {
                    top: data.top * 100 + '%',
                };
            }
            if (typeof data.bottom !== 'undefined') {
                return {
                    bottom: data.bottom * 100 + '%',
                };
            }
        },
        onTabs1Click(index) {
            if (this.tab1 === index) return;
            this.tab1 = index;
            this.setTabs1Line();
            if (index === 0) {
                this.tab2 = 'blackCircle';
            } else {
                this.tab2 = 'medialMalar';
            }
            this.$nextTick(() => {
                this.setTab2Offset();
            });
        },
        onTabs2Click(data) {
            this.tab2 = data.key;
            this.$nextTick(() => {
                this.setTab2Offset();
            });
        },
        async setTabs1Line() {
            await this.$nextTick();

            var el = this.$el.querySelector('.evev-dim-tabs1-item.active');
            if (!el) return;
            this.lineStyle = {
                width: el.offsetWidth + 'px',
                left: el.offsetLeft + 'px',
            };
        },
        setTab2Offset() {
            let winW = window.innerWidth;
            let tabsEl = this.$refs.tabs;
            let tabsWrapEl = this.$refs.tabsWrap;
            let current = tabsEl.querySelector(`.${this.tab2}`);
            let currentRect = current.getBoundingClientRect();
            if (winW > 768) {
                let offsetTop = current.offsetTop;
                let tabsRect = tabsEl.getBoundingClientRect();
                let top = tabsRect.height / 2 - currentRect.height / 2 - offsetTop;
                tabsWrapEl.style.transform = `translateY(${top}px)`;
            } else {
                tabsWrapEl.scrollTo({
                    left: current.offsetLeft - winW / 2 + currentRect.width / 2, 
                    top: 0,
                    behavior: 'smooth',
                });
            }
        },
    },
    mounted() {
        setTimeout(() => {
            this.setTabs1Line();
            this.setTab2Offset();
        }, 200);

        this.mxAnimation({
            handle: '.evev-dim-ani',
            triggerElement: '.evev-dim',
        });
    },
};
</script>

<style lang="scss">
.evev-dim {
    background: #343536;

    @media only screen and (min-width: 769px) {
        padding-top: 80px;
        &-title {
            color: #fff;
        }
        &-desc {
            color: #fff;
        }
        &-container {
            width: 948px;
            height: 712px;
            margin: 0 auto;
            position: relative;
        }
        &-main {
            width: 100%;
            height: 100%;
            margin-top: -20px;
            background: url(~@assets/images/product/evev/dim/pc/ipad.png) 0 0 no-repeat;
            background-size: contain;

            &-legend {
                position: absolute;
                z-index: 10;
                left: 131px;
                bottom: 144px;

                .content {
                    width: 1px;
                    height: 94px;
                    position: relative;
                    .min,
                    .max {
                        position: absolute;
                        left: 50%;
                        font-size: 12px;
                        line-height: 15px;
                        color: #ccc;
                        transform: translateX(-50%) scale(0.6667);
                        white-space: nowrap;
                    }
                    .min {
                        top: -15px;
                    }
                    .max {
                        bottom: -15px;
                    }
                    .arrow {
                        &-left,
                        &-right {
                            width: 2px;
                            height: 1px;
                            background: rgba(255, 255, 255, 0.6);
                            position: absolute;
                            bottom: 0;
                        }
                        &-left {
                            right: 0;
                            transform: rotate(45deg);
                        }
                        &-right {
                            left: 0;
                            transform: rotate(-45deg);
                        }
                    }
                    .value {
                        position: absolute;
                        box-sizing: border-box;
                        min-width: 66px;
                        height: 30px;
                        background: linear-gradient(280.5deg, rgba(0, 200, 200, 0.3) -17.58%, rgba(255, 255, 255, 0) 218.03%);
                        backdrop-filter: blur(40px);
                        border-radius: 2px;
                        font-size: 16px;
                        color: #00c8c8;
                        padding: 0 14px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: solid 2px #3d8988;
                        left: 10px;
                        white-space: nowrap;

                        &:before {
                            position: absolute;
                            content: '';
                            width: 9px;
                            height: 1px;
                            background: #fff;
                            left: -11px;
                            top: 14px;
                            transform: scaleY(0.5);
                        }
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 1px;
                        height: 100%;
                        background-color: #fff;
                        transform: scaleX(0.5);
                    }
                }
            }

            .img {
                width: 550px;
                height: 452px;
                position: absolute;
                left: 102px;
                top: 140px;
                background-size: contain;
                background-position: 0 0;
                background-repeat: no-repeat;
                opacity: 0;
                transition: all 0.5s;
                &.active {
                    opacity: 1;
                }

                &.pore {
                    background-image: url(~@assets/images/product/evev/dim/pc/pore.jpg);
                }
                &.blackheads {
                    background-image: url(~@assets/images/product/evev/dim/pc/blackheads.jpg);
                }
                &.acne {
                    background-image: url(~@assets/images/product/evev/dim/pc/acne.jpg);
                }
                &.blackCircle {
                    background-image: url(~@assets/images/product/evev/dim/pc/blackCircle.jpg);
                }
                &.sensitivity {
                    background-image: url(~@assets/images/product/evev/dim/pc/sensitivity.jpg);
                }
                &.glow {
                    background-image: url(~@assets/images/product/evev/dim/pc/glow.jpg);
                }
                &.skinTone {
                    background-image: url(~@assets/images/product/evev/dim/pc/skinTone.jpg);
                }
                &.tear {
                    background-image: url(~@assets/images/product/evev/dim/pc/tear.jpg);
                }
                &.spots {
                    background-image: url(~@assets/images/product/evev/dim/pc/spots.jpg);
                }
                &.eyeBags {
                    background-image: url(~@assets/images/product/evev/dim/pc/eyeBags.jpg);
                }
                &.eyeSocket {
                    background-image: url(~@assets/images/product/evev/dim/pc/eyeSocket.jpg);
                }
                &.foreheadWrinkles {
                    background-image: url(~@assets/images/product/evev/dim/pc/foreheadWrinkles.jpg);
                }
                &.nlf {
                    background-image: url(~@assets/images/product/evev/dim/pc/nlf.jpg);
                }
                &.crowsFeet {
                    background-image: url(~@assets/images/product/evev/dim/pc/crowsFeet.jpg);
                }
                &.marionetteLines {
                    background-image: url(~@assets/images/product/evev/dim/pc/marionetteLines.jpg);
                }
                &.eyelidLaxity {
                    background-image: url(~@assets/images/product/evev/dim/pc/eyelidLaxity.jpg);
                }
                &.glabellarWrinkles {
                    background-image: url(~@assets/images/product/evev/dim/pc/glabellarWrinkles.jpg);
                }
                &.medialMalar {
                    background-image: url(~@assets/images/product/evev/dim/pc/medialMalar.jpg);
                }
                &.jowl {
                    background-image: url(~@assets/images/product/evev/dim/pc/jowl.jpg);
                }
                &.jawLine {
                    background-image: url(~@assets/images/product/evev/dim/pc/jawLine.jpg);
                }
            }
        }
        &-tabs1 {
            font-size: 16px;
            line-height: 22px;
            position: absolute;
            width: 550px;
            left: 102px;
            top: 102px;
            display: flex;
            &-container {
                margin: 0 auto;
                position: relative;
                padding-bottom: 13px;

                &:after {
                    content: '';
                    width: 100%;
                    height: 3px;
                    border-radius: 2px;
                    background: rgba(255, 255, 255, 0.3);
                    position: absolute;
                    left: 0;
                    bottom: 0;
                }
            }
            &-line {
                position: absolute;
                height: 3px;
                bottom: 0;
                background: #00c8c8;
                border-radius: 2px;
                transition: all 0.4s;
            }
            &-item {
                color: #fff;
                font-weight: 500;
                padding: 0 10px;
                &:last-of-type {
                    margin-left: 6px;
                }

                &.active {
                    color: #00c8c8;
                }
            }
        }
        &-tabs2 {
            position: absolute;
            width: 220px;
            height: 370px;
            right: 78px;
            top: 162px;
            overflow: hidden;

            &-wrap {
                width: 100%;
                transition: all 0.3s;
                padding-left: 54px;
                padding-right: 30px;
                box-sizing: border-box;
            }

            &-item {
                color: rgba(255, 255, 255, 0);
                cursor: pointer;
                font-size: 16px;
                line-height: 1.4;
                font-weight: 300;
                margin: 16px 0;
                position: relative;
                user-select: none;
                pointer-events: none;

                &.next-1 {
                    pointer-events: auto;
                    color: rgba(255, 255, 255, 0.6);
                }
                &.next-2 {
                    pointer-events: auto;
                    color: rgba(255, 255, 255, 0.4);
                }
                &.next-3 {
                    pointer-events: auto;
                    color: rgba(255, 255, 255, 0.2);
                }
                &.next-4 {
                    pointer-events: auto;
                    color: rgba(255, 255, 255, 0.1);
                }

                &.active {
                    // font-size: 25px;
                    color: #00c8c8;
                    font-weight: 500;
                    
                    text-shadow: 0px 4px 4px #000000;

                    &:before {
                        width: 2px;
                        height: calc(100% - 12px);
                        background: #00c8c8;
                        content: '';
                        position: absolute;
                        left: -14px;
                        top: 6px;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        padding: 0.6rem 0;
        &-title {
            font-size: 0.28rem;
            line-height: 0.32rem;
            padding: 0 0.3rem;
            text-align: center;
            color: #fff;
            font-weight: 600;
        }
        &-desc {
            font-size: 0.13rem;
            padding: 0 0.3rem;
            text-align: center;
            color: #fff;
            margin-top: 0.12rem;
        }

        &-main {
            width: 3.38rem;
            height: 2.91rem;
            background: url(~@assets/images/product/evev/dim/mobile/ipad.png) 0 0 no-repeat;
            background-size: contain;
            margin: 0 auto;
            position: relative;
            transform: translateX(0.03rem);

            &-legend {
                position: absolute;
                z-index: 10;
                bottom: 195px;
                transform: scale(0.3);
                transform-origin: 0 0;
                left: 0.82rem;
                bottom: 0;

                .content {
                    width: 1px;
                    height: 128px;
                    position: relative;
                    .min,
                    .max {
                        position: absolute;
                        left: 50%;
                        font-size: 11px;
                        line-height: 15px;
                        color: #ccc;
                        transform: translateX(-50%);
                        white-space: nowrap;
                    }
                    .min {
                        top: -20px;
                    }
                    .max {
                        bottom: -20px;
                    }
                    .arrow {
                        &-left,
                        &-right {
                            width: 3px;
                            height: 1px;
                            background: rgba(255, 255, 255, 0.6);
                            position: absolute;
                            bottom: 0;
                        }
                        &-left {
                            right: 0;
                            transform: rotate(45deg);
                        }
                        &-right {
                            left: 0;
                            transform: rotate(-45deg);
                        }
                    }
                    .value {
                        position: absolute;
                        box-sizing: border-box;
                        min-width: 90px;
                        height: 40px;
                        background: linear-gradient(280.5deg, rgba(0, 200, 200, 0.3) -17.58%, rgba(255, 255, 255, 0) 218.03%);
                        backdrop-filter: blur(40px);
                        border-radius: 2px;
                        font-size: 20px;
                        color: #00c8c8;
                        padding: 0 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: solid 2px #3d8988;
                        left: 10px;
                        white-space: nowrap;

                        &:before {
                            position: absolute;
                            content: '';
                            width: 9px;
                            height: 1px;
                            background: #fff;
                            left: -11px;
                            top: 20px;
                            transform: scaleY(0.5);
                        }
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 1px;
                        height: 100%;
                        background-color: #fff;
                        transform: scaleX(0.5);
                    }
                }
            }

            .img {
                width: 2.59rem;
                height: 1.77rem;
                position: absolute;
                background-size: contain;
                background-position: 0 0;
                background-repeat: no-repeat;
                opacity: 0;
                transition: all 0.5s;
                left: 0.37rem;
                top: 0.32rem;
                &.active {
                    opacity: 1;
                }
                &.pore {
                    background-image: url(~@assets/images/product/evev/dim/mobile/pore.png);
                }
                &.blackheads {
                    background-image: url(~@assets/images/product/evev/dim/mobile/blackheads.png);
                }
                &.acne {
                    background-image: url(~@assets/images/product/evev/dim/mobile/acne.png);
                }
                &.blackCircle {
                    background-image: url(~@assets/images/product/evev/dim/mobile/blackCircle.png);
                }
                &.sensitivity {
                    background-image: url(~@assets/images/product/evev/dim/mobile/sensitivity.png);
                }
                &.glow {
                    background-image: url(~@assets/images/product/evev/dim/mobile/glow.png);
                }
                &.skinTone {
                    background-image: url(~@assets/images/product/evev/dim/mobile/skinTone.png);
                }
                &.tear {
                    background-image: url(~@assets/images/product/evev/dim/mobile/tear.png);
                }
                &.spots {
                    background-image: url(~@assets/images/product/evev/dim/mobile/spots.png);
                }
                &.eyeBags {
                    background-image: url(~@assets/images/product/evev/dim/mobile/eyeBags.png);
                }
                &.eyeSocket {
                    background-image: url(~@assets/images/product/evev/dim/mobile/eyeSocket.png);
                }
                &.foreheadWrinkles {
                    background-image: url(~@assets/images/product/evev/dim/mobile/foreheadWrinkles.png);
                }
                &.nlf {
                    background-image: url(~@assets/images/product/evev/dim/mobile/nlf.png);
                }
                &.crowsFeet {
                    background-image: url(~@assets/images/product/evev/dim/mobile/crowsFeet.png);
                }
                &.marionetteLines {
                    background-image: url(~@assets/images/product/evev/dim/mobile/marionetteLines.png);
                }
                &.eyelidLaxity {
                    background-image: url(~@assets/images/product/evev/dim/mobile/eyelidLaxity.png);
                }
                &.glabellarWrinkles {
                    background-image: url(~@assets/images/product/evev/dim/mobile/glabellarWrinkles.png);
                }
                &.medialMalar {
                    background-image: url(~@assets/images/product/evev/dim/mobile/medialMalar.png);
                }
                &.jowl {
                    background-image: url(~@assets/images/product/evev/dim/mobile/jowl.png);
                }
                &.jawLine {
                    background-image: url(~@assets/images/product/evev/dim/mobile/jawLine.png);
                }
            }
        }

        &-tabs1 {
            font-size: 0.13rem;
            line-height: 0.18rem;
            display: flex;
            margin-top: -0.4rem;

            &-container {
                margin: 0 auto;
                position: relative;
                padding-bottom: 0.08rem;

                &:after {
                    content: '';
                    width: 100%;
                    height: 2px;
                    border-radius: 2px;
                    background: rgba(255, 255, 255, 0.3);
                    position: absolute;
                    left: 0;
                    bottom: 0;
                }
            }
            &-line {
                position: absolute;
                height: 2px;
                bottom: 0;
                background: #00c8c8;
                border-radius: 2px;
                transition: all 0.4s;
            }
            &-item {
                color: #fff;
                font-weight: 500;
                padding: 0 0.04rem;
                &:last-of-type {
                    margin-left: 0.1rem;
                }

                &.active {
                    color: #00c8c8;
                }
            }
        }

        &-tabs2 {
            position: relative;
            overflow: hidden;
            margin-top: 0.2rem;

            &:before {
                width: 0.15rem;
                height: 100%;
                content: '';
                background-image: linear-gradient(to right, rgba(52, 53, 54, 1), rgba(52, 53, 54, 0) 100%);
                position: absolute;
                left: 0;
                top: 0;
                z-index: 2;
            }
            &:after {
                width: 0.15rem;
                height: 100%;
                content: '';
                background-image: linear-gradient(to left, rgba(52, 53, 54, 1), rgba(52, 53, 54, 0) 100%);
                position: absolute;
                right: 0;
                top: 0;
                z-index: 2;
            }

            &-wrap {
                display: flex;
                width: 100%;
                height: 0.18rem;
                overflow-x: auto;
                flex-wrap: nowrap;
                padding-bottom: 0.2rem;

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            &-item {
                font-size: 0.13rem;
                line-height: 0.18rem;
                flex-shrink: 0;
                padding: 0 0.04rem;
                margin: 0 0.1rem;
                color: rgba(255, 255, 255, 0.3);
                position: relative;
                cursor: pointer;
                font-weight: 300;
                white-space: nowrap;

                &.active {
                    color: #00c8c8;
                    font-weight: 500;

                    &:after {
                        content: '';
                        width: 70%;
                        height: 2px;
                        background: #00c8c8;
                        position: absolute;
                        left: 15%;
                        top: 0.25rem;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 350px) {
        &-main {
            &-legend {
                transform: scale(0.2);
                bottom: -0.24rem;
            }
        }
    }
}
</style>
