<template>
    <div class="evev-patent">
        <h2 class="block-title poppins evev-patent-ani evev-patent-title">{{ i18nPage.title }}</h2>
        <p class="block-desc evev-patent-ani evev-patent-desc">{{ i18nPage.desc }}</p>

        <div class="evev-patent-container">
            <div class="evev-patent-ani evev-patent-list">
                <div class="evev-patent-scroll" ref="scroll">
                    <div class="evev-patent-item" :class="{ active: current === index }" @click="onTabClick(index)" v-for="(item, index) in i18nPage.tabs" :key="index">
                        <h3 class="title">{{ item.title }}</h3>
                        <div class="desc" v-if="item.desc">{{ item.desc }}</div>
                    </div>
                </div>
                <div class="evev-patent-list-line" :style="lineStyle"></div>
            </div>
            <div class="hidden-sm-and-up evev-patent-ani evev-patent-descs">
                <template v-for="(item, index) in i18nPage.tabs">
                    <transition name="fade" :key="index">
                        <div class="evev-patent-descs-item" v-if="index === current && item.desc">{{ item.desc }}</div>
                    </transition>
                </template>
            </div>
            <div class="evev-patent-ani evev-patent-imgs">
                <transition name="fade">
                    <div class="model" v-if="current !== 3"></div>
                </transition>
                <div class="img img1" :class="{ show: current === 0 }"></div>
                <div class="img img2" :class="[{ show: current === 1, unzh: currentLang !== 'zh' }]"></div>
                <div class="img img3" :class="{ show: current === 2 }"></div>
                <div class="img img4" :class="{ show: current === 3 }"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    data() {
        return {
            current: 0,
            lineStyle: {},
        };
    },
    computed: {
        ...mapState({
            currentLang: state => state.route.params.lang || 'zh',
        }),
        i18nPage() {
            return this.$t('evevPage.patent');
        },
    },
    methods: {
        sleep(time) {
            return new Promise(resolve => {
                setTimeout(() => {
                    resolve();
                }, time);
            });
        },
        onTabClick(index) {
            this.current = index;
            this.setTabLine();
        },
        async setTabLine() {
            await this.$nextTick();
            var width = window.innerWidth;
            var el = this.$el.querySelector('.evev-patent-item.active');

            if (width <= 768) {
                var scrollEl = this.$refs.scroll;
                if (!scrollEl) return;
                this.mxScrollToCenter({
                    scrollEl,
                    el,
                    offset: -10,
                });
            } else {
                if (!el) return;
                this.lineStyle = {
                    height: el.offsetHeight + 'px',
                    top: el.offsetTop + 'px',
                };
            }
        },
    },
    mounted() {
        this.setTabLine();
        this.mxAnimation({
            handle: '.evev-patent-ani',
            triggerElement: '.evev-patent',
        });
    },
};
</script>

<style lang="scss">
.evev-patent {
    @media only screen and (min-width: 769px) {
        width: 100%;
        height: 758px;
        background: url(~@assets/images/product/evev/patent/bg.jpg) center no-repeat;
        background-size: cover;
        padding-top: 80px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        box-sizing: border-box;

        &-title,
        &-desc {
            color: #fff;
        }
        &-container {
            width: 1108px;
            margin: 0 auto;
            flex: 1;
            display: flex;
            align-items: center;
        }
        &-list {
            padding-left: 25px;
            border-left: 1px solid rgba(255, 255, 255, 0.8);
            position: relative;
            margin-top: 100px;

            &-line {
                width: 4px;
                position: absolute;
                left: -1px;
                background: #00c8c8;
                transition: all 0.4s;
            }
        }
        &-item {
            width: 380px;
            color: rgba(255, 255, 255, 0.5);
            margin-bottom: 25px;

            .title {
                font-size: 18px;
                line-height: 27px;
                cursor: pointer;
                font-weight: 500;
            }
            .desc {
                max-height: 0;
                opacity: 0;
                font-size: 14px;
                color: #fff;
                line-height: 20px;
                margin-top: 10px;
            }

            &:last-of-type {
                margin-bottom: 0;
            }

            &.active {
                .title {
                    color: #00c8c8;
                    font-size: 24px;
                    line-height: 28px;
                    // transition: all 0.2s;
                }
                .desc {
                    max-height: 30px;
                    opacity: 1;
                }
            }
        }
        &-imgs {
            height: 100%;
            margin-top: auto;
            margin-left: auto;
            position: relative;
            flex: 1;
            display: flex;
            .model {
                width: 602px;
                height: 534px;
                background: url(~@assets/images/product/evev/patent/model.png) 0 0 no-repeat;
                background-size: contain;
                margin-top: auto;
                margin-left: auto;
                position: relative;
                z-index: 2;
            }
            .img {
                transform: translateY(100px);
                opacity: 0;
                transition: all 0.6s;
                z-index: 3;
                pointer-events: none;

                &.show {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
            .img1 {
                width: 714px;
                height: 211px;
                background: url(~@assets/images/product/evev/patent/1.png) 0 0 no-repeat;
                background-size: contain;
                position: absolute;
                left: 6px;
                top: 210px;
            }
            .img2 {
                width: 303px;
                height: 246px;
                background: url(~@assets/images/product/evev/patent/2.png) 0 0 no-repeat;
                background-size: contain;
                position: absolute;
                left: 24px;
                top: 226px;

                &.unzh {
                    width: 333px;
                    height: 272px;
                    background: url(~@assets/images/product/evev/patent/2-en.png) 0 0 no-repeat;
                    background-size: contain;
                    left: 0;
                }
            }
            .img3 {
                width: 798px;
                height: 343px;
                background: url(~@assets/images/product/evev/patent/3.png) 0 0 no-repeat;
                background-size: contain;
                position: absolute;
                left: 20px;
                top: 124px;
                z-index: 1;
            }
            .img4 {
                width: 1278px;
                height: 892px;
                background: url(~@assets/images/product/evev/patent/4.png) 0 0 no-repeat;
                background-size: contain;
                position: absolute;
                left: -250px;
                top: -150px;
            }
        }
    }
    @media only screen and (max-width: 768px) {
        width: 100%;
        background: url(~@assets/images/product/evev/patent/mbg.jpg) center no-repeat;
        background-size: cover;
        padding-top: 0.6rem;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        &-title {
            font-size: 0.28rem;
            line-height: 0.32rem;
            font-weight: 600;
            color: #fff;
            text-align: center;
        }
        &-desc {
            font-size: 0.13rem;
            line-height: 0.16rem;
            text-align: center;
            color: #fff;
            margin-top: 0.12rem;
            margin-bottom: 0.4rem;
        }
        &-container {
            flex: 1;
            display: flex;
            flex-direction: column;

            &-imgs {
                flex: 1;
            }
        }
        &-list {
            overflow: hidden;
            position: relative;
            &:before {
                width: 0.15rem;
                height: 100%;
                content: '';
                background-image: linear-gradient(to right, rgba(21, 21, 21, 1), rgba(21, 21, 21, 0.6) 70%, rgba(255, 255, 255, 0) 100%);
                position: absolute;
                left: 0;
                top: 0;
                z-index: 2;
            }
            &:after {
                width: 0.15rem;
                height: 100%;
                content: '';
                background-image: linear-gradient(to left, rgba(21, 21, 21, 1), rgba(21, 21, 21, 0.6) 70%, rgba(255, 255, 255, 0) 100%);
                position: absolute;
                right: 0;
                top: 0;
                z-index: 2;
            }
        }
        &-scroll {
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            overflow-x: auto;
            overflow-y: hidden;

            &::-webkit-scrollbar {
                display: none;
            }
        }
        &-item {
            max-width: 2.1rem;
            padding: 0 0.1rem;
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            &:first-of-type {
                padding-left: 0.35rem;
            }
            &:last-of-type {
                padding-right: 0.35rem;
            }

            .title {
                font-size: 0.17rem;
                font-weight: 300;
                // white-space: nowrap;
                margin-right: auto;

                line-height: 0.23rem;
                padding-bottom: 0.2rem;
                position: relative;
                color: rgba(255, 255, 255, 0.8);
            }
            .desc {
                display: none;
            }

            &.active {
                .title {
                    color: #00c8c8;
                    font-weight: 600;

                    &:after {
                        width: 100%;
                        height: 2px;
                        background: #00c8c8;
                        position: absolute;
                        content: '';
                        position: absolute;
                        bottom: 0.1rem;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
            }
        }
        &-descs {
            position: relative;
            margin-top: 0.1rem;
            margin-left: 0.35rem;
            font-size: 0.13rem;
            line-height: 0.18rem;
            color: #fff;

            &-item {
                position: absolute;
                left: 0;
                top: 0;
            }
        }

        &-imgs {
            height: 2.5rem;
            margin-top: 0.5rem;
            display: flex;

            .model {
                width: 2.82rem;
                height: 2.5rem;
                background: url(~@assets/images/product/evev/patent/mmodel.png) 0 0 no-repeat;
                background-size: contain;
                position: absolute;
                bottom: 0;
                left: 0.465rem;
                z-index: 2;
            }
            .img {
                transform: translateY(100px);
                opacity: 0;
                transition: all 0.6s;
                z-index: 3;
                pointer-events: none;

                &.show {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
            .img1 {
                width: 3.12rem;
                height: 0.96rem;
                background: url(~@assets/images/product/evev/patent/m1.png) 0 0 no-repeat;
                background-size: contain;
                position: absolute;
                left: 0.3rem;
                bottom: 0.68rem;
            }
            .img2 {
                width: 1.41rem;
                height: 1.14rem;
                background: url(~@assets/images/product/evev/patent/m2.png) 0 0 no-repeat;
                background-size: contain;
                position: absolute;
                left: 0.1rem;
                bottom: 0.5rem;

                &.unzh {
                    width: 1.83rem;
                    height: 1.2rem;
                    background: url(~@assets/images/product/evev/patent/m2-en.png) 0 0 no-repeat;
                    background-size: contain;
                }
            }
            .img3 {
                width: 3.39rem;
                height: 1.46rem;
                background: url(~@assets/images/product/evev/patent/m3.png) 0 0 no-repeat;
                background-size: contain;
                position: absolute;
                left: 0.28rem;
                bottom: 0.6rem;
                z-index: 1;
            }
            .img4 {
                width: 3.75rem;
                height: 2.46rem;
                background: url(~@assets/images/product/evev/patent/m4.png) 0 0 no-repeat;
                background-size: contain;
                position: absolute;
                right: 0;
                bottom: 0.2rem;
            }
        }
    }
}
</style>
