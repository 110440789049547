<template>
    <div class="eve-quantitativeAnalysis">
        <div class="block-title poppins eve-quantitativeAnalysis-ani eve-quantitativeAnalysis-title">{{ i18nPage.title }}</div>
        <div class="eve-quantitativeAnalysis-list">
            <div class="eve-quantitativeAnalysis-ani eve-quantitativeAnalysis-item" :class="[item.key]" v-for="item in i18nPage.list" :key="item.key">
                <div class="poppins eve-quantitativeAnalysis-item-title">{{ item.title }}</div>
                <div class="eve-quantitativeAnalysis-item-desc">{{ item.desc }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        i18nPage() {
            return this.$t('evePage.quantitativeAnalysis');
        },
    },
    mounted() {
        this.mxAnimation({
            handle: '.eve-quantitativeAnalysis-ani',
            triggerElement: '.eve-quantitativeAnalysis-title',
            triggerHook: 0.5,
        });
    },
};
</script>

<style lang="scss">
.eve-quantitativeAnalysis {
    background: #f9f9f9;
    @media only screen and (min-width: 769px) {
        padding-top: 80px;
        &-list {
            max-width: 1108px;
            margin: 40px auto 0 auto;
            display: flex;
            flex-flow: wrap;
            justify-content: space-between;
            padding: 0 20px 80px 20px;
        }
        &-item {
            width: 258px;
            height: 396px;
            background-color: #000;
            background-size: contain;
            background-repeat: no-repeat;
            border-radius: 14px;
            margin: 12px 0;
            color: #fff;

            &-title {
                padding: 291px 14px 4px 14px;
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
            }
            &-desc {
                padding: 0 14px;
                font-size: 11px;
                line-height: 1.1;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 5;
                -webkit-box-orient: vertical;
            }

            &.wrinkles {
                background-image: url(~@assets/images/product/eve/quantitative/wrinkles.jpg);
            }
            &.porphyrin {
                background-image: url(~@assets/images/product/eve/quantitative/porphyrin.jpg);
            }
            &.spots {
                background-image: url(~@assets/images/product/eve/quantitative/spots.jpg);
            }
            &.acne {
                background-image: url(~@assets/images/product/eve/quantitative/acne.jpg);
            }
            &.sensitive {
                background-image: url(~@assets/images/product/eve/quantitative/sensitive.jpg);
            }
            &.greySpots {
                background-image: url(~@assets/images/product/eve/quantitative/greySpots.jpg);
            }
            &.brownSpots {
                background-image: url(~@assets/images/product/eve/quantitative/brownSpots.jpg);
            }
            &.pores {
                background-image: url(~@assets/images/product/eve/quantitative/pores.jpg);
            }
        }
    }

    @media only screen and (max-width: 768px) {
        &-title {
            padding: 0.8rem 0 0.5rem 0.35rem;
            font-weight: 600;
            font-size: 0.28rem;
            width: 1.82rem;
            line-height: 1.15;
        }
        &-list {
            padding-bottom: 0.8rem;
        }
        &-item {
            width: 3.03rem;
            height: 2.46rem;
            margin: 0.12rem auto;
            background-size: contain;
            background-repeat: no-repeat;
            padding: 0 0.14rem;
            box-sizing: border-box;
            color: #fff;

            &-title {
                font-size: 0.2rem;
                line-height: 1.5;
                font-weight: 600;
                padding-top: 1.55rem;
                padding-bottom: 0.06rem;
            }
            &-desc {
                font-size: 0.1rem;
                line-height: 1.17;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }

            &.wrinkles {
                display: none;
            }
            &.porphyrin {
                background-image: url(~@assets/images/product/eve/quantitative/mobile/porphyrin.png);
            }
            &.spots {
                background-image: url(~@assets/images/product/eve/quantitative/mobile/spots.png);
            }
            &.acne {
                display: none;
            }
            &.sensitive {
                background-image: url(~@assets/images/product/eve/quantitative/mobile/sensitive.png);
            }
            &.greySpots {
                background-image: url(~@assets/images/product/eve/quantitative/mobile/greySpots.png);
            }
            &.brownSpots {
                display: none;
            }
            &.pores {
                display: none;
            }
        }
    }
}
</style>
