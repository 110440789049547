<template>
    <div class="evev-scene">
        <h2 class="block-title evev-scene-ani poppins evev-scene-title">{{ i18nPage.title }}</h2>
        <h3 class="block-desc evev-scene-ani evev-scene-desc" v-if="i18nPage.desc">{{ i18nPage.desc }}</h3>
        <div class="evev-scene-main">
            <div class="evev-scene-ani evev-scene-item" :class="{ 'evev-scene-item-overseas': buildForOverseas }" v-for="(item, index) in i18nPage.list" :key="index">
                <div class="info">
                    <h3 class="poppins info-title">{{ item }}</h3>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            buildForOverseas: process.env.VUE_APP_BUILD_FOR === 'evelabinsight',
        };
    },
    computed: {
        i18nPage() {
            return this.$t('evevPage.scene');
        },
    },
    mounted() {
        this.mxAnimation({
            handle: '.evev-scene-ani',
            triggerElement: '.evev-scene',
        });
    },
};
</script>

<style lang="scss">
.evev-scene {
    background-color: #151515;

    &-item {
        &:nth-child(1) {
            background: url(~@assets/images/product/evev/scene/0.jpg) 0 0 no-repeat;
            background-size: 100%;
        }
        &:nth-child(2) {
            background: url(~@assets/images/product/evev/scene/1.jpg) 0 0 no-repeat;
            background-size: 100%;
        }
        &:nth-child(3) {
            background: url(~@assets/images/product/evev/scene/2.jpg) 0 0 no-repeat;
            background-size: 100%;
        }
    }
    &-title,
    &-desc {
        color: #fff;
    }
    @media only screen and (min-width: 769px) {
        padding-bottom: 80px;
        &-main {
            width: 900px;
            margin: 40px auto 0 auto;
            display: flex;
        }
        &-item {
            width: 300px;
            height: 400px;
            overflow: hidden;
            flex-shrink: 0;
            position: relative;
            background-position: center center !important;
            transition: background-size 0.3s;

            .info {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background: rgba(0, 0, 0, 0.7);
                color: #fff;
                transition: all 0.3s;
                transform: translateZ(0);

                &-title {
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 1.5;
                    width: 100%;
                    text-align: center;
                }
            }
            &:hover {
                background-size: 110%;
                .info {
                    opacity: 0;
                }
            }
        }
        &-item-overseas {
            &:nth-child(1) {
                background: url(~@assets/images/product/evev/scene/evelabinsight/0.jpg) 0 0 no-repeat;
                background-size: 100%;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        padding: 0.6rem 0.3rem;
        &-title {
            font-size: 0.28rem;
            font-weight: 600;
            line-height: 1.1;
            text-align: center;
            color: #fff;
            margin-bottom: 0.3rem;
        }
        &-item {
            width: 2.7rem;
            height: 3.09rem;
            margin: 0 auto 0.4rem auto;
            border-radius: 0.1rem;
            overflow: hidden;

            &:last-of-type {
                margin-bottom: 0;
            }

            .info {
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                padding: 0.22rem;
                background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6));
                color: #fff;
                display: flex;
                flex-direction: column;

                &-title {
                    font-size: 15px;
                    font-weight: 600;
                    margin-top: auto;
                }
            }
        }
    }
}
</style>
