<template>
    <div class="product-makeup">
        <top></top>
        <tech></tech>
        <lip></lip>
        <look></look>
        <textures></textures>
        <recommend></recommend>
        <cloud background="#f9f9f9"></cloud>
        <service background="#fff"></service>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import top from './components/top.vue';
import tech from './components/tech.vue';
import lip from './components/lip.vue';
import look from './components/look.vue';
import textures from './components/textures.vue';
import recommend from './components/recommend.vue';
import cloud from './components/cloud.vue';
import service from '../skinMirror/components/service.vue';

export default {
    name: 'productMakeup',
    components: {
        top,
        tech,
        lip,
        look,
        textures,
        recommend,
        cloud,
        service,
    },
    computed: {
        ...mapState({
            currentLang: state => state.route.params.lang || 'zh',
        }),
    },
    data() {
        return {
            pageDurationID: 'Makeup',
        };
    },
    mounted() {
        this.$track('product_makeup_imp');
    },
};
</script>
